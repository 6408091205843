import {
  CarrierType,
  ContainerPositionType,
  EquipmentType,
  JobDto,
  OperationType,
  WorkAreaEquipmentBindingTypes,
} from '@operations/app/api'
import { SelectOption } from '@operations/app/models'
import { EquipmentRadioOption } from '@operations/app/models/operator-pages'
import { CargoUnitStore } from '@operations/stores/CargoUnitStore'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { JobStore } from '@operations/stores/JobStore'
import { TenantConfigStore } from '@operations/stores/TenantConfigStore'
import { YardBlockStore } from '@operations/stores/YardBlockStore'
import { AppStore } from '@tom-ui/utils'
import _ from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { FinishJobDto } from '../models/finish-job.model'
import { isCraneEquipmentType, isServiceLocation } from '../utils'
import { EquipmentOperatorUIStore } from './equipment-operator.ui-store'

export interface JobStep {
  step: JobSteps
  active?: boolean
  isOptional?: boolean
  canBeOptional?: boolean
  stepNumber: number
}

export enum JobSteps {
  selectContainer,
  editDestination,
  plugReefer,
  assignEquipment,
  confirmPickup,
  checkInformation,
  confirmWagonNumber,
  confirmWeight,
}

export class EquipmentOperatorStepperUIStore {
  isOpen = false
  steps: JobStep[] = []

  hasSlotChanged?: boolean
  selectedYardBlock?: any = null
  selectedJob?: JobDto
  selectedContainerNumber?: SelectOption
  assignedEquipmentId?: number
  isPluggedIn?: boolean
  railcarId?: number | null = null
  grossWeight?: number
  picks?: number

  containerOrigin?: string

  hasError?: boolean

  isConfirmationDialogOpen = false

  constructor(
    private cargoUnitStore: CargoUnitStore,
    private equipmentStore: EquipmentStore,
    private jobStore: JobStore,
    private yardBlockStore: YardBlockStore,
    private appStore: AppStore,
    private tenantConfigStore: TenantConfigStore,
    public equipmentOperatorUIStore: EquipmentOperatorUIStore,
  ) {
    makeObservable(this, {
      assignedEquipmentId: observable,
      containerOrigin: observable,
      hasSlotChanged: observable,
      isOpen: observable,
      isPluggedIn: observable,
      selectedContainerNumber: observable,
      selectedJob: observable,
      selectedYardBlock: observable,
      steps: observable,
      hasError: observable,
      railcarId: observable,
      grossWeight: observable,
      isConfirmationDialogOpen: observable,

      assignEquipment: action,
      openStepper: action,
      closeStepper: action,
      nextStep: action,
      previousStep: action,
      plugReeferJob: action,
      setContainerNumberOptionAndOrigin: action,
      setGrossWeight: action,
      setPicks: action,
      setSelectedYardBlock: action,
      setHasError: action,
      setRailcarId: action,
      toggleConfirmationDialog: action,

      activeStep: computed,
      totalSteps: computed,
      hasNextStep: computed,
      equipments: computed,
      equipmentAssignableOptions: computed,
      canCurrentEquipmentBeAssignedToNextJob: computed,
      canSkipEquipmentAssignment: computed,
      containerNumber: computed,
      containerCanBePluggedIn: computed,
      cargoUnits: computed,
      displayJourneyForNonNumeric: computed,
      yardBlockOptions: computed,
      containerNeedsToBePluggedOut: computed,
    })
  }

  public async checkIfJobInProgressToOpenStepper(isOperatorRailcarSelectionComponentOn: boolean) {
    const job = this.equipmentOperatorUIStore.jobs.find(
      x =>
        x.assignedEquipment?.id === this.equipmentOperatorUIStore.selectedEquipmentId &&
        x.container?.currentLocation.equipmentId ===
          this.equipmentOperatorUIStore.selectedEquipmentId &&
        this.equipmentOperatorUIStore.typeOfSelectedEquipment !== EquipmentType.Tt,
    )

    if (job && !this.canSkipStepper(job)) {
      await this.openStepper(job, isOperatorRailcarSelectionComponentOn)
    }
  }

  public async openStepper(job: JobDto, isOperatorRailcarSelectionComponentOn: boolean) {
    this.selectedJob = job

    const steps: JobStep[] = []

    const addStep = (step: JobSteps, isOptional?: boolean) => {
      steps.push({
        step: step,
        stepNumber: steps.length + 1,
        active: !steps.length,
        isOptional,
        canBeOptional: isOptional,
      })
    }

    const addTrainSteps = async (isOptional?: boolean) => {
      addStep(JobSteps.confirmWeight, isOptional)
      addStep(JobSteps.confirmWagonNumber, isOptional)

      await this.equipmentOperatorUIStore.setWagonWeights(job.carrierVisit!.id)
    }

    const addYardSteps = (isOptional?: boolean) => {
      addStep(JobSteps.editDestination, isOptional)

      if (job.container?.isReefer && !job.container.isEmpty && job.hasCoolingOrder) {
        addStep(JobSteps.plugReefer, isOptional)
      }
    }

    const removeCheckInformationIfOnlyStep = () => {
      if (steps.some(x => x.step === JobSteps.checkInformation) && steps.length === 1) {
        steps.pop()
      }
    }

    const isJobOptional = () => {
      return (
        steps.some(x => x.step === JobSteps.assignEquipment) &&
        !this.canSkipEquipmentAssignment &&
        this.canCurrentEquipmentBeAssignedToNextJob
      )
    }

    if (job.from.type === ContainerPositionType.Yard && !job.container?.containerNumber) {
      //non numeric

      addStep(JobSteps.selectContainer)
      if (
        (job?.carrierVisit?.type === CarrierType.Vessel ||
          job?.carrierVisit?.type === CarrierType.Train) &&
        this.hasToAssignEquipment
      ) {
        addStep(JobSteps.assignEquipment)
      }

      await this.cargoUnitStore.loadEmpties(job.container?.id)
    } else if (job.to.type === ContainerPositionType.Truck && job.container?.containerNumber) {
      //container pick up
      addStep(JobSteps.confirmPickup)
    } else if (
      job.from.type === ContainerPositionType.Yard ||
      job.from.type === ContainerPositionType.Warehouse ||
      job.from.type === ContainerPositionType.Train
    ) {
      //from yard job
      addStep(JobSteps.checkInformation)

      if (this.canSkipEquipmentAssignment) {
        this.assignedEquipmentId = this.equipmentOperatorUIStore.selectedEquipmentId
      } else {
        addStep(JobSteps.assignEquipment)
      }
    }

    if (job.to.type === ContainerPositionType.Train) {
      //to train pick up
      if (this.tenantConfigStore.skipRailTally && isOperatorRailcarSelectionComponentOn) {
        removeCheckInformationIfOnlyStep()
        const isOptional = isJobOptional()

        await addTrainSteps(isOptional)
      } else if (!steps.length) {
        addStep(JobSteps.checkInformation)
      }
    }

    if (job.to.type === ContainerPositionType.Yard) {
      //to yard job
      removeCheckInformationIfOnlyStep()
      const isOptional = isJobOptional()

      addYardSteps(isOptional)
    }

    runInAction(() => {
      this.isOpen = true
      this.steps = steps
    })

    return steps
  }

  public nextStep() {
    this.steps = this.steps.map(x => ({
      ...x,
      active: x.stepNumber === this.activeStep.stepNumber + 1,
    }))
  }

  public previousStep() {
    this.steps = this.steps.map(x => ({
      ...x,
      active: x.stepNumber === this.activeStep.stepNumber - 1,
    }))
  }

  public closeStepper() {
    this.isOpen = false

    this.steps = []
    this.selectedYardBlock = undefined
    this.selectedContainerNumber = undefined
    this.containerOrigin = undefined
    this.assignedEquipmentId = undefined
    this.isPluggedIn = undefined
    this.hasSlotChanged = undefined
    this.grossWeight = undefined
    this.railcarId = null
    // the picks is commented out because we want to retain the number of picks even after closing the stepper
    // it is relevant for billing
    //this.picks = undefined
    this.hasError = undefined
  }

  public assignEquipment(id?: number): void {
    if (this.assignedEquipmentId !== id) {
      this.assignedEquipmentId = id
      this.handleOptionStepsOnEquipmentSelection(id)
    }
  }

  public plugReeferJob(isPluggedIn: boolean) {
    if (this.isPluggedIn !== isPluggedIn) {
      this.isPluggedIn = isPluggedIn
    }
  }

  public setPicks(picks?: number): void {
    if (this.picks !== picks) {
      this.picks = picks
    }
  }

  public setSelectedYardBlock(selectedYardBlock?: any) {
    this.hasSlotChanged = true
    if (
      this.selectedYardBlock?.value !== selectedYardBlock?.value ||
      this.selectedYardBlock !== selectedYardBlock
    ) {
      this.selectedYardBlock = selectedYardBlock
    }
  }

  public setContainerNumberOptionAndOrigin(option?: SelectOption, origin?: string) {
    if (this.selectedContainerNumber?.value !== option?.value) {
      this.selectedContainerNumber = option ? { ...option } : undefined
    }

    if (this.containerOrigin !== origin) {
      this.containerOrigin = origin
    }
  }

  public setGrossWeight(weight?: number) {
    if (this.grossWeight !== weight) {
      this.grossWeight = weight
    }
  }

  public setRailcarId(railcarId?: number) {
    if (this.railcarId !== railcarId) {
      this.railcarId = railcarId
    }
  }

  public toggleConfirmationDialog() {
    this.isConfirmationDialogOpen = !this.isConfirmationDialogOpen
  }

  public setHasError(value?: boolean) {
    if (value !== this.hasError) this.hasError = value
  }

  async startJob(job: JobDto) {
    await this.jobStore.startJob({
      workInstructionId: job.workInstructionId,
      equipmentId: this.equipmentOperatorUIStore.selectedEquipmentId!,
    })
  }

  async cancelStartJob(workInstructionId: number) {
    const job = this.equipmentOperatorUIStore.jobs.find(
      x => x.workInstructionId === workInstructionId,
    )
    if (
      !job ||
      job.container?.currentLocation.equipmentId !==
        this.equipmentOperatorUIStore.selectedEquipmentId
    ) {
      return
    }

    await this.jobStore.cancelStartJob({
      workInstructionId: job.workInstructionId,
      equipmentId: this.equipmentOperatorUIStore.selectedEquipmentId!,
    })
  }

  async finishJobByWorkInstructionId(workInstructionId: number) {
    await this.appStore.triggerRequestWithoutLoader(
      async () =>
        await this.jobStore.finishJob({
          workInstructionId: workInstructionId,
          equipmentId: this.equipmentOperatorUIStore.selectedEquipmentId!,
          isPluggedIn: false,
        }),
    )
  }

  async finishJob(jobDto: FinishJobDto) {
    await this.appStore.triggerRequestWithoutLoader(
      async () =>
        await this.jobStore.finishJob({
          workInstructionId: jobDto.workInstructionId,
          equipmentId: jobDto.equipmentId,
          newCargoUnitId: jobDto.selectedContainerNumber
            ? +jobDto.selectedContainerNumber.value
            : undefined,
          nextJobEquipmentId:
            jobDto.assignedEquipmentId !== this.equipmentOperatorUIStore.selectedEquipmentId
              ? jobDto.assignedEquipmentId
              : undefined,
          isPluggedIn: !!jobDto.isPluggedIn,
          grossWeight: jobDto.grossWeight,
          railcarTrackPositionId: jobDto.railcarTrackPositionId,
          picks: jobDto.picks,
        }),
    )
  }

  public canSkipStepper(job: JobDto) {
    const isNumeric = !!job.container?.containerNumber

    const isNotOnOrigin =
      job.container?.currentLocation?.type === ContainerPositionType.Handover ||
      job.container?.currentLocation?.type === ContainerPositionType.Berth ||
      job.container?.currentLocation?.type === ContainerPositionType.Equipment

    const isNotGoingToDestination =
      job.to.type === ContainerPositionType.Handover ||
      job.to.type === ContainerPositionType.Berth ||
      isServiceLocation(job.to.type)

    return (
      (!this.isRtgEquipmentType() && isNotOnOrigin && isNotGoingToDestination && isNumeric) ||
      isCraneEquipmentType(this.equipmentOperatorUIStore.typeOfSelectedEquipment) ||
      this.isTtEquipmentType()
    )
  }

  public isTtEquipmentType() {
    return this.equipmentOperatorUIStore.typeOfSelectedEquipment === EquipmentType.Tt
  }

  public isRtgEquipmentType() {
    return this.equipmentOperatorUIStore.typeOfSelectedEquipment === EquipmentType.Rtg
  }

  public isRsEquipmentType(equipmentType: EquipmentType, job: JobDto) {
    return (
      equipmentType === EquipmentType.Rs &&
      (job.to.type === ContainerPositionType.Handover ||
        job.to.type === ContainerPositionType.Berth ||
        isServiceLocation(job.to.type))
    )
  }

  public getDestinationType(workInstructionId: number) {
    return this.jobStore.jobAndYardBlocksDto.jobs.find(
      x => x.workInstructionId === workInstructionId,
    )?.to.type
  }

  public getOriginType(workInstructionId: number) {
    return this.jobStore.jobAndYardBlocksDto.jobs.find(
      x => x.workInstructionId === workInstructionId,
    )?.from.type
  }

  public getVisitId(workInstructionId: number) {
    return this.jobStore.jobAndYardBlocksDto.jobs.find(
      x => x.workInstructionId === workInstructionId,
    )?.carrierVisit?.id
  }

  public get activeStep() {
    return this.steps.find(x => x.active) ?? { stepNumber: 1, step: JobSteps.checkInformation }
  }

  public get totalSteps() {
    return this.steps.filter(x => !x.isOptional).length
  }

  public get hasNextStep() {
    return this.steps.some(x => x.stepNumber > this.activeStep.stepNumber && !x.isOptional)
  }

  public get containerNumber() {
    return this.selectedContainerNumber
      ? this.cargoUnitStore.items.find(x => x.id === this.selectedContainerNumber?.value)
          ?.containerNumber
      : this.selectedJob?.container?.containerNumber
  }

  public get containerCanBePluggedIn() {
    return (
      this.selectedJob?.container?.isReefer &&
      !this.selectedJob?.container?.isEmpty &&
      this.selectedJob?.to.type === ContainerPositionType.Yard
    )
  }

  public get containerNeedsToBePluggedOut() {
    return (
      this.selectedJob?.operationType === OperationType.Outbound &&
      this.selectedJob?.container?.isPluggedIn
    )
  }

  public get yardBlockOptions(): SelectOption[] {
    return _(
      this.yardBlockStore.items
        .filter(y => !!y.name)
        .map(x => ({
          label: x.name,
          value: x.id,
        })),
    )
      .sortBy(i => i.label)
      .value()
  }

  public get cargoUnits() {
    return _(this.cargoUnitStore.items)
      .sortBy(x => x.containerNumber)
      .value()
  }

  public get railcarTrackPositions() {
    return _(
      this.equipmentOperatorUIStore.railcarTrackPositions[this.selectedJob!.carrierVisit!.id!],
    )
      .sortBy(x => x.railcarName)
      .value()
  }

  public get canCurrentEquipmentBeAssignedToNextJob() {
    const currentEquipment = this.equipments.find(
      x => x.id === this.equipmentOperatorUIStore.selectedEquipmentId,
    )!

    return (
      currentEquipment?.equipmentType === EquipmentType.Rs ||
      currentEquipment?.equipmentType === EquipmentType.Ech
    )
  }

  public get equipments() {
    return this.equipmentStore.items
  }

  public get equipmentAssignableOptions(): EquipmentRadioOption[] {
    if (!this.selectedJob) {
      return []
    }

    const jobCarrierWaId =
      this.selectedJob?.operationType === OperationType.Inbound
        ? this.selectedJob?.originWorkAreaId
        : this.selectedJob?.destinationWorkAreaId

    const equipmentType = this.equipmentOperatorUIStore.typeOfSelectedEquipment

    const equipments = _(
      this.equipments
        .filter(
          eq =>
            equipmentType !== eq.equipmentType &&
            eq.equipmentType !== EquipmentType.Sts &&
            eq.equipmentType !== EquipmentType.Rtg &&
            eq.equipmentType !== EquipmentType.Fl &&
            eq.equipmentType !== EquipmentType.Rmg &&
            ((this.selectedJob?.container?.isEmpty && equipmentType !== EquipmentType.Rs) ||
              eq.equipmentType !== EquipmentType.Ech) && //Hide ECH when it is not empty container or current job is taken by RS
            (equipmentType !== EquipmentType.Ech || eq.equipmentType !== EquipmentType.Rs) && //Hide RS when current job is  taken by ECH
            (this.selectedJob?.operationType === OperationType.Internal ||
              this.selectedJob?.operationType === OperationType.Service ||
              eq.workAreaEquipments?.some(
                wa =>
                  wa.workAreaId === jobCarrierWaId &&
                  wa.assignmentType.includes(
                    this.selectedJob?.operationType === OperationType.Inbound
                      ? WorkAreaEquipmentBindingTypes.Discharge
                      : WorkAreaEquipmentBindingTypes.Load,
                  ),
              )),
        )
        .map(
          ({ id, name, equipmentType }) =>
            ({ id: id, title: name, type: equipmentType }) as EquipmentRadioOption,
        ),
    )
      .sortBy(i => i.title)
      .value()

    return equipments
  }

  public get canSkipEquipmentAssignment() {
    return (
      this.canCurrentEquipmentBeAssignedToNextJob &&
      (!this.equipmentAssignableOptions.length ||
        this.selectedJob?.destination.yardBlockType === CarrierType.Train)
    )
  }

  public get displayJourneyForNonNumeric() {
    return (
      (this.selectedJob?.carrierVisit?.type === CarrierType.Vessel && this.hasToAssignEquipment) ||
      this.selectedJob?.carrierVisit?.type === CarrierType.Truck
    )
  }

  public getCurrentFinishJobDto(): FinishJobDto | null {
    if (!this.selectedJob) {
      return null
    }

    return {
      workInstructionId: this.selectedJob.workInstructionId,
      equipmentId: this.equipmentOperatorUIStore.selectedEquipmentId!,
      hasSlotChanged: this.hasSlotChanged,
      selectedYardBlock: undefined, //TODO: remove as soon as FinishJob api updated
      selectedContainerNumber: this.selectedContainerNumber,
      assignedEquipmentId: this.assignedEquipmentId,
      isPluggedIn: this.isPluggedIn,
      grossWeight: this.grossWeight,
      railcarId: this.railcarId,
      orderId: this.selectedJob.order?.externalId ?? undefined,
      picks: this.picks,
    }
  }

  private get hasToAssignEquipment() {
    return (
      (this.selectedJob?.from.type === ContainerPositionType.Yard ||
        this.selectedJob?.from.type === ContainerPositionType.Warehouse ||
        this.selectedJob?.from.type === ContainerPositionType.Train) &&
      !this.canSkipEquipmentAssignment
    )
  }

  private handleOptionStepsOnEquipmentSelection(id?: number) {
    if (this.equipmentOperatorUIStore.selectedEquipmentId === id) {
      this.steps = [...this.steps.map(x => ({ ...x, isOptional: false }))]
    } else {
      this.steps = [...this.steps.map(x => ({ ...x, isOptional: x.canBeOptional }))]
    }
  }
}
