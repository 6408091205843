import { useOperationsStore } from '@operations/AppProvider'
import { SelectOption } from '@operations/app/models'
import { JobSteps } from '@operations/features/equipmentOperator/stores/equipment-operator-stepper.ui-store'
import { observer } from 'mobx-react-lite'
import { AssignToEquipment } from './AssignToEquipment'
import { CheckInformation } from './CheckInformation'
import { ConfirmWagonNumber } from './ConfirmWagonNumber'
import { ConfirmWeight } from './ConfirmWeight'
import { EditDestination } from './EditDestination'
import { PlugReefer } from './PlugReefer'
import { SelectContainer } from './SelectContainer'
import { EquipmentOperatorProps } from './edit-destination.interface'

export const JobStepper = observer(
  ({
    EditDestinationComponent,
    ConfirmPickupComponent,
    RailcarAutocompleteComponent,
    handlePicks,
  }: EquipmentOperatorProps) => {
    const { equipmentOperatorStepperUIStore, tenantConfigStore } = useOperationsStore()

    const handleChangeDestination = (newValue?: any) => {
      equipmentOperatorStepperUIStore.setSelectedYardBlock(newValue)
      equipmentOperatorStepperUIStore.setHasError()
    }

    const handleChangeCargoUnit = (newValue?: SelectOption, origin?: string) => {
      equipmentOperatorStepperUIStore.setContainerNumberOptionAndOrigin(newValue, origin)
      equipmentOperatorStepperUIStore.setHasError()
    }

    const handleChangeRailcar = (newValue?: number) => {
      equipmentOperatorStepperUIStore.setRailcarId(newValue)
      equipmentOperatorStepperUIStore.setHasError()
    }

    const handleChangeWeight = (newValue?: number) => {
      equipmentOperatorStepperUIStore.setGrossWeight(newValue)
      equipmentOperatorStepperUIStore.setHasError()
    }

    const handleEquipmentAssignment = (id?: number) => {
      equipmentOperatorStepperUIStore.assignEquipment(id)
      equipmentOperatorStepperUIStore.setHasError()
    }

    const handlePluggedIn = (isPluggedIn: boolean) => {
      equipmentOperatorStepperUIStore.plugReeferJob(isPluggedIn)
      equipmentOperatorStepperUIStore.setHasError()
    }

    return (
      <>
        {equipmentOperatorStepperUIStore.activeStep?.step === JobSteps.editDestination && (
          <EditDestination
            job={equipmentOperatorStepperUIStore.selectedJob!}
            containerOrigin={equipmentOperatorStepperUIStore.containerOrigin}
            selectedYardBlock={equipmentOperatorStepperUIStore.selectedYardBlock}
            yardBlockOptions={equipmentOperatorStepperUIStore.yardBlockOptions}
            stepsTotal={equipmentOperatorStepperUIStore.totalSteps}
            currentStep={equipmentOperatorStepperUIStore.activeStep.stepNumber}
            hasError={equipmentOperatorStepperUIStore.hasError}
            skippedEquipmentAssignment={equipmentOperatorStepperUIStore.canSkipEquipmentAssignment}
            selectedContainerNumber={equipmentOperatorStepperUIStore.containerNumber}
            EditDestinationComponent={EditDestinationComponent}
            handleChange={handleChangeDestination}
            ConfirmPickupComponent={ConfirmPickupComponent}
            handlePicks={handlePicks}
          />
        )}

        {equipmentOperatorStepperUIStore.activeStep?.step === JobSteps.plugReefer && (
          <PlugReefer
            job={equipmentOperatorStepperUIStore.selectedJob}
            pluggedIn={equipmentOperatorStepperUIStore.isPluggedIn}
            stepsTotal={equipmentOperatorStepperUIStore.totalSteps}
            hasError={equipmentOperatorStepperUIStore.hasError}
            onPlugReeferJob={handlePluggedIn}
          />
        )}

        {equipmentOperatorStepperUIStore.activeStep?.step === JobSteps.assignEquipment && (
          <AssignToEquipment
            currentEquipmentId={
              equipmentOperatorStepperUIStore.equipmentOperatorUIStore.selectedEquipmentId
            }
            assignedEquipmentId={equipmentOperatorStepperUIStore.assignedEquipmentId}
            equipmentOptions={equipmentOperatorStepperUIStore.equipmentAssignableOptions}
            canCurrentEquipmentBeAssignedToNextJob={
              equipmentOperatorStepperUIStore.canCurrentEquipmentBeAssignedToNextJob
            }
            stepsTotal={equipmentOperatorStepperUIStore.totalSteps}
            currentStep={equipmentOperatorStepperUIStore.activeStep.stepNumber}
            hasError={equipmentOperatorStepperUIStore.hasError}
            handleEquipment={handleEquipmentAssignment}
          />
        )}

        {equipmentOperatorStepperUIStore.activeStep?.step === JobSteps.selectContainer && (
          <SelectContainer
            job={equipmentOperatorStepperUIStore.selectedJob!}
            cargoUnits={equipmentOperatorStepperUIStore.cargoUnits}
            selectedContainerNumber={equipmentOperatorStepperUIStore.selectedContainerNumber}
            containerOrigin={equipmentOperatorStepperUIStore.containerOrigin}
            showJourney={equipmentOperatorStepperUIStore.displayJourneyForNonNumeric}
            hasError={equipmentOperatorStepperUIStore.hasError}
            stepsTotal={equipmentOperatorStepperUIStore.totalSteps}
            currentStep={equipmentOperatorStepperUIStore.activeStep.stepNumber}
            handleSelectCargoUnit={handleChangeCargoUnit}
            handlePicks={handlePicks}
            DestinationComponent={ConfirmPickupComponent}
          />
        )}

        {equipmentOperatorStepperUIStore.activeStep?.step === JobSteps.confirmWeight && (
          <ConfirmWeight
            job={equipmentOperatorStepperUIStore.selectedJob!}
            hasError={equipmentOperatorStepperUIStore.hasError}
            stepsTotal={equipmentOperatorStepperUIStore.totalSteps}
            currentStep={equipmentOperatorStepperUIStore.activeStep.stepNumber}
            handleWeight={handleChangeWeight}
            handlePicks={handlePicks}
            DestinationComponent={ConfirmPickupComponent}
          />
        )}

        {equipmentOperatorStepperUIStore.activeStep?.step === JobSteps.confirmWagonNumber && (
          <ConfirmWagonNumber
            job={equipmentOperatorStepperUIStore.selectedJob!}
            hasError={equipmentOperatorStepperUIStore.hasError}
            railcarTrackPositions={equipmentOperatorStepperUIStore.railcarTrackPositions}
            containerOrigin={equipmentOperatorStepperUIStore.containerOrigin}
            currentWeight={equipmentOperatorStepperUIStore.grossWeight!}
            stepsTotal={equipmentOperatorStepperUIStore.totalSteps}
            currentStep={equipmentOperatorStepperUIStore.activeStep.stepNumber}
            handleRailcar={handleChangeRailcar}
            showDoorDirectIfExists={tenantConfigStore.tenantConfig?.skipRailTally}
            RailcarAutocompleteComponent={RailcarAutocompleteComponent}
          />
        )}

        {equipmentOperatorStepperUIStore.activeStep?.step === JobSteps.confirmPickup && (
          <CheckInformation
            job={equipmentOperatorStepperUIStore.selectedJob!}
            handlePicks={handlePicks}
            DestinationComponent={ConfirmPickupComponent}
          />
        )}

        {equipmentOperatorStepperUIStore.activeStep?.step === JobSteps.checkInformation && (
          <CheckInformation
            job={equipmentOperatorStepperUIStore.selectedJob!}
            currentStep={equipmentOperatorStepperUIStore.activeStep.stepNumber}
            stepsTotal={equipmentOperatorStepperUIStore.totalSteps}
            handlePicks={handlePicks}
            DestinationComponent={ConfirmPickupComponent}
            showDoorDirectIfExists={!tenantConfigStore.tenantConfig?.skipRailTally}
          />
        )}
      </>
    )
  },
)
