import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { IssueStatus } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

interface Props {
  status: IssueStatus
}

export const IssueStatusChip = ({ status }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const getChipBg = (value: string) => {
    switch (value) {
      case IssueStatus.Unresolved:
        return theme.palette.warning.lighter
      case IssueStatus.FollowUp:
        return theme.palette.busy.lighter
      default:
        return theme.palette.success.lighter
    }
  }

  const getChipColor = (value: string) => {
    switch (value) {
      case IssueStatus.Unresolved:
        return theme.palette.warning.dark
      case IssueStatus.FollowUp:
        return theme.palette.busy.dark
      default:
        return theme.palette.success.dark
    }
  }

  return (
    <Box
      sx={{
        background: getChipBg(status),
        padding: '4px 8px',
        borderRadius: '4px',
        display: 'flex',
        height: '32px',
      }}
    >
      <Typography
        fontSize='14px'
        alignSelf='center'
        alignItems='center'
        fontWeight='bold'
        color={getChipColor(status)}
      >
        {t(status)}
      </Typography>
    </Box>
  )
}
