import { IssueResponseDto, ObjectType } from '@planning/app/api'
import { IListStore } from '@planning/components/list/SimpleListStore'
import { IMessageBus } from '@planning/messages'
import issueService from '@planning/services/issueService'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

export class IssueDataStore implements IListStore<IssueResponseDto> {
  items: IssueResponseDto[] = []
  filter = ''
  isLoading = false
  isNoMatches = false

  constructor(messageBus: IMessageBus) {
    makeObservable(this, {
      items: observable,
      filter: observable,
      isLoading: observable,
      isNoMatches: observable,

      itemsWithObjectTypeOrder: computed,

      setFilter: action,
      setLoading: action,
    })

    //TODO song listen to backend issue event after it is there
    //messageBus.subscribeEvent(EventTypes.OrdersUpsertedEvent, this.receiveOrdersUpsertedMessage)
  }

  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setFilter = (filter: string) => {
    if (filter !== '') {
      this.items = []
      this.isNoMatches = false
    }
    this.filter = filter
  }

  fetch = async () => {
    this.setLoading(true)
    const data = await issueService.getAll()

    runInAction(() => {
      this.items = data
      this.isNoMatches = false
    })

    this.setLoading(false)
  }

  reset = () => {
    this.items = []
  }

  public get itemsWithObjectTypeOrder() {
    return this.items.filter(i => i.objectType === ObjectType.Order)
  }
}
