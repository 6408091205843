import {
  AllocationRuleSummaryDto,
  AllocationsApiDangerousGoodsValidationRequest,
  CarrierVisitAllocationRuleDto,
  CarrierVisitAllocationRuleDtoDestination,
  CarrierVisitDirection,
  ContainerTurnoversFilterDto,
  CreateVisitAllocationRuleDto,
  ErrorCodes,
} from '@storage/app/api'
import {
  getApplicationDomainExceptionPayload,
  isApplicationDomainException,
} from '@storage/app/http-client/interceptors/domain-exception.response-interceptor'
import { tolgee } from '@storage/app/translation'
import { ContainerTurnoversFilterFormProfile } from '@storage/pages/container-turnovers/components/container-turnovers-filter-form'
import { mapFormValuesToFilterDto } from '@storage/pages/container-turnovers/components/container-turnovers-filter-form/container-turnovers-filter-form.mapper'
import { ManualPlanningDialogStore } from '@storage/pages/container-turnovers/stores/manual-planning-dialog.store'
import { ContainerPlanningService } from '@storage/services/container-planning.service'
import { AlertStore } from '@storage/stores/alert.store'
import { DialogUtilStore } from '@storage/stores/dialog.util-store'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import {
  getInsufficientPlanningSpaceMsg,
  getPlanningHasReservedMsg,
} from '@storage/utils/translation'
import { AxiosError } from 'axios'
import { action, makeObservable, observable } from 'mobx'
import {
  mapAllocationRuleTemplateFormProfileToCarrierVisitAllocationRuleDto,
  mapFormValuesToCarrierVisitsUpdateCarrierVisitAllocationRulesRequest,
  mapSettingsToAllocationRuleTemplateFormProfile,
  mapYardPositionDtoToYardPositionDescriptor,
} from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import { AllocationRuleTemplateFormProfile } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'
import { CarrierVisitAllocationRulesV2Store } from './carrier-visit-allocation-rules-v2.store'

export class CarrierVisitAllocationRulesV2UIStore {
  selectedAllocationRule?: CarrierVisitAllocationRuleDto
  searchQuery = ''
  isAllocationRequestLoading = false
  isDangerousGoodsDialogOpen = false
  dialogText = ''
  formExternalDefaultValues?: AllocationRuleTemplateFormProfile
  persistAllChangesIsLoading = false

  constructor(
    private readonly _carrierVisitAllocationRulesStore: CarrierVisitAllocationRulesV2Store,
    private readonly _alertStore: AlertStore,
    private readonly _snackbarStore: SnackbarStore,
    private readonly _containerPlanningService: ContainerPlanningService,
    public readonly containerDialogUtilStore: DialogUtilStore,
    public readonly listItemDialogUtilStore: DialogUtilStore,
  ) {
    makeObservable(this, {
      selectedAllocationRule: observable,
      toggleAllocationRule: action,
      setSelectedAllocationRule: action,

      isAllocationRequestLoading: observable,
      toggleAllocationRequestState: action,

      isDangerousGoodsDialogOpen: observable,
      dialogText: observable,
      toggleDangerousGoodsDialog: action,
      setDialogText: action,

      formExternalDefaultValues: observable,
      setFormExternalDefaultValuesFromSettings: action,

      persistAllChangesIsLoading: observable,
      setPersistAllChangesLoadingState: action,
    })
  }

  async loadAllocationRules(carrierVisitId: number, carrierVisitDirection: CarrierVisitDirection) {
    await this._carrierVisitAllocationRulesStore.loadAll(carrierVisitId, carrierVisitDirection)
  }

  get allocationRules(): CarrierVisitAllocationRuleDto[] {
    return this._carrierVisitAllocationRulesStore.entries
  }

  getAllocationSummary(allocationRuleId: string): AllocationRuleSummaryDto | undefined {
    return this._carrierVisitAllocationRulesStore.allocationSummaries.get(allocationRuleId)
  }

  get showAllocationPanel(): boolean {
    return !!this.selectedAllocationRule
  }

  get selectedAllocationRulePosition(): string {
    if (!this.selectedAllocationRule) {
      return ''
    }
    return mapYardPositionDtoToYardPositionDescriptor(this.selectedAllocationRule.destination)
  }

  async reorderAllocationRules(
    startIndex: number,
    endIndex: number,
    carrierVisitId: number,
    carrierVisitDirection: CarrierVisitDirection,
  ) {
    this._carrierVisitAllocationRulesStore.reorderAllocationRules(startIndex, endIndex)
    await this._carrierVisitAllocationRulesStore.loadAllocationSummaries(
      carrierVisitId,
      carrierVisitDirection,
    )
  }

  setPersistAllChangesLoadingState(isLoading: boolean) {
    this.persistAllChangesIsLoading = isLoading
  }

  async persistAllChanges(carrierVisitId: number, carrierVisitDirection: CarrierVisitDirection) {
    this.setPersistAllChangesLoadingState(true)

    const createVisitAllocationRuleDtos: CreateVisitAllocationRuleDto[] =
      this._carrierVisitAllocationRulesStore.entries.map((rule, index) =>
        this.mapCarrierVisitAllocationRuleDtoToCreateVisitAllocationRuleDto(rule, index + 1),
      )

    await this._carrierVisitAllocationRulesStore
      .saveChanges(carrierVisitId, carrierVisitDirection, createVisitAllocationRuleDtos)
      .then(() => {
        this._snackbarStore.showMessage(
          tolgee.t('changesSaveSuccess', 'All changes are saved successfully'),
          'success',
        )
      })
      .catch(error => {
        if (isApplicationDomainException(error, ErrorCodes.AllocationSpaceConflict)) {
          this._snackbarStore.showMessage(
            tolgee.t('insufficientAllocationSpace', 'Insufficient allocation space'),
            'error',
          )
        } else {
          // Generic error
          this._snackbarStore.showMessage(
            tolgee.t(
              'changesSaveSuccessFailure',
              'An unexpected error occurred while saving the changes',
            ),
            'error',
          )
        }
      })
      .finally(() => this.setPersistAllChangesLoadingState(false))
  }

  toggleAllocationRequestState() {
    this.isAllocationRequestLoading = !this.isAllocationRequestLoading
  }

  toggleDangerousGoodsDialog() {
    this.isDangerousGoodsDialogOpen = !this.isDangerousGoodsDialogOpen
  }

  setDialogText(text: string) {
    this.dialogText = text
  }

  toggleAllocationRule(allocationRule: CarrierVisitAllocationRuleDto) {
    if (this.selectedAllocationRule?.id === allocationRule.id) {
      this.selectedAllocationRule = undefined
    } else {
      this.selectedAllocationRule = allocationRule
    }
  }

  setFormExternalDefaultValuesFromSettings(settings: { [key: string]: string } | null | undefined) {
    this.formExternalDefaultValues = mapSettingsToAllocationRuleTemplateFormProfile(settings)
  }

  openAddDialogWithSettings(settings: { [key: string]: string } | null | undefined) {
    this.setFormExternalDefaultValuesFromSettings(settings)
    this.listItemDialogUtilStore.toggleDialog('Add')
  }

  setSelectedAllocationRule(allocationRule?: CarrierVisitAllocationRuleDto) {
    this.selectedAllocationRule = allocationRule
  }

  setSearchQuery(searchQuery: string) {
    const trimmedSearchQuery = searchQuery.trim()
    if (this.searchQuery !== trimmedSearchQuery) {
      this.searchQuery = trimmedSearchQuery.toLocaleLowerCase()
    }
  }

  get alerts() {
    return this._alertStore.getAlerts()
  }

  get panelActionButtonLabel() {
    const hasAllocationSpaceAlert = this._alertStore.doesAlertExist(
      ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
    )
    return hasAllocationSpaceAlert
      ? tolgee.t('allocateAnyway', 'Allocate Anyway')
      : tolgee.t('allocate', 'Allocate')
  }

  public preAllocationValidation(filter: ContainerTurnoversFilterDto) {
    // Clear alerts
    this._alertStore.clearAlerts()

    this._containerPlanningService
      .prePlanningValidation({
        filter,
      })
      .catch((error: AxiosError) => this.handleDomainException(error))
  }

  clearAlerts() {
    this._alertStore.clearAlerts()
  }

  async createAllocation(filter: ContainerTurnoversFilterDto) {
    if (!this.selectedAllocationRule) {
      return
    }

    const forceCreation = this._alertStore.doesAlertExist(
      ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
    )

    await this._containerPlanningService.createAllocation({
      filter,
      yardPosition: this.selectedAllocationRule.destination,
      forceCreation,
    })
  }

  async validateDangerousGoodsPlanning(
    filter: ContainerTurnoversFilterFormProfile,
    numberOfCTsMatchingFilter: number,
    onSuccess: () => void,
  ) {
    try {
      await this.validateAllocationRequest(
        mapFormValuesToFilterDto(filter),
        this.selectedAllocationRule?.destination,
      )
      this.allocationRequest(mapFormValuesToFilterDto(filter), numberOfCTsMatchingFilter, () =>
        onSuccess(),
      )
    } catch (error: any) {
      if (
        isApplicationDomainException(
          error,
          ErrorCodes.PreplanningMixedDangerousareaNonDangerouscontainers,
        )
      ) {
        this.toggleDangerousGoodsDialog()
        this.setDialogText(
          tolgee.t(
            'dangerousLocationNonDangerousSelection',
            'You are trying to allocate non-dangerous goods to a dangerous goods location',
          ),
        )
      }
      if (
        isApplicationDomainException(
          error,
          ErrorCodes.PreplanningMixedNonDangerousareaDangerouscontainers,
        )
      ) {
        this.toggleDangerousGoodsDialog()
        this.setDialogText(
          tolgee.t(
            'nonDangerousLocationDangerousSelection',
            'You are trying to allocate dangerous goods to a non-dangerous goods location',
          ),
        )
      }
    }
  }

  async validateAllocationRequest(
    filter: ContainerTurnoversFilterDto,
    yardPosition?: CarrierVisitAllocationRuleDtoDestination,
  ) {
    if (!yardPosition) {
      return
    }
    const request: AllocationsApiDangerousGoodsValidationRequest = {
      allocationsDangerousGoodsValidationRequest: {
        filter,
        yardPosition,
      },
    }
    await this._containerPlanningService.dangerousGoodsPrePlanningValidation(request)
  }

  async allocationRequest(
    filter: ContainerTurnoversFilterDto,
    numberOfCTsMatchingFilter: number,
    onSuccess: () => void,
  ) {
    this.toggleAllocationRequestState()
    const req = this.createAllocation(filter)
    //TODO: refactor repetitive requests with manualPlanningRequest
    req
      .then(() => {
        this._snackbarStore.showMessage(
          tolgee.t(
            'containersPlanned',
            '{n} containers has been successfully planned to {destination}',
            {
              n: numberOfCTsMatchingFilter,
              destination: this.selectedAllocationRulePosition,
            },
          ),
          'success',
        )
        onSuccess()
      })
      .catch(error => {
        // Check for allocation space conflict (not enough allocation space)
        if (isApplicationDomainException(error, ErrorCodes.AllocationSpaceConflict)) {
          const payload = getApplicationDomainExceptionPayload(error)

          this._alertStore.showAlert(
            ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
            getInsufficientPlanningSpaceMsg(payload.locationsAvailable),
          )
        } else {
          // Generic error
          this._snackbarStore.showMessage(
            tolgee.t(
              'cantPlanAtThisPosition',
              `Something went wrong. We can't plan at this position`,
            ),
            'error',
          )
        }
      })
      .finally(() => this.toggleAllocationRequestState())
  }

  async addAllocationRule(
    formValues: AllocationRuleTemplateFormProfile,
    carrierVisitId: number,
    carrierVisitDirection: CarrierVisitDirection,
    onSuccess: (carrierVisitAllocationRule?: CarrierVisitAllocationRuleDto) => void,
    onFinally: () => void,
  ) {
    const carrierVisitAllocationRule =
      mapAllocationRuleTemplateFormProfileToCarrierVisitAllocationRuleDto(formValues)

    this._carrierVisitAllocationRulesStore.add(carrierVisitAllocationRule)

    onSuccess(carrierVisitAllocationRule)

    this._carrierVisitAllocationRulesStore.loadAllocationSummaries(
      carrierVisitId,
      carrierVisitDirection,
    )

    onFinally()
  }

  async updateAllocationRule(
    formValues: AllocationRuleTemplateFormProfile,
    carrierVisitId: number,
    carrierVisitDirection: CarrierVisitDirection,
    onSuccess: (carrierVisitAllocationRule?: CarrierVisitAllocationRuleDto) => void,
    onFinally: () => void,
  ) {
    const carrierVisitAllocationRule =
      mapAllocationRuleTemplateFormProfileToCarrierVisitAllocationRuleDto(formValues)

    this._carrierVisitAllocationRulesStore.add(carrierVisitAllocationRule)

    this._carrierVisitAllocationRulesStore.loadAllocationSummaries(
      carrierVisitId,
      carrierVisitDirection,
    )

    onSuccess(carrierVisitAllocationRule)

    onFinally()
  }

  async deleteAllocationRule(
    onSuccess: (carrierVisitAllocationRule?: CarrierVisitAllocationRuleDto) => void,
    onFinally: () => void,
  ) {
    this._carrierVisitAllocationRulesStore.delete(this.listItemDialogUtilStore.dialogEntityId!)

    onSuccess()

    onFinally()
  }

  async updateAllocationRule_Deprecated(
    formValues: AllocationRuleTemplateFormProfile,
    onSuccess: (carrierVisitAllocationRule?: CarrierVisitAllocationRuleDto) => void,
    onFinally: () => void,
  ) {
    this._carrierVisitAllocationRulesStore
      .update_Deprecated(
        mapFormValuesToCarrierVisitsUpdateCarrierVisitAllocationRulesRequest(formValues),
      )
      .then((carrierVisitAllocationRule: CarrierVisitAllocationRuleDto) => {
        onSuccess(carrierVisitAllocationRule)
        this._snackbarStore.showMessage(
          tolgee.t('allocationRuleUpdateSuccess', 'The allocation rule is successfully updated'),
          'success',
        )
      })
      .catch(() =>
        this._snackbarStore.showMessage(
          tolgee.t(
            'allocationRuleUpdateFailure',
            'An unexpected error occurred while updating the allocation rule',
          ),
          'error',
        ),
      )
      .finally(onFinally)
  }

  async deleteAllocationRule_Deprecated(
    onSuccess: (carrierVisitAllocationRule?: CarrierVisitAllocationRuleDto) => void,
    onFinally: () => void,
  ) {
    this._carrierVisitAllocationRulesStore
      .delete_Deprecated(this.listItemDialogUtilStore.dialogEntityId!)
      .then(() => {
        onSuccess()
        this._snackbarStore.showMessage(
          tolgee.t('allocationRuleDeletionSuccess', 'The allocation rule is successfully removed'),
          'success',
        )
      })
      .catch(() =>
        this._snackbarStore.showMessage(
          tolgee.t(
            'allocationRuleDeletionFailure',
            'An unexpected error occurred while removing the allocation rule',
          ),
          'error',
        ),
      )
      .finally(onFinally)
  }

  private handleDomainException(error: AxiosError) {
    if (isApplicationDomainException(error, ErrorCodes.PreplanningMixedContainerTurnoverSizes)) {
      return
    }

    if (isApplicationDomainException(error, ErrorCodes.PreplanningHasReserved)) {
      const payload = getApplicationDomainExceptionPayload(error, ErrorCodes.PreplanningHasReserved)
      this._alertStore.showAlert(
        ErrorCodes.PreplanningHasReserved,
        getPlanningHasReservedMsg(payload.reservedCount),
      )
    }
    if (isApplicationDomainException(error, ErrorCodes.PreplanningMixedDangerousAndNonDangerous)) {
      this._alertStore.showAlert(
        ErrorCodes.PreplanningMixedDangerousAndNonDangerous,
        tolgee.t(
          'planningContainerOfDifferentHandling',
          `Pay attention! you're planning dangerous & non dangerous containers`,
        ),
      )
    }

    if (isApplicationDomainException(error, ErrorCodes.PreplanningMixedFullAndEmpties)) {
      this._alertStore.showAlert(
        ErrorCodes.PreplanningMixedFullAndEmpties,
        tolgee.t(
          'preplanningMixedFullAndEmpties',
          `Pay attention! you're planning full & empty containers`,
        ),
      )
    }
  }

  private mapCarrierVisitAllocationRuleDtoToCreateVisitAllocationRuleDto(
    { name, facets, destination }: CarrierVisitAllocationRuleDto,
    rulePosition: number,
  ): CreateVisitAllocationRuleDto {
    return { name, facets, destination, rulePosition }
  }
}
