import {
  CarrierVisitDirection,
  CarrierVisitStatus,
  CheckLoadPreparationCommand,
  CheckRailcarForDischargeCommand,
  CheckType,
  CreateContainerOrderDto,
  CreateDepartureCheckRailcarCommand,
  CreateRailcarAndOrdersCommand,
  OrderResponseDto,
  UpdateContainerOrderDto,
} from '@planning/app/api'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import {
  IRailcarTrackPositionItem,
  PinInfo,
} from '@planning/rt-stores/railTrack/RailcarTrackPositionItem'
import { RailTrackItem } from '@planning/rt-stores/railTrack/RailTrackItemStore'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { TallymanV2ViewStore } from '@planning/rt-stores/tallyman'
import { IEntityStore } from '@planning/rt-stores/types'
import { containerService, railVisitService } from '@planning/services'
import { CancellableTimeoutStore } from '@planning/stores/gateClerk/CancellableTimeoutStore'
import { AxiosError } from 'axios'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { IInspectContainerFormData } from '../Components/InspectContainer/InspectContainerForm'

export interface IInspectionRailCar {
  railcarId: number
  railcarTrackId: number
  name: string
  sequence: number
  containers: string[]
  isCheckedId: boolean
  dischargeCheck: string | null | undefined
  loadCheck: string | null | undefined
  departureCheck: string | null | undefined
}

export interface ICreateRailcarFormData {
  railcarNumber: string
  sequence: number
  length?: number
  maxPayload?: number
}

// [Test] TODO: UT the stores pls!
export class RailTallyViewStore extends CancellableTimeoutStore {
  searchFilter = ''
  toggleWarningDialog = false

  selectedVisit?: IRailVisitItem
  selectedVisitOrders?: IOrderItem[]
  selectedTrack?: RailTrackItem
  selectedRailTrackPosition?: IRailcarTrackPositionItem
  selectedOperationType?: CheckType

  positionToBeDeleted?: IInspectionRailCar
  containerOrderToBeRemoved?: OrderResponseDto
  unassignedOrders: OrderResponseDto[] = []
  upsertedOrders: IInspectContainerFormData[] = []

  railcarToBeCreated?: ICreateRailcarFormData

  currentStep = 0

  stepperSelectedItems: (string | null)[] = []

  confirmedRailcarIds: number[] = []

  confirmed = 0

  constructor(
    public parentStore: TallymanV2ViewStore,
    private railVisitItemStore: IEntityStore<IRailVisitItem>,
    delay?: number,
  ) {
    super(delay)
    makeObservable(this, {
      selectedTrack: observable,
      selectedVisit: observable,
      selectedVisitOrders: observable,
      selectedRailTrackPosition: observable,
      selectedOperationType: observable,
      searchFilter: observable,
      positionToBeDeleted: observable,
      containerOrderToBeRemoved: observable,
      unassignedOrders: observable,
      upsertedOrders: observable,
      currentStep: observable,
      railcarToBeCreated: observable,
      toggleWarningDialog: observable,
      stepperSelectedItems: observable,
      confirmedRailcarIds: observable,

      railVisits: computed,
      railVisitsOnSelectedTrack: computed,
      selectedRailcarsOnSelectedTrack: computed,
      selectedOrdersOnSelectedRailcar: computed,
      filteredRailcars: computed,
      direction: computed,
      confirmedRailcar: computed,

      checkinRailcar: action,
      selectTrack: action,
      selectVisit: action,
      selectVisitOrders: action,
      selectRailTrackPosition: action,
      selectOperationType: action,
      setRailcarToBeCreated: action,
      setCurrentStep: action,
      setSearchFilter: action,
      setRailToBeDeleted: action,
      setContainerOrderToBeRemoved: action,
      upsertUnassignedOrders: action,
      upsertOrders: action,
      setToggleWarningDialog: action,
      setStepperSelectedItems: action,
      addConfirmedRailcarId: action,
      removeConfirmedRailcarId: action,
    })
  }

  get railVisits() {
    return _(this.railVisitItemStore.elements)
      .filter(rv => rv.data.ata !== null && rv.data.status !== CarrierVisitStatus.Departed)
      .value()
  }

  get railVisitsOnSelectedTrack() {
    if (!this.selectedTrack) return undefined
    return _(this.railVisits)
      .filter(rv => rv.railTracks.map(rt => rt.id).includes(this.selectedTrack!.id))
      .value()
  }

  get selectedRailcarsOnSelectedTrack() {
    return (
      this.selectedVisit?.railcarTrackPositions
        .filter(rtp => rtp.data.direction === this.direction)
        .filter(rtp => {
          const railcarOrders = this.selectedVisitOrders?.filter(
            order => order.railcarTrackPosition?.data.railcarId === rtp.data.railcarId,
          )

          // If there are no orders, we show the empty railcar
          if (!railcarOrders || railcarOrders.length === 0) {
            return true
          } else {
            return railcarOrders.filter(o => o.data.direction === this.direction).length > 0
          }
        })
        .reduce<IInspectionRailCar[]>((acc, rtp) => {
          if (rtp.railTrack && rtp.railTrack.id === this.selectedTrack?.id) {
            const railcarContainerNumbers =
              this.selectedVisitOrders
                ?.filter(order => order.railcarTrackPosition?.data.railcarId === rtp.data.railcarId)
                .filter(
                  order =>
                    !!order.container?.data.number && order.data.direction === this.direction,
                )
                .map(order => order.container!.data.number!) || []

            const railcar: IInspectionRailCar = {
              railcarId: rtp.data.railcarId,
              railcarTrackId: rtp.id,
              name: rtp.data.railcarName,
              sequence: rtp.data.railcarSequenceNumber,
              containers: railcarContainerNumbers,
              dischargeCheck: rtp.data.checkinDate,
              loadCheck: rtp.data.loadPreparationCheckedDate,
              departureCheck: rtp.data.checkoutDate,
              isCheckedId:
                rtp.data.checkinDate !== null || rtp.data.loadPreparationCheckedDate !== null,
            }

            acc.push(railcar)
          }
          return acc
        }, []) || []
    )
  }

  get pinsOnSelectedRailcar() {
    const lengths = this.selectedOrdersOnSelectedRailcar
      .map((o: IOrderItem) => o.data.containerLength)
      .flatMap(l => (l ? [l] : []))

    const result: PinInfo[] = []
    lengths.forEach(l => {
      const pinInfo = result.find(pin => pin.length === l)
      if (pinInfo) pinInfo.amount += 1
      else result.push({ amount: 1, length: l })
    })

    return result
  }

  get selectedOrdersOnSelectedRailcar() {
    return (
      this.selectedVisitOrders?.filter(
        order =>
          order.railcarTrackPosition?.data.railcarId ===
            this.selectedRailTrackPosition?.data.railcarId &&
          order.data.direction === this.direction,
      ) || []
    )
  }

  get filteredRailcars() {
    return this.selectedRailcarsOnSelectedTrack.filter(railcar =>
      railcar.name.includes(this.searchFilter),
    )
  }

  get lastSequenceNumberForSelectedTrack() {
    return (
      this.selectedRailcarsOnSelectedTrack.reduce(
        (max, railcar) => Math.max(max, railcar.sequence),
        0,
      ) || undefined
    )
  }

  get dischargeOrdersOnRailVisit() {
    return _(this.selectedVisit?.discharge.orders).value()
  }

  get uncheckedDischargeOrdersOnRailVisit() {
    return _(this.dischargeOrdersOnRailVisit)
      .filter(o => !o.railcarTrackPosition?.data.checkinDate)
      .value()
  }

  get direction() {
    return this.selectedOperationType === CheckType.DischargePreparation ? 'Inbound' : 'Outbound'
  }

  get confirmedRailcar() {
    return this.computeConfirmedRailcar(
      this.selectedRailcarsOnSelectedTrack,
      this.confirmedRailcarIds,
      this.selectedOperationType,
    )
  }

  checkinRailcar = async (
    selectedRailTrackPositionId: number,
    railcarSequenceNumber: number,
    railcarId: number,
    railTrackId: string,
    visitId: number,
    length?: number,
    maxPayload?: number,
  ) => {
    this.addConfirmedRailcarId(railcarId)

    this.createRequest(async () => {
      try {
        const { ordersToBeCreated, ordersToBeUpdated } =
          await this.getCreatedAndUpdatedOrdersWithDamage(
            visitId,
            undefined,
            railTrackId,
            selectedRailTrackPositionId,
          )

        if (this.selectedOperationType === CheckType.LoadPreparation) {
          const cmd: CheckLoadPreparationCommand = {
            railcarTrackPositionId: selectedRailTrackPositionId,
            railcarSequenceNumber: railcarSequenceNumber,
            length: length,
          }

          await railVisitService.checkLoadPreparation(cmd)
        }

        if (this.selectedOperationType === CheckType.DischargePreparation) {
          const cmd: CheckRailcarForDischargeCommand = {
            railcarTrackPositionId: selectedRailTrackPositionId,
            sequenceNumber: railcarSequenceNumber,
            length,
            maxPayload,
            ordersToBeCreated: ordersToBeCreated,
            ordersToBeUpdated: ordersToBeUpdated,
            ordersIdsToBeDeleted: this.unassignedOrders.map(uo => uo.id),
          }

          await railVisitService.checkRailcarForDischarge(cmd)
        }

        if (this.selectedOperationType === CheckType.DepartureCheck) {
          const cmd: CreateDepartureCheckRailcarCommand = {
            railcarTrackPositionId: selectedRailTrackPositionId,
            sequenceNumber: railcarSequenceNumber,
            length,
            ordersToBeUpdated: ordersToBeUpdated,
            ordersIdsToBeDeleted: this.unassignedOrders.map(uo => uo.id),
          }

          await railVisitService.createDepartureCheck(cmd)
        }

        super.reset()
        this.removeUpsertedOrders()
      } catch (error) {
        this.removeConfirmedRailcarId(railcarId)
        this.handleError(error as AxiosError<any>)
      }
    })
  }

  createRailcarAndOrders = async (
    carrierVisitId: number,
    railcarNumber: string,
    railTrackId: string,
    railcarSequenceNumber: number,
    direction: CarrierVisitDirection,
    length?: number,
    maxPayload?: number,
  ) => {
    this.selectRailTrackPosition()
    this.createRequest(async () => {
      try {
        const { ordersToBeUpdated } = await this.getCreatedAndUpdatedOrdersWithDamage(
          carrierVisitId,
          railcarNumber,
          railTrackId,
        )

        const cmd: CreateRailcarAndOrdersCommand = {
          carrierVisitId,
          railcarNumber,
          railTrackId,
          sequenceNumber: railcarSequenceNumber,
          direction,
          length,
          maxPayload,
          ordersToBeAssigned: ordersToBeUpdated,
        }

        await railVisitService.createRailcarAndOrders(cmd)

        this.setRailcarToBeCreated()
        this.removeUpsertedOrders()
        super.reset()
      } catch (error) {
        this.handleError(error as AxiosError<any>)
      }
    })
  }

  computeConfirmedRailcar(
    selectedRailcarsOnSelectedTrack: IInspectionRailCar[],
    confirmedRailcarIds: number[],
    selectedOperationType?: CheckType,
  ) {
    return selectedRailcarsOnSelectedTrack.filter(rc => {
      if (confirmedRailcarIds.includes(rc.railcarId)) return true

      if (selectedOperationType === CheckType.DischargePreparation) {
        return rc.dischargeCheck
      }

      if (selectedOperationType === CheckType.LoadPreparation) {
        return rc.loadCheck
      }

      if (selectedOperationType === CheckType.DepartureCheck) {
        return rc.departureCheck
      }

      return false
    }).length
  }

  handleError(err: AxiosError<any>) {
    let errorMessage = err.message
    if (err.response?.data?.errors?.['RailcarSequence']) {
      errorMessage = err.response.data.errors['RailcarSequence'][0]
    }

    this.setRequestErrorMessage(errorMessage)
    this.cancelRequest()
  }

  deleteRailcar = async () => {
    if (!this.selectedVisit || !this.positionToBeDeleted) return

    try {
      await railVisitService.deleteRailCar({
        railVisitId: this.selectedVisit.id,
        ids: [this.positionToBeDeleted.railcarTrackId],
        checkType: this.selectedOperationType,
      })
      this.setRailToBeDeleted()
    } catch (error) {
      console.log(error)
    }
  }

  upsertUnassignedOrders = (order: OrderResponseDto) => {
    this.unassignedOrders = [...this.unassignedOrders.filter(o => o.id !== order.id), order]
  }

  upsertOrders = (containerOrder: IInspectContainerFormData) => {
    this.upsertedOrders = [
      ...this.upsertedOrders.filter(o => o.id !== containerOrder.id),
      containerOrder,
    ]
  }

  removeUpsertedOrders = (containerId?: number) => {
    if (containerId) {
      this.upsertedOrders = [...this.upsertedOrders.filter(o => o.containerId !== containerId)]
    } else {
      this.upsertedOrders = []
    }
  }

  removeContainerOrder = async () => {
    if (!this.containerOrderToBeRemoved || !this.selectedVisit) return

    const idToBeRemoved = this.containerOrderToBeRemoved.id
    const orders = this.selectedVisitOrders?.filter(o => o.id !== idToBeRemoved) ?? []

    this.selectVisitOrders(orders)
    this.removeUpsertedOrders(this.containerOrderToBeRemoved.containerId ?? 0)
    this.upsertUnassignedOrders(this.containerOrderToBeRemoved)
    this.setContainerOrderToBeRemoved()
  }

  cancelRequest = () => {
    super.cancelRequest()

    setTimeout(async () => {
      super.reset()
    }, this.delay)
  }

  selectTrack = (track?: RailTrackItem) => (this.selectedTrack = track)
  selectVisit = (visit?: IRailVisitItem) => {
    this.selectedVisit = visit
    this.unassignedOrders = []
    this.upsertedOrders = []
    this.selectVisitOrders(visit?.orders)
  }

  selectVisitOrders = (orders?: IOrderItem[]) => (this.selectedVisitOrders = orders)

  selectRailTrackPosition = (position?: IRailcarTrackPositionItem) =>
    (this.selectedRailTrackPosition = position)

  selectRailTrackPositionById(
    visitId?: number,
    railTrackId?: string,
    railcarId?: number,
    direction?: CarrierVisitDirection,
  ) {
    const selectedRailTrackPosition = _(this.selectedVisit?.railcarTrackPositions).find(
      rtp =>
        rtp.data.railVisitId === visitId &&
        rtp.data.railTrackId === railTrackId &&
        rtp.data.railcarId === railcarId &&
        rtp.data.direction === direction,
    )
    this.selectRailTrackPosition(selectedRailTrackPosition)
  }

  mapToContainerOrderDto = (
    data: IInspectContainerFormData,
    carrierVisitId?: number,
    railcarNumber?: string,
    railTrackId?: string,
    railcarTrackPositionId?: number,
  ): CreateContainerOrderDto | UpdateContainerOrderDto => {
    return {
      id: data.id ?? null,
      referenceNumber: data.referenceNumber,
      direction: data.direction,
      isEmpty: data.isEmpty,
      imoClasses: data.imoClasses,
      isAccidentalDischarge: false,
      isToBeStoredInYard: false,
      containerNumber: data.containerNumber,
      containerIsoCode: data.containerIsoCode,
      containerMaxGross: data.containerMaxGross,
      containerTare: data.containerTare,
      carrierVisitId: carrierVisitId ?? this.selectedVisit?.id ?? 0,
      railTrackPositionId: railcarTrackPositionId,
      waggon: railcarNumber,
      railTrackId: railTrackId ?? this.selectedRailTrackPosition?.data.railTrackId,
      seals: data.seals,
      hasSeals: data.hasSeals,
      doorDirection: data.doorDirection,
    }
  }

  selectOperationType = (operationType?: CheckType) => {
    this.selectedOperationType = operationType
    this.confirmedRailcarIds = []
  }

  setCurrentStep = (step: number) => (this.currentStep = step)

  setSearchFilter = (filter: string) => (this.searchFilter = filter)

  setRailToBeDeleted = (data?: IInspectionRailCar) => (this.positionToBeDeleted = data)

  setContainerOrderToBeRemoved = (data?: OrderResponseDto) =>
    (this.containerOrderToBeRemoved = data)

  setRailcarToBeCreated = (data?: ICreateRailcarFormData) => (this.railcarToBeCreated = data)

  setToggleWarningDialog = (isOpen: boolean) => (this.toggleWarningDialog = isOpen)

  setStepperSelectedItems = (items: (string | null)[]) => {
    this.stepperSelectedItems = items
  }

  addConfirmedRailcarId = (id: number) => {
    this.confirmedRailcarIds.push(id)
  }

  removeConfirmedRailcarId = (id: number) => {
    this.confirmedRailcarIds = this.confirmedRailcarIds.filter(cId => cId !== id)
  }

  resetRequests = () => {
    super.reset()
  }

  reset = () => {
    this.resetRequests()
    this.selectTrack()
    this.selectVisit()
    this.selectRailTrackPosition()
    this.selectOperationType()
    this.setRailToBeDeleted()
    this.setContainerOrderToBeRemoved()
    this.unassignedOrders = []
    this.upsertedOrders = []
    this.confirmedRailcarIds = []
  }

  private async getCreatedAndUpdatedOrdersWithDamage(
    carrierVisitId?: number,
    railcarNumber?: string,
    railTrackId?: string,
    railcarTrackPositionId?: number,
  ) {
    const ordersToBeCreated: CreateContainerOrderDto[] | UpdateContainerOrderDto[] = []
    const ordersToBeUpdated: CreateContainerOrderDto[] | UpdateContainerOrderDto[] = []

    const imgUploadRequests = this.upsertedOrders
      ?.map((x, index) => ({
        index: index,
        request:
          x.containerNumber && x.damagesReported?.length
            ? containerService.getDamageDtoForUploadedDamages(x.containerNumber, x.damagesReported)
            : undefined,
      }))
      .filter(x => x.request)

    const imgUploadResults = await Promise.all(imgUploadRequests.map(x => x.request))

    this.upsertedOrders.forEach((order, index) => {
      const mappedOrder = this.mapToContainerOrderDto(
        order,
        carrierVisitId,
        railcarNumber,
        railTrackId,
        railcarTrackPositionId,
      )
      const imagRequestIndex = imgUploadRequests.findIndex(x => x.index === index)
      if (index >= 0) {
        mappedOrder.damages = imgUploadResults[imagRequestIndex] ?? []
      }

      if (order.id) {
        ordersToBeUpdated.push(mappedOrder)
      } else {
        ordersToBeCreated.push(mappedOrder)
      }
    })

    return { ordersToBeCreated, ordersToBeUpdated }
  }
}
