import { Alert, Button, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { AllocationRuleTemplateDto } from '@storage/app/api'
import CustomTabsContainer from '@storage/components/CustomTabsContainer'
import { ManualInputValidationStore } from '@storage/pages/container-turnovers/stores/manual-input-validation.store'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useEffect, useState } from 'react'
import { mapYardPositionDtoToYardPositionDescriptor } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import AllocationRuleTemplateFacetChips from './AllocationRuleTemplateFacetChips'
import { SimplifiedControlledTextField } from './SimplifiedControlledTextField'

interface AllocationRulesTemplatesListItemProps {
  allocationRuleTemplate: AllocationRuleTemplateDto
  manualInputValidationStore: ManualInputValidationStore
  onActionClick: (ruleId: string, destination: string) => void
}

const AllocationRulesTemplatesListItem = ({
  allocationRuleTemplate,
  manualInputValidationStore,
  onActionClick,
}: AllocationRulesTemplatesListItemProps) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const [error, setError] = useState('')
  const [isValidDangerousGoods, setIsValidDangerousGoods] = useState(true)
  const [isDangerousAlertMessage, setIsDangerousAlertMessage] = useState('')

  const [destination, setDestination] = useState(
    allocationRuleTemplate.destination
      ? mapYardPositionDtoToYardPositionDescriptor(allocationRuleTemplate.destination)
      : '',
  )

  // New state to store local validation results
  const [localValidationState, setLocalValidationState] = useState({
    isAccepted: false,
    isInvalid: false,
    hint: { type: '', text: '' },
    optionCount: 0,
  })

  useEffect(() => {
    manualInputValidationStore.validate(destination.trim().toUpperCase())
    // Capture validation results locally
    setLocalValidationState({
      isAccepted: manualInputValidationStore.isAccepted,
      isInvalid: manualInputValidationStore.isInvalid,
      hint: manualInputValidationStore.hint,
      optionCount: manualInputValidationStore.optionCount,
    })

    const validateDangerousGoodsSelection = () => {
      const { isValidDangerousGoods, isDangerousAlertMessage } =
        manualInputValidationStore.validateDGLocation(allocationRuleTemplate.facets.isDangerous)
      setIsValidDangerousGoods(isValidDangerousGoods)
      setIsDangerousAlertMessage(isDangerousAlertMessage)
    }
    validateDangerousGoodsSelection()
  }, [allocationRuleTemplate.facets.isDangerous, destination, manualInputValidationStore])

  const showHint = (hint: { type: string; text: string }) => {
    const label = hint.type.split('.')
    return `${t(label[0], label[1] ?? '')}${hint.text.length > 0 ? ':' : ''} ${hint.text}`
  }

  const locationFieldHelperText =
    !localValidationState.isAccepted || localValidationState.optionCount > 1
      ? showHint(localValidationState.hint)
      : ''

  const handleCopyToVisit = () => {
    if (!destination.trim()) {
      setError(t('destinationRequired', 'Destination is required'))
      return
    }
    setError('')
    onActionClick(allocationRuleTemplate.id, destination)
  }

  const handleOnChange = (value: string) => {
    setDestination(value)
    if (error) setError('')
  }

  return (
    <Stack gap={1}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        p={2}
        gap={2}
        sx={{
          borderRadius: theme.customRadius.large,
          boxShadow: theme.shadows[3],
        }}
      >
        <Stack gap={1} flexGrow={1}>
          <Typography variant='subtitle1'>{allocationRuleTemplate.name}</Typography>

          <CustomTabsContainer maxWidth='350px'>
            <AllocationRuleTemplateFacetChips facets={allocationRuleTemplate.facets} />
          </CustomTabsContainer>
        </Stack>

        <SimplifiedControlledTextField
          value={destination}
          onChange={handleOnChange}
          label={t('destination', 'Destination')}
          placeholder='(e.g. A, A.01, A.01.A, A.01-03.A-D)'
          uppercase
          fontColor={localValidationState.isInvalid ? theme.palette.error.main : undefined}
          helperText={error || locationFieldHelperText}
          error={!!error}
          size='small'
        />
        <Button
          onClick={handleCopyToVisit}
          variant='contained'
          color='primary'
          disabled={!localValidationState.isAccepted}
        >
          {t('copyToVisit', 'Copy to visit')}
        </Button>
      </Stack>
      {!isValidDangerousGoods && <Alert severity='warning'>{isDangerousAlertMessage}</Alert>}
    </Stack>
  )
}

export default AllocationRulesTemplatesListItem
