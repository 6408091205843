import { IssueResponseDto, IssueStatus } from '@planning/app/api'
import {
  ContainerJourney,
  ContainerJourneyDataStore,
} from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { action, computed, makeObservable, observable } from 'mobx'
import { IssueDataStore } from './IssueDataStore'

export type IssuesTypeCount = {
  all: number
  unresolved: number
  followUp: number
}

export interface IssueWithJourney extends IssueResponseDto {
  containerJourney?: ContainerJourney
}

export class IssuesViewStore {
  issueFilterType?: IssueStatus
  carrierNameFilter? = ''
  containerNumberFilter? = ''

  constructor(
    public issueDataStore: IssueDataStore,
    public containerJourneyDataStore: ContainerJourneyDataStore,
  ) {
    makeObservable(this, {
      issueFilterType: observable,
      carrierNameFilter: observable,
      containerNumberFilter: observable,

      data: computed,
      issuesTypeCount: computed,

      setSearchFilter: action,
      setIssueFilterType: action,
      setCarrierNameFilter: action,
      applyFilters: action,
    })
  }

  setSearchFilter(filter: string) {
    this.containerNumberFilter = filter
  }

  setCarrierNameFilter(filter?: string) {
    this.carrierNameFilter = filter
  }

  applyFilters() {
    // TODO Victor implement
    console.log(this.carrierNameFilter)
  }

  fetchData = async () => {
    await this.issueDataStore.fetch()
    const orderIds = this.issueDataStore.itemsWithObjectTypeOrder.map(i => i.objectId)
    await this.containerJourneyDataStore.fetchByOrderIds(orderIds)
  }

  getContainerJourneyByIssueOrderId(orderId: number) {
    return this.containerJourneyDataStore.items.find(
      i => i.inboundOrderId === orderId || i.outboundOrderId === orderId,
    )
  }

  setIssueFilterType(issueFilterType?: IssueStatus) {
    if (this.issueFilterType !== issueFilterType) {
      this.issueFilterType = issueFilterType
    }
  }

  get issuesTypeCount(): IssuesTypeCount {
    const items = this.issueDataStore.itemsWithObjectTypeOrder
    let unresolved = 0
    let followUp = 0

    items.forEach(item => {
      if (item.issueStatus === IssueStatus.Unresolved) {
        unresolved++
      } else if (item.issueStatus === IssueStatus.FollowUp) {
        followUp++
      }
    })

    return {
      all: items.length,
      unresolved: unresolved,
      followUp: followUp,
    }
  }

  get data(): IssueWithJourney[] {
    const data = this.issueDataStore.itemsWithObjectTypeOrder.map(item => ({
      ...item,
      containerJourney: this.getContainerJourneyByIssueOrderId(item.objectId),
    }))

    let filteredData = this.issueFilterType
      ? data.filter(i => i.issueStatus === this.issueFilterType)
      : data

    if (this.containerNumberFilter) {
      filteredData = filteredData.filter(i => {
        const { containerJourney } = i

        return (
          containerJourney &&
          (containerJourney.inboundOrder?.order?.containerNumber?.includes(
            this.containerNumberFilter!,
          ) ||
            containerJourney.outboundOrder?.order?.containerNumber?.includes(
              this.containerNumberFilter!,
            ))
        )
      })
    }

    return filteredData
  }
}
