/* tslint:disable */
/* eslint-disable */
/**
 * TOM PLANNING SERVICE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration'
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
import type { RequestArgs } from './base'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AllowOrderPassageCommand
 */
export interface AllowOrderPassageCommand {
  /**
   *
   * @type {number}
   * @memberof AllowOrderPassageCommand
   */
  id: number
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof AllowOrderPassageCommand
   */
  carrierVisitDirection: CarrierVisitDirection
}

/**
 *
 * @export
 * @interface AllowTruckVisitEntryCommand
 */
export interface AllowTruckVisitEntryCommand {
  /**
   *
   * @type {number}
   * @memberof AllowTruckVisitEntryCommand
   */
  id: number
  /**
   *
   * @type {Array<number>}
   * @memberof AllowTruckVisitEntryCommand
   */
  inboundOrderIds?: Array<number> | null
  /**
   *
   * @type {Array<number>}
   * @memberof AllowTruckVisitEntryCommand
   */
  outboundOrderIds?: Array<number> | null
}
/**
 *
 * @export
 * @interface AllowTruckVisitExitCommand
 */
export interface AllowTruckVisitExitCommand {
  /**
   *
   * @type {number}
   * @memberof AllowTruckVisitExitCommand
   */
  id: number
  /**
   *
   * @type {Array<number>}
   * @memberof AllowTruckVisitExitCommand
   */
  orderIds?: Array<number> | null
}
/**
 *
 * @export
 * @interface ApproveHoldsCommand
 */
export interface ApproveHoldsCommand {
  /**
   *
   * @type {Array<number>}
   * @memberof ApproveHoldsCommand
   */
  ids: Array<number>
}
/**
 *
 * @export
 * @interface AssignContainerCommand
 */
export interface AssignContainerCommand {
  /**
   *
   * @type {number}
   * @memberof AssignContainerCommand
   */
  nonNumericOrderId: number
  /**
   *
   * @type {number}
   * @memberof AssignContainerCommand
   */
  containerId: number
  /**
   *
   * @type {number}
   * @memberof AssignContainerCommand
   */
  orderId?: number | null
}
/**
 *
 * @export
 * @interface AssignOrderToRailcarTrackPositionCommand
 */
export interface AssignOrderToRailcarTrackPositionCommand {
  /**
   *
   * @type {number}
   * @memberof AssignOrderToRailcarTrackPositionCommand
   */
  orderId?: number
  /**
   *
   * @type {number}
   * @memberof AssignOrderToRailcarTrackPositionCommand
   */
  railcarTrackPositionId?: number
}
/**
 *
 * @export
 * @interface AssignRailcarTrackPositionCommand
 */
export interface AssignRailcarTrackPositionCommand {
  /**
   *
   * @type {number}
   * @memberof AssignRailcarTrackPositionCommand
   */
  railVisitId: number
  /**
   *
   * @type {Array<number>}
   * @memberof AssignRailcarTrackPositionCommand
   */
  ids: Array<number>
  /**
   *
   * @type {string}
   * @memberof AssignRailcarTrackPositionCommand
   */
  newTrackId: string
}
/**
 *
 * @export
 * @interface BerthResponseDto
 */
export interface BerthResponseDto {
  /**
   *
   * @type {number}
   * @memberof BerthResponseDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof BerthResponseDto
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof BerthResponseDto
   */
  quayMeterMarkFrom: number
  /**
   *
   * @type {number}
   * @memberof BerthResponseDto
   */
  quayMeterMarkTo: number
  /**
   *
   * @type {number}
   * @memberof BerthResponseDto
   */
  maxDraft?: number | null
  /**
   *
   * @type {number}
   * @memberof BerthResponseDto
   */
  meterSize: number
  /**
   *
   * @type {boolean}
   * @memberof BerthResponseDto
   */
  isDeleted: boolean
  /**
   *
   * @type {string}
   * @memberof BerthResponseDto
   */
  pierId?: string | null
  /**
   *
   * @type {Array<BollardResponseDto>}
   * @memberof BerthResponseDto
   */
  bollards: Array<BollardResponseDto>
}
/**
 *
 * @export
 * @enum {string}
 */

export const BerthSide = {
  Unspecified: 'Unspecified',
  Port: 'Port',
  Starboard: 'Starboard',
} as const

export type BerthSide = (typeof BerthSide)[keyof typeof BerthSide]

/**
 *
 * @export
 * @interface BollardResponseDto
 */
export interface BollardResponseDto {
  /**
   *
   * @type {string}
   * @memberof BollardResponseDto
   */
  name?: string | null
  /**
   *
   * @type {number}
   * @memberof BollardResponseDto
   */
  meterMark: number
}
/**
 *
 * @export
 * @interface BreakTimeResponseDto
 */
export interface BreakTimeResponseDto {
  /**
   *
   * @type {number}
   * @memberof BreakTimeResponseDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof BreakTimeResponseDto
   */
  from: string
  /**
   *
   * @type {string}
   * @memberof BreakTimeResponseDto
   */
  to: string
  /**
   *
   * @type {string}
   * @memberof BreakTimeResponseDto
   */
  description: string
  /**
   *
   * @type {number}
   * @memberof BreakTimeResponseDto
   */
  duration?: number
}
/**
 *
 * @export
 * @interface CargoData
 */
export interface CargoData {
  /**
   *
   * @type {number}
   * @memberof CargoData
   */
  grossWeight?: number | null
  /**
   *
   * @type {string}
   * @memberof CargoData
   */
  content?: string | null
  /**
   *
   * @type {Array<number>}
   * @memberof CargoData
   */
  dangerousGoodsDefinitionIds?: Array<number> | null
  /**
   *
   * @type {Array<string>}
   * @memberof CargoData
   */
  imoClasses: Array<string>
  /**
   *
   * @type {string}
   * @memberof CargoData
   */
  temperature?: string | null
}
/**
 *
 * @export
 * @interface CargoInformationDto
 */
export interface CargoInformationDto {
  /**
   *
   * @type {number}
   * @memberof CargoInformationDto
   */
  commodityId?: number
  /**
   *
   * @type {number}
   * @memberof CargoInformationDto
   */
  plannedAmount?: number
  /**
   *
   * @type {number}
   * @memberof CargoInformationDto
   */
  actualAmount?: number | null
  /**
   *
   * @type {string}
   * @memberof CargoInformationDto
   */
  lotNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof CargoInformationDto
   */
  handlingInstructions?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CargoInformationDto
   */
  identifiableItems?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof CargoInformationDto
   */
  packageId?: number | null
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof CargoInformationDto
   */
  weightUnit?: WeightMeasurementUnit
}

/**
 *
 * @export
 * @interface CargoItemDto
 */
export interface CargoItemDto {
  /**
   *
   * @type {number}
   * @memberof CargoItemDto
   */
  commodityId?: number
  /**
   *
   * @type {number}
   * @memberof CargoItemDto
   */
  plannedAmount?: number
  /**
   *
   * @type {number}
   * @memberof CargoItemDto
   */
  actualAmount?: number | null
  /**
   *
   * @type {string}
   * @memberof CargoItemDto
   */
  lotNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof CargoItemDto
   */
  handlingInstructions?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CargoItemDto
   */
  identifiableItems?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof CargoItemDto
   */
  packageId?: number | null
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof CargoItemDto
   */
  weightUnit?: WeightMeasurementUnit
  /**
   *
   * @type {number}
   * @memberof CargoItemDto
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof CargoItemDto
   */
  customerId?: number
  /**
   *
   * @type {string}
   * @memberof CargoItemDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {number}
   * @memberof CargoItemDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {Array<string>}
   * @memberof CargoItemDto
   */
  imoClasses?: Array<string> | null
}

/**
 *
 * @export
 * @interface CargoItemResponseDto
 */
export interface CargoItemResponseDto {
  /**
   *
   * @type {number}
   * @memberof CargoItemResponseDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof CargoItemResponseDto
   */
  commodityId: number
  /**
   *
   * @type {string}
   * @memberof CargoItemResponseDto
   */
  commodityName: string
  /**
   *
   * @type {number}
   * @memberof CargoItemResponseDto
   */
  customerId: number
  /**
   *
   * @type {string}
   * @memberof CargoItemResponseDto
   */
  customerName: string
  /**
   *
   * @type {string}
   * @memberof CargoItemResponseDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {number}
   * @memberof CargoItemResponseDto
   */
  plannedAmount: number
  /**
   *
   * @type {number}
   * @memberof CargoItemResponseDto
   */
  actualAmount?: number | null
  /**
   *
   * @type {string}
   * @memberof CargoItemResponseDto
   */
  lotNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof CargoItemResponseDto
   */
  handlingInstructions?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CargoItemResponseDto
   */
  identifiableItems?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof CargoItemResponseDto
   */
  packageId?: number | null
  /**
   *
   * @type {string}
   * @memberof CargoItemResponseDto
   */
  packageName?: string | null
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof CargoItemResponseDto
   */
  weightUnit?: WeightMeasurementUnit
  /**
   *
   * @type {number}
   * @memberof CargoItemResponseDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {Array<string>}
   * @memberof CargoItemResponseDto
   */
  imoClasses?: Array<string> | null
}

/**
 *
 * @export
 * @enum {string}
 */

export const CargoType = {
  Unknown: 'Unknown',
  Container: 'Container',
  GeneralCargo: 'GeneralCargo',
} as const

export type CargoType = (typeof CargoType)[keyof typeof CargoType]

/**
 *
 * @export
 * @enum {string}
 */

export const CarrierType = {
  Universal: 'Universal',
  Truck: 'Truck',
  Vessel: 'Vessel',
  Train: 'Train',
} as const

export type CarrierType = (typeof CarrierType)[keyof typeof CarrierType]

/**
 *
 * @export
 * @enum {string}
 */

export const CarrierVisitDirection = {
  Inbound: 'Inbound',
  Outbound: 'Outbound',
} as const

export type CarrierVisitDirection =
  (typeof CarrierVisitDirection)[keyof typeof CarrierVisitDirection]

/**
 *
 * @export
 * @interface CarrierVisitDto
 */
export interface CarrierVisitDto {
  /**
   *
   * @type {number}
   * @memberof CarrierVisitDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  identifier?: string | null
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  driverName?: string | null
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  eta?: string
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  atd?: string | null
  /**
   *
   * @type {CargoType}
   * @memberof CarrierVisitDto
   */
  cargoType: CargoType
  /**
   *
   * @type {Array<number>}
   * @memberof CarrierVisitDto
   */
  carrierIds: Array<number>
  /**
   *
   * @type {CarrierVisitStatus}
   * @memberof CarrierVisitDto
   */
  status: CarrierVisitStatus
  /**
   *
   * @type {Array<BreakTimeResponseDto>}
   * @memberof CarrierVisitDto
   */
  breakTimes?: Array<BreakTimeResponseDto> | null
}

/**
 *
 * @export
 * @interface CarrierVisitScheduleDto
 */
export interface CarrierVisitScheduleDto {
  /**
   *
   * @type {number}
   * @memberof CarrierVisitScheduleDto
   */
  repeatEvery: number
  /**
   *
   * @type {RepeatByDateKind}
   * @memberof CarrierVisitScheduleDto
   */
  repeatBy: RepeatByDateKind
  /**
   *
   * @type {string}
   * @memberof CarrierVisitScheduleDto
   */
  endsOn?: string | null
  /**
   *
   * @type {number}
   * @memberof CarrierVisitScheduleDto
   */
  endsAfter?: number | null
}

/**
 *
 * @export
 * @enum {string}
 */

export const CarrierVisitStatus = {
  Expected: 'Expected',
  Arrived: 'Arrived',
  InOperation: 'InOperation',
  Departed: 'Departed',
  Completed: 'Completed',
} as const

export type CarrierVisitStatus = (typeof CarrierVisitStatus)[keyof typeof CarrierVisitStatus]

/**
 *
 * @export
 * @interface CheckLoadPreparationCommand
 */
export interface CheckLoadPreparationCommand {
  /**
   *
   * @type {number}
   * @memberof CheckLoadPreparationCommand
   */
  railcarTrackPositionId: number
  /**
   *
   * @type {number}
   * @memberof CheckLoadPreparationCommand
   */
  railcarSequenceNumber: number
  /**
   *
   * @type {number}
   * @memberof CheckLoadPreparationCommand
   */
  length?: number | null
}
/**
 *
 * @export
 * @interface CheckRailcarForDischargeCommand
 */
export interface CheckRailcarForDischargeCommand {
  /**
   *
   * @type {number}
   * @memberof CheckRailcarForDischargeCommand
   */
  railcarTrackPositionId: number
  /**
   *
   * @type {number}
   * @memberof CheckRailcarForDischargeCommand
   */
  sequenceNumber: number
  /**
   *
   * @type {number}
   * @memberof CheckRailcarForDischargeCommand
   */
  length?: number | null
  /**
   *
   * @type {number}
   * @memberof CheckRailcarForDischargeCommand
   */
  maxPayload?: number | null
  /**
   *
   * @type {Array<CreateContainerOrderDto>}
   * @memberof CheckRailcarForDischargeCommand
   */
  ordersToBeCreated?: Array<CreateContainerOrderDto> | null
  /**
   *
   * @type {Array<UpdateContainerOrderDto>}
   * @memberof CheckRailcarForDischargeCommand
   */
  ordersToBeUpdated?: Array<UpdateContainerOrderDto> | null
  /**
   *
   * @type {Array<number>}
   * @memberof CheckRailcarForDischargeCommand
   */
  ordersIdsToBeDeleted?: Array<number> | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const CheckType = {
  DischargePreparation: 'DischargePreparation',
  LoadPreparation: 'LoadPreparation',
  DepartureCheck: 'DepartureCheck',
} as const

export type CheckType = (typeof CheckType)[keyof typeof CheckType]

/**
 *
 * @export
 * @interface CloseNonNumericOrderCommand
 */
export interface CloseNonNumericOrderCommand {
  /**
   *
   * @type {number}
   * @memberof CloseNonNumericOrderCommand
   */
  id: number
}
/**
 *
 * @export
 * @interface CommodityAttribute
 */
export interface CommodityAttribute {
  /**
   *
   * @type {string}
   * @memberof CommodityAttribute
   */
  name?: string | null
  /**
   *
   * @type {string}
   * @memberof CommodityAttribute
   */
  value?: string | null
}
/**
 *
 * @export
 * @interface CommodityResponseDto
 */
export interface CommodityResponseDto {
  /**
   *
   * @type {number}
   * @memberof CommodityResponseDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof CommodityResponseDto
   */
  name: string
  /**
   *
   * @type {Array<CommodityAttribute>}
   * @memberof CommodityResponseDto
   */
  attributes?: Array<CommodityAttribute> | null
  /**
   *
   * @type {Array<number>}
   * @memberof CommodityResponseDto
   */
  packageIds?: Array<number> | null
}
/**
 *
 * @export
 * @interface ConfirmActualCargoAmountOrderCommand
 */
export interface ConfirmActualCargoAmountOrderCommand {
  /**
   *
   * @type {number}
   * @memberof ConfirmActualCargoAmountOrderCommand
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof ConfirmActualCargoAmountOrderCommand
   */
  amount: number
}
/**
 *
 * @export
 * @interface ContainerAttributes
 */
export interface ContainerAttributes {
  /**
   *
   * @type {number}
   * @memberof ContainerAttributes
   */
  length?: number | null
  /**
   *
   * @type {ContainerHeight}
   * @memberof ContainerAttributes
   */
  height?: ContainerHeight
  /**
   *
   * @type {ContainerType}
   * @memberof ContainerAttributes
   */
  type?: ContainerType
}

/**
 *
 * @export
 * @interface ContainerDamageLocationResponseDto
 */
export interface ContainerDamageLocationResponseDto {
  /**
   *
   * @type {number}
   * @memberof ContainerDamageLocationResponseDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ContainerDamageLocationResponseDto
   */
  name?: string | null
  /**
   *
   * @type {string}
   * @memberof ContainerDamageLocationResponseDto
   */
  code?: string | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const ContainerDamageResolutionReason = {
  Unknown: 'Unknown',
  Repaired: 'Repaired',
  MistakenlyCreated: 'MistakenlyCreated',
} as const

export type ContainerDamageResolutionReason =
  (typeof ContainerDamageResolutionReason)[keyof typeof ContainerDamageResolutionReason]

/**
 *
 * @export
 * @interface ContainerDamageResponseDto
 */
export interface ContainerDamageResponseDto {
  /**
   *
   * @type {number}
   * @memberof ContainerDamageResponseDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof ContainerDamageResponseDto
   */
  description: string
  /**
   *
   * @type {ContainerDamageStatus}
   * @memberof ContainerDamageResponseDto
   */
  status: ContainerDamageStatus
  /**
   *
   * @type {string}
   * @memberof ContainerDamageResponseDto
   */
  created: string
  /**
   *
   * @type {string}
   * @memberof ContainerDamageResponseDto
   */
  resolved?: string | null
  /**
   *
   * @type {ContainerDamageResolutionReason}
   * @memberof ContainerDamageResponseDto
   */
  resolutionReason?: ContainerDamageResolutionReason
  /**
   *
   * @type {number}
   * @memberof ContainerDamageResponseDto
   */
  locationId?: number | null
  /**
   *
   * @type {ContainerDamageLocationResponseDto}
   * @memberof ContainerDamageResponseDto
   */
  location?: ContainerDamageLocationResponseDto
  /**
   *
   * @type {Array<number>}
   * @memberof ContainerDamageResponseDto
   */
  typeIds?: Array<number> | null
  /**
   *
   * @type {Array<ContainerDamageTypeResponseDto>}
   * @memberof ContainerDamageResponseDto
   */
  types?: Array<ContainerDamageTypeResponseDto> | null
  /**
   *
   * @type {string}
   * @memberof ContainerDamageResponseDto
   */
  size?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof ContainerDamageResponseDto
   */
  imagePaths?: Array<string> | null
}

/**
 *
 * @export
 * @enum {string}
 */

export const ContainerDamageStatus = {
  Active: 'Active',
  Resolved: 'Resolved',
} as const

export type ContainerDamageStatus =
  (typeof ContainerDamageStatus)[keyof typeof ContainerDamageStatus]

/**
 *
 * @export
 * @interface ContainerDamageTypeResponseDto
 */
export interface ContainerDamageTypeResponseDto {
  /**
   *
   * @type {number}
   * @memberof ContainerDamageTypeResponseDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ContainerDamageTypeResponseDto
   */
  name?: string | null
  /**
   *
   * @type {string}
   * @memberof ContainerDamageTypeResponseDto
   */
  code?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ContainerDamageTypeResponseDto
   */
  isCriticalDamage?: boolean
}
/**
 *
 * @export
 * @interface ContainerDamageUpsertDto
 */
export interface ContainerDamageUpsertDto {
  /**
   *
   * @type {number}
   * @memberof ContainerDamageUpsertDto
   */
  id?: number | null
  /**
   *
   * @type {number}
   * @memberof ContainerDamageUpsertDto
   */
  locationId?: number | null
  /**
   *
   * @type {Array<number>}
   * @memberof ContainerDamageUpsertDto
   */
  typeIds?: Array<number> | null
  /**
   *
   * @type {string}
   * @memberof ContainerDamageUpsertDto
   */
  size?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof ContainerDamageUpsertDto
   */
  imagePaths?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof ContainerDamageUpsertDto
   */
  description?: string | null
}
/**
 *
 * @export
 * @interface ContainerDto
 */
export interface ContainerDto {
  /**
   *
   * @type {number}
   * @memberof ContainerDto
   */
  id?: number | null
  /**
   *
   * @type {string}
   * @memberof ContainerDto
   */
  number?: string | null
  /**
   *
   * @type {string}
   * @memberof ContainerDto
   */
  isoCode?: string | null
  /**
   *
   * @type {string}
   * @memberof ContainerDto
   */
  operator?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ContainerDto
   */
  isEmpty?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContainerDto
   */
  isOnTerminal?: boolean
  /**
   *
   * @type {number}
   * @memberof ContainerDto
   */
  length?: number | null
  /**
   *
   * @type {ContainerHeight}
   * @memberof ContainerDto
   */
  height?: ContainerHeight
  /**
   *
   * @type {ContainerType}
   * @memberof ContainerDto
   */
  type?: ContainerType
  /**
   *
   * @type {number}
   * @memberof ContainerDto
   */
  maxGross?: number | null
  /**
   *
   * @type {number}
   * @memberof ContainerDto
   */
  tare?: number | null
  /**
   *
   * @type {Array<number>}
   * @memberof ContainerDto
   */
  activeOrderIds: Array<number>
}

/**
 *
 * @export
 * @interface ContainerDtoPaginatedList
 */
export interface ContainerDtoPaginatedList {
  /**
   *
   * @type {Array<ContainerDto>}
   * @memberof ContainerDtoPaginatedList
   */
  payload: Array<ContainerDto>
  /**
   *
   * @type {number}
   * @memberof ContainerDtoPaginatedList
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof ContainerDtoPaginatedList
   */
  totalPages: number
  /**
   *
   * @type {number}
   * @memberof ContainerDtoPaginatedList
   */
  totalCount: number
  /**
   *
   * @type {boolean}
   * @memberof ContainerDtoPaginatedList
   */
  hasPreviousPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContainerDtoPaginatedList
   */
  hasNextPage: boolean
}
/**
 *
 * @export
 * @interface ContainerEventDto
 */
export interface ContainerEventDto {
  /**
   *
   * @type {number}
   * @memberof ContainerEventDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof ContainerEventDto
   */
  eventId?: string | null
  /**
   *
   * @type {EventType}
   * @memberof ContainerEventDto
   */
  type: EventType
  /**
   *
   * @type {string}
   * @memberof ContainerEventDto
   */
  topic?: string | null
  /**
   *
   * @type {string}
   * @memberof ContainerEventDto
   */
  time?: string
  /**
   *
   * @type {string}
   * @memberof ContainerEventDto
   */
  containerNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof ContainerEventDto
   */
  payload?: string | null
  /**
   *
   * @type {number}
   * @memberof ContainerEventDto
   */
  tenantId?: number
}

/**
 *
 * @export
 * @enum {string}
 */

export const ContainerHeight = {
  Standard: 'Standard',
  High: 'High',
  Low: 'Low',
} as const

export type ContainerHeight = (typeof ContainerHeight)[keyof typeof ContainerHeight]

/**
 *
 * @export
 * @interface ContainerJourneyDto
 */
export interface ContainerJourneyDto {
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyDto
   */
  id: number
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof ContainerJourneyDto
   */
  direction: CarrierVisitDirection
  /**
   *
   * @type {OrderStatus}
   * @memberof ContainerJourneyDto
   */
  status: OrderStatus
  /**
   *
   * @type {string}
   * @memberof ContainerJourneyDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyDto
   */
  carrierVisitId?: number | null
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyDto
   */
  customerId?: number | null
  /**
   *
   * @type {string}
   * @memberof ContainerJourneyDto
   */
  customerName?: string | null
  /**
   *
   * @type {CarrierType}
   * @memberof ContainerJourneyDto
   */
  carrierVisitType?: CarrierType
  /**
   *
   * @type {CarrierVisitDto}
   * @memberof ContainerJourneyDto
   */
  carrierVisit?: CarrierVisitDto
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyDto
   */
  containerId?: number | null
  /**
   *
   * @type {string}
   * @memberof ContainerJourneyDto
   */
  containerNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof ContainerJourneyDto
   */
  containerIsoCode?: string | null
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyDto
   */
  containerMaxGross?: number | null
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyDto
   */
  containerTare?: number | null
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {string}
   * @memberof ContainerJourneyDto
   */
  operator?: string | null
  /**
   *
   * @type {Array<HoldResponseDto>}
   * @memberof ContainerJourneyDto
   */
  holds?: Array<HoldResponseDto> | null
  /**
   *
   * @type {boolean}
   * @memberof ContainerJourneyDto
   */
  isOnTerminal?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContainerJourneyDto
   */
  isEmpty?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContainerJourneyDto
   */
  isDeliveryByTruck?: boolean | null
  /**
   *
   * @type {Array<string>}
   * @memberof ContainerJourneyDto
   */
  imoClasses: Array<string>
  /**
   *
   * @type {string}
   * @memberof ContainerJourneyDto
   */
  releaseEnd?: string | null
  /**
   *
   * @type {LinkedOrderResponseDto}
   * @memberof ContainerJourneyDto
   */
  linkedOrder?: LinkedOrderResponseDto
}

/**
 *
 * @export
 * @interface ContainerJourneyResponseDto
 */
export interface ContainerJourneyResponseDto {
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyResponseDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyResponseDto
   */
  containerId: number
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyResponseDto
   */
  inboundOrderId: number
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyResponseDto
   */
  outboundOrderId?: number | null
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyResponseDto
   */
  strippingOrderId?: number | null
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyResponseDto
   */
  stuffingOrderId?: number | null
  /**
   *
   * @type {number}
   * @memberof ContainerJourneyResponseDto
   */
  coolingOrderId?: number | null
}
/**
 *
 * @export
 * @interface ContainerResponseDto
 */
export interface ContainerResponseDto {
  /**
   *
   * @type {number}
   * @memberof ContainerResponseDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof ContainerResponseDto
   */
  number?: string | null
  /**
   *
   * @type {string}
   * @memberof ContainerResponseDto
   */
  isoCode?: string | null
  /**
   *
   * @type {string}
   * @memberof ContainerResponseDto
   */
  operator?: string | null
  /**
   *
   * @type {number}
   * @memberof ContainerResponseDto
   */
  length?: number | null
  /**
   *
   * @type {ContainerHeight}
   * @memberof ContainerResponseDto
   */
  height: ContainerHeight
  /**
   *
   * @type {ContainerType}
   * @memberof ContainerResponseDto
   */
  type: ContainerType
  /**
   *
   * @type {boolean}
   * @memberof ContainerResponseDto
   */
  isOnTerminal: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContainerResponseDto
   */
  isEmpty: boolean
  /**
   *
   * @type {Array<ContainerDamageResponseDto>}
   * @memberof ContainerResponseDto
   */
  damages: Array<ContainerDamageResponseDto>
  /**
   *
   * @type {number}
   * @memberof ContainerResponseDto
   */
  maxGross?: number | null
  /**
   *
   * @type {number}
   * @memberof ContainerResponseDto
   */
  tare?: number | null
}

/**
 *
 * @export
 * @interface ContainerResponseDtoPaginatedList
 */
export interface ContainerResponseDtoPaginatedList {
  /**
   *
   * @type {Array<ContainerResponseDto>}
   * @memberof ContainerResponseDtoPaginatedList
   */
  payload: Array<ContainerResponseDto>
  /**
   *
   * @type {number}
   * @memberof ContainerResponseDtoPaginatedList
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof ContainerResponseDtoPaginatedList
   */
  totalPages: number
  /**
   *
   * @type {number}
   * @memberof ContainerResponseDtoPaginatedList
   */
  totalCount: number
  /**
   *
   * @type {boolean}
   * @memberof ContainerResponseDtoPaginatedList
   */
  hasPreviousPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContainerResponseDtoPaginatedList
   */
  hasNextPage: boolean
}
/**
 *
 * @export
 * @interface ContainerSyncStatusResponseDto
 */
export interface ContainerSyncStatusResponseDto {
  /**
   *
   * @type {number}
   * @memberof ContainerSyncStatusResponseDto
   */
  tenantId: number
  /**
   *
   * @type {number}
   * @memberof ContainerSyncStatusResponseDto
   */
  count: number
}
/**
 *
 * @export
 * @enum {string}
 */

export const ContainerType = {
  Unknown: 'Unknown',
  General: 'General',
  GeneralVentilated: 'GeneralVentilated',
  Reefer: 'Reefer',
  OpenTop: 'OpenTop',
  Platform: 'Platform',
  Tank: 'Tank',
  Bulk: 'Bulk',
  Foldable: 'Foldable',
  AirSurface: 'AirSurface',
  Livestock: 'Livestock',
  Auto: 'Auto',
  Fish: 'Fish',
  NonCargoCarrying: 'NonCargoCarrying',
} as const

export type ContainerType = (typeof ContainerType)[keyof typeof ContainerType]

/**
 *
 * @export
 * @interface CoolingOrderResponseDto
 */
export interface CoolingOrderResponseDto {
  /**
   *
   * @type {number}
   * @memberof CoolingOrderResponseDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof CoolingOrderResponseDto
   */
  requestedTemperature: number
  /**
   *
   * @type {boolean}
   * @memberof CoolingOrderResponseDto
   */
  isMonitoringRequired: boolean
  /**
   *
   * @type {number}
   * @memberof CoolingOrderResponseDto
   */
  monitoringFrequency?: number | null
  /**
   *
   * @type {boolean}
   * @memberof CoolingOrderResponseDto
   */
  isPlugInRequired: boolean
  /**
   *
   * @type {OrderStatus}
   * @memberof CoolingOrderResponseDto
   */
  status: OrderStatus
}

/**
 *
 * @export
 * @interface CreateBreakTimeCommand
 */
export interface CreateBreakTimeCommand {
  /**
   *
   * @type {number}
   * @memberof CreateBreakTimeCommand
   */
  carrierId: number
  /**
   *
   * @type {string}
   * @memberof CreateBreakTimeCommand
   */
  from: string
  /**
   *
   * @type {string}
   * @memberof CreateBreakTimeCommand
   */
  to: string
  /**
   *
   * @type {string}
   * @memberof CreateBreakTimeCommand
   */
  description: string
}
/**
 *
 * @export
 * @interface CreateCargoItemDto
 */
export interface CreateCargoItemDto {
  /**
   *
   * @type {number}
   * @memberof CreateCargoItemDto
   */
  commodityId: number
  /**
   *
   * @type {number}
   * @memberof CreateCargoItemDto
   */
  customerId: number
  /**
   *
   * @type {number}
   * @memberof CreateCargoItemDto
   */
  plannedAmount: number
  /**
   *
   * @type {string}
   * @memberof CreateCargoItemDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateCargoItemDto
   */
  lotNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateCargoItemDto
   */
  handlingInstructions?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CreateCargoItemDto
   */
  identifiableItems?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof CreateCargoItemDto
   */
  packageId?: number | null
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof CreateCargoItemDto
   */
  weightUnit?: WeightMeasurementUnit
  /**
   *
   * @type {number}
   * @memberof CreateCargoItemDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {Array<string>}
   * @memberof CreateCargoItemDto
   */
  imoClasses?: Array<string> | null
}

/**
 *
 * @export
 * @interface CreateContainerDamageCommand
 */
export interface CreateContainerDamageCommand {
  /**
   *
   * @type {number}
   * @memberof CreateContainerDamageCommand
   */
  containerId: number
  /**
   *
   * @type {string}
   * @memberof CreateContainerDamageCommand
   */
  description: string
}
/**
 *
 * @export
 * @interface CreateContainerOrderDto
 */
export interface CreateContainerOrderDto {
  /**
   *
   * @type {string}
   * @memberof CreateContainerOrderDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof CreateContainerOrderDto
   */
  direction: CarrierVisitDirection
  /**
   *
   * @type {number}
   * @memberof CreateContainerOrderDto
   */
  linkedOrderId?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateContainerOrderDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {string}
   * @memberof CreateContainerOrderDto
   */
  temperature?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CreateContainerOrderDto
   */
  isDeliveryByTruck?: boolean
  /**
   *
   * @type {string}
   * @memberof CreateContainerOrderDto
   */
  portOfLoading?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateContainerOrderDto
   */
  portOfDischarge?: string | null
  /**
   *
   * @type {number}
   * @memberof CreateContainerOrderDto
   */
  railTrackPositionId?: number | null
  /**
   *
   * @type {string}
   * @memberof CreateContainerOrderDto
   */
  waggon?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateContainerOrderDto
   */
  railTrackId?: string | null
  /**
   *
   * @type {boolean}
   * @memberof CreateContainerOrderDto
   */
  isEmpty?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof CreateContainerOrderDto
   */
  imoClasses: Array<string>
  /**
   *
   * @type {number}
   * @memberof CreateContainerOrderDto
   */
  carrierVisitId?: number | null
  /**
   *
   * @type {boolean}
   * @memberof CreateContainerOrderDto
   */
  isAccidentalDischarge: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateContainerOrderDto
   */
  isToBeStoredInYard: boolean
  /**
   *
   * @type {string}
   * @memberof CreateContainerOrderDto
   */
  containerNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateContainerOrderDto
   */
  containerIsoCode?: string | null
  /**
   *
   * @type {ContainerAttributes}
   * @memberof CreateContainerOrderDto
   */
  containerAttributes?: ContainerAttributes
  /**
   *
   * @type {string}
   * @memberof CreateContainerOrderDto
   */
  operator?: string | null
  /**
   *
   * @type {Array<ContainerDamageUpsertDto>}
   * @memberof CreateContainerOrderDto
   */
  damages?: Array<ContainerDamageUpsertDto> | null
  /**
   *
   * @type {number}
   * @memberof CreateContainerOrderDto
   */
  containerMaxGross?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateContainerOrderDto
   */
  containerTare?: number | null
  /**
   *
   * @type {Array<string>}
   * @memberof CreateContainerOrderDto
   */
  seals?: Array<string> | null
  /**
   *
   * @type {boolean}
   * @memberof CreateContainerOrderDto
   */
  hasSeals?: boolean
}

/**
 *
 * @export
 * @interface CreateContainerVisitCommand
 */
export interface CreateContainerVisitCommand {
  /**
   *
   * @type {string}
   * @memberof CreateContainerVisitCommand
   */
  containerNumber: string
  /**
   *
   * @type {string}
   * @memberof CreateContainerVisitCommand
   */
  containerIsoCode: string
  /**
   *
   * @type {string}
   * @memberof CreateContainerVisitCommand
   */
  referenceNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateContainerVisitCommand
   */
  consignee?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateContainerVisitCommand
   */
  notes?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateContainerVisitCommand
   */
  operationalInstructions?: string | null
  /**
   *
   * @type {number}
   * @memberof CreateContainerVisitCommand
   */
  customerId?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateContainerVisitCommand
   */
  containerId: number
  /**
   *
   * @type {CargoData}
   * @memberof CreateContainerVisitCommand
   */
  inboundCargo?: CargoData
  /**
   *
   * @type {CargoData}
   * @memberof CreateContainerVisitCommand
   */
  outboundCargo?: CargoData
  /**
   *
   * @type {TransportData}
   * @memberof CreateContainerVisitCommand
   */
  inboundTransportData?: TransportData
  /**
   *
   * @type {TransportData}
   * @memberof CreateContainerVisitCommand
   */
  outboundTransportData?: TransportData
  /**
   *
   * @type {boolean}
   * @memberof CreateContainerVisitCommand
   */
  inboundHasSeals?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateContainerVisitCommand
   */
  outboundHasSeals?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof CreateContainerVisitCommand
   */
  inboundSeals?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof CreateContainerVisitCommand
   */
  outboundSeals?: Array<string> | null
}
/**
 *
 * @export
 * @interface CreateCoolingOrderCommand
 */
export interface CreateCoolingOrderCommand {
  /**
   *
   * @type {number}
   * @memberof CreateCoolingOrderCommand
   */
  containerJourneyId: number
  /**
   *
   * @type {number}
   * @memberof CreateCoolingOrderCommand
   */
  requestedTemperature: number
  /**
   *
   * @type {boolean}
   * @memberof CreateCoolingOrderCommand
   */
  isMonitoringRequired: boolean
  /**
   *
   * @type {number}
   * @memberof CreateCoolingOrderCommand
   */
  monitoringFrequency?: number | null
  /**
   *
   * @type {boolean}
   * @memberof CreateCoolingOrderCommand
   */
  isPlugInRequired: boolean
}
/**
 *
 * @export
 * @interface CreateDangerousGoodsDefinitionCommand
 */
export interface CreateDangerousGoodsDefinitionCommand {
  /**
   *
   * @type {string}
   * @memberof CreateDangerousGoodsDefinitionCommand
   */
  unNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateDangerousGoodsDefinitionCommand
   */
  material?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateDangerousGoodsDefinitionCommand
   */
  primaryImoClass?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CreateDangerousGoodsDefinitionCommand
   */
  secondaryImoClasses?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof CreateDangerousGoodsDefinitionCommand
   */
  handlingNotes?: string | null
}
/**
 *
 * @export
 * @interface CreateDepartureCheckRailcarCommand
 */
export interface CreateDepartureCheckRailcarCommand {
  /**
   *
   * @type {number}
   * @memberof CreateDepartureCheckRailcarCommand
   */
  railcarTrackPositionId: number
  /**
   *
   * @type {number}
   * @memberof CreateDepartureCheckRailcarCommand
   */
  sequenceNumber: number
  /**
   *
   * @type {number}
   * @memberof CreateDepartureCheckRailcarCommand
   */
  length?: number | null
  /**
   *
   * @type {Array<UpdateContainerOrderDto>}
   * @memberof CreateDepartureCheckRailcarCommand
   */
  ordersToBeUpdated?: Array<UpdateContainerOrderDto> | null
  /**
   *
   * @type {Array<number>}
   * @memberof CreateDepartureCheckRailcarCommand
   */
  ordersIdsToBeDeleted?: Array<number> | null
}
/**
 *
 * @export
 * @interface CreateGeneralCargoOrderCommand
 */
export interface CreateGeneralCargoOrderCommand {
  /**
   *
   * @type {string}
   * @memberof CreateGeneralCargoOrderCommand
   */
  referenceNumber?: string | null
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof CreateGeneralCargoOrderCommand
   */
  direction: CarrierVisitDirection
  /**
   *
   * @type {Array<string>}
   * @memberof CreateGeneralCargoOrderCommand
   */
  imoClasses: Array<string>
  /**
   *
   * @type {CarrierType}
   * @memberof CreateGeneralCargoOrderCommand
   */
  carrierVisitType?: CarrierType
  /**
   *
   * @type {number}
   * @memberof CreateGeneralCargoOrderCommand
   */
  carrierVisitId?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateGeneralCargoOrderCommand
   */
  customerId?: number | null
  /**
   *
   * @type {string}
   * @memberof CreateGeneralCargoOrderCommand
   */
  billOfLading?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateGeneralCargoOrderCommand
   */
  lotNumber?: string | null
  /**
   *
   * @type {number}
   * @memberof CreateGeneralCargoOrderCommand
   */
  commodityId: number
  /**
   *
   * @type {number}
   * @memberof CreateGeneralCargoOrderCommand
   */
  plannedAmount: number
  /**
   *
   * @type {number}
   * @memberof CreateGeneralCargoOrderCommand
   */
  actualAmount?: number | null
  /**
   *
   * @type {string}
   * @memberof CreateGeneralCargoOrderCommand
   */
  handlingInstructions?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateGeneralCargoOrderCommand
   */
  waggon?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CreateGeneralCargoOrderCommand
   */
  identifiableItems?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof CreateGeneralCargoOrderCommand
   */
  packageId?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateGeneralCargoOrderCommand
   */
  grossWeight?: number | null
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof CreateGeneralCargoOrderCommand
   */
  weightUnit?: WeightMeasurementUnit
}

/**
 *
 * @export
 * @interface CreateHoldCommand
 */
export interface CreateHoldCommand {
  /**
   *
   * @type {number}
   * @memberof CreateHoldCommand
   */
  orderId: number
  /**
   *
   * @type {string}
   * @memberof CreateHoldCommand
   */
  reason: string
  /**
   *
   * @type {string}
   * @memberof CreateHoldCommand
   */
  contact: string
}
/**
 *
 * @export
 * @interface CreateNonNumericOrderCommand
 */
export interface CreateNonNumericOrderCommand {
  /**
   *
   * @type {string}
   * @memberof CreateNonNumericOrderCommand
   */
  shippingLine: string
  /**
   *
   * @type {string}
   * @memberof CreateNonNumericOrderCommand
   */
  referenceNumber: string
  /**
   *
   * @type {string}
   * @memberof CreateNonNumericOrderCommand
   */
  containerIsoCode?: string | null
  /**
   *
   * @type {ContainerAttributes}
   * @memberof CreateNonNumericOrderCommand
   */
  containerAttributes?: ContainerAttributes
  /**
   *
   * @type {number}
   * @memberof CreateNonNumericOrderCommand
   */
  containerMaxPayload?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateNonNumericOrderCommand
   */
  amount: number
  /**
   *
   * @type {CarrierType}
   * @memberof CreateNonNumericOrderCommand
   */
  modeOfTransport: CarrierType
  /**
   *
   * @type {number}
   * @memberof CreateNonNumericOrderCommand
   */
  carrierVisitId?: number | null
}

/**
 *
 * @export
 * @interface CreateOrAssignOrderToRailcarTrackPositionCommand
 */
export interface CreateOrAssignOrderToRailcarTrackPositionCommand {
  /**
   *
   * @type {number}
   * @memberof CreateOrAssignOrderToRailcarTrackPositionCommand
   */
  orderId?: number
  /**
   *
   * @type {number}
   * @memberof CreateOrAssignOrderToRailcarTrackPositionCommand
   */
  railVisitId?: number
  /**
   *
   * @type {number}
   * @memberof CreateOrAssignOrderToRailcarTrackPositionCommand
   */
  railcarId?: number
}
/**
 *
 * @export
 * @interface CreateOrdersCommand
 */
export interface CreateOrdersCommand {
  /**
   *
   * @type {CarrierType}
   * @memberof CreateOrdersCommand
   */
  carrierVisitType: CarrierType
  /**
   *
   * @type {Array<CreateContainerOrderDto>}
   * @memberof CreateOrdersCommand
   */
  orders?: Array<CreateContainerOrderDto> | null
}

/**
 *
 * @export
 * @interface CreateRailVisitCommand
 */
export interface CreateRailVisitCommand {
  /**
   *
   * @type {string}
   * @memberof CreateRailVisitCommand
   */
  name: string
  /**
   *
   * @type {CargoType}
   * @memberof CreateRailVisitCommand
   */
  cargoType: CargoType
  /**
   *
   * @type {string}
   * @memberof CreateRailVisitCommand
   */
  trainOperator?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateRailVisitCommand
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateRailVisitCommand
   */
  etd?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CreateRailVisitCommand
   */
  inboundTripIds?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof CreateRailVisitCommand
   */
  outboundTripIds?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof CreateRailVisitCommand
   */
  railTrackIds: Array<string>
  /**
   *
   * @type {number}
   * @memberof CreateRailVisitCommand
   */
  railcarEstimate?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateRailVisitCommand
   */
  dischargeEstimate?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateRailVisitCommand
   */
  loadEstimate?: number | null
  /**
   *
   * @type {CarrierVisitScheduleDto}
   * @memberof CreateRailVisitCommand
   */
  schedule?: CarrierVisitScheduleDto
}

/**
 *
 * @export
 * @interface CreateRailcarAndOrdersCommand
 */
export interface CreateRailcarAndOrdersCommand {
  /**
   *
   * @type {number}
   * @memberof CreateRailcarAndOrdersCommand
   */
  carrierVisitId: number
  /**
   *
   * @type {string}
   * @memberof CreateRailcarAndOrdersCommand
   */
  railTrackId: string
  /**
   *
   * @type {string}
   * @memberof CreateRailcarAndOrdersCommand
   */
  railcarNumber: string
  /**
   *
   * @type {number}
   * @memberof CreateRailcarAndOrdersCommand
   */
  sequenceNumber: number
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof CreateRailcarAndOrdersCommand
   */
  direction: CarrierVisitDirection
  /**
   *
   * @type {number}
   * @memberof CreateRailcarAndOrdersCommand
   */
  length?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateRailcarAndOrdersCommand
   */
  maxPayload?: number | null
  /**
   *
   * @type {Array<UpdateContainerOrderDto>}
   * @memberof CreateRailcarAndOrdersCommand
   */
  ordersToBeAssigned?: Array<UpdateContainerOrderDto> | null
}

/**
 *
 * @export
 * @interface CreateRailcarCommand
 */
export interface CreateRailcarCommand {
  /**
   *
   * @type {string}
   * @memberof CreateRailcarCommand
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof CreateRailcarCommand
   */
  length?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateRailcarCommand
   */
  maxPayload?: number | null
}
/**
 *
 * @export
 * @interface CreateStrippingOrderCommand
 */
export interface CreateStrippingOrderCommand {
  /**
   *
   * @type {number}
   * @memberof CreateStrippingOrderCommand
   */
  containerJourneyId: number
  /**
   *
   * @type {Array<CreateCargoItemDto>}
   * @memberof CreateStrippingOrderCommand
   */
  packingList: Array<CreateCargoItemDto>
}
/**
 *
 * @export
 * @interface CreateStuffingOrderCommand
 */
export interface CreateStuffingOrderCommand {
  /**
   *
   * @type {number}
   * @memberof CreateStuffingOrderCommand
   */
  containerJourneyId: number
  /**
   *
   * @type {Array<CreateCargoItemDto>}
   * @memberof CreateStuffingOrderCommand
   */
  packingList: Array<CreateCargoItemDto>
}
/**
 *
 * @export
 * @interface CreateTruckAppointmentCommand
 */
export interface CreateTruckAppointmentCommand {
  /**
   *
   * @type {string}
   * @memberof CreateTruckAppointmentCommand
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateTruckAppointmentCommand
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateTruckAppointmentCommand
   */
  driverName?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateTruckAppointmentCommand
   */
  licensePlate: string
  /**
   *
   * @type {Array<OrderWithDirectionDto>}
   * @memberof CreateTruckAppointmentCommand
   */
  ordersWithDirection: Array<OrderWithDirectionDto>
  /**
   *
   * @type {Array<CreateContainerOrderDto>}
   * @memberof CreateTruckAppointmentCommand
   */
  ordersToBeCreated: Array<CreateContainerOrderDto>
  /**
   *
   * @type {Array<TruckAppointmentNnrOrderDto>}
   * @memberof CreateTruckAppointmentCommand
   */
  nnrOrders: Array<TruckAppointmentNnrOrderDto>
}
/**
 *
 * @export
 * @interface CreateTruckCommand
 */
export interface CreateTruckCommand {
  /**
   *
   * @type {string}
   * @memberof CreateTruckCommand
   */
  licensePlate: string
}
/**
 *
 * @export
 * @interface CreateTruckVisitCommand
 */
export interface CreateTruckVisitCommand {
  /**
   *
   * @type {number}
   * @memberof CreateTruckVisitCommand
   */
  truckId: number
  /**
   *
   * @type {string}
   * @memberof CreateTruckVisitCommand
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateTruckVisitCommand
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateTruckVisitCommand
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateTruckVisitCommand
   */
  atd?: string | null
}
/**
 *
 * @export
 * @interface CreateVesselCommand
 */
export interface CreateVesselCommand {
  /**
   *
   * @type {string}
   * @memberof CreateVesselCommand
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateVesselCommand
   */
  shippingLine?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateVesselCommand
   */
  imoNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateVesselCommand
   */
  mmsi?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateVesselCommand
   */
  callSign?: string | null
  /**
   *
   * @type {number}
   * @memberof CreateVesselCommand
   */
  length: number
  /**
   *
   * @type {number}
   * @memberof CreateVesselCommand
   */
  width: number
}
/**
 *
 * @export
 * @interface CreateVesselVisitCommand
 */
export interface CreateVesselVisitCommand {
  /**
   *
   * @type {Array<number>}
   * @memberof CreateVesselVisitCommand
   */
  vesselIds: Array<number>
  /**
   *
   * @type {CargoType}
   * @memberof CreateVesselVisitCommand
   */
  cargoType: CargoType
  /**
   *
   * @type {string}
   * @memberof CreateVesselVisitCommand
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateVesselVisitCommand
   */
  etb?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateVesselVisitCommand
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateVesselVisitCommand
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateVesselVisitCommand
   */
  atb?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateVesselVisitCommand
   */
  atd?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CreateVesselVisitCommand
   */
  inboundTripIds?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof CreateVesselVisitCommand
   */
  outboundTripIds?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof CreateVesselVisitCommand
   */
  importDraft?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateVesselVisitCommand
   */
  exportDraft?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateVesselVisitCommand
   */
  dischargeEstimate?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateVesselVisitCommand
   */
  loadEstimate?: number | null
  /**
   *
   * @type {CarrierVisitScheduleDto}
   * @memberof CreateVesselVisitCommand
   */
  schedule?: CarrierVisitScheduleDto
  /**
   *
   * @type {BerthSide}
   * @memberof CreateVesselVisitCommand
   */
  berthSide?: BerthSide
  /**
   *
   * @type {number}
   * @memberof CreateVesselVisitCommand
   */
  qmmFrom?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateVesselVisitCommand
   */
  qmmTo?: number | null
}

/**
 *
 * @export
 * @interface CustomerResponseDto
 */
export interface CustomerResponseDto {
  /**
   *
   * @type {number}
   * @memberof CustomerResponseDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof CustomerResponseDto
   */
  referenceId: number
  /**
   *
   * @type {string}
   * @memberof CustomerResponseDto
   */
  name: string
}
/**
 *
 * @export
 * @interface DangerousGoodsDefinitionDto
 */
export interface DangerousGoodsDefinitionDto {
  /**
   *
   * @type {number}
   * @memberof DangerousGoodsDefinitionDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof DangerousGoodsDefinitionDto
   */
  unNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof DangerousGoodsDefinitionDto
   */
  material?: string | null
  /**
   *
   * @type {string}
   * @memberof DangerousGoodsDefinitionDto
   */
  primaryImoClass?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof DangerousGoodsDefinitionDto
   */
  secondaryImoClasses?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof DangerousGoodsDefinitionDto
   */
  handlingNotes?: string | null
}
/**
 *
 * @export
 * @interface DangerousGoodsDefinitionDtoPaginatedList
 */
export interface DangerousGoodsDefinitionDtoPaginatedList {
  /**
   *
   * @type {Array<DangerousGoodsDefinitionDto>}
   * @memberof DangerousGoodsDefinitionDtoPaginatedList
   */
  payload: Array<DangerousGoodsDefinitionDto>
  /**
   *
   * @type {number}
   * @memberof DangerousGoodsDefinitionDtoPaginatedList
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof DangerousGoodsDefinitionDtoPaginatedList
   */
  totalPages: number
  /**
   *
   * @type {number}
   * @memberof DangerousGoodsDefinitionDtoPaginatedList
   */
  totalCount: number
  /**
   *
   * @type {boolean}
   * @memberof DangerousGoodsDefinitionDtoPaginatedList
   */
  hasPreviousPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof DangerousGoodsDefinitionDtoPaginatedList
   */
  hasNextPage: boolean
}
/**
 *
 * @export
 * @interface DeleteOrdersCommand
 */
export interface DeleteOrdersCommand {
  /**
   *
   * @type {Array<number>}
   * @memberof DeleteOrdersCommand
   */
  ids?: Array<number> | null
  /**
   *
   * @type {boolean}
   * @memberof DeleteOrdersCommand
   */
  deleteLinkedOrders?: boolean
}
/**
 *
 * @export
 * @interface DeleteRailcarTrackPositionCommand
 */
export interface DeleteRailcarTrackPositionCommand {
  /**
   *
   * @type {number}
   * @memberof DeleteRailcarTrackPositionCommand
   */
  railVisitId: number
  /**
   *
   * @type {Array<number>}
   * @memberof DeleteRailcarTrackPositionCommand
   */
  ids: Array<number>
  /**
   *
   * @type {CheckType}
   * @memberof DeleteRailcarTrackPositionCommand
   */
  checkType?: CheckType
}

/**
 *
 * @export
 * @enum {string}
 */

export const DoorDirection = {
  Anyway: 'Anyway',
  Inward: 'Inward',
  Outward: 'Outward',
} as const

export type DoorDirection = (typeof DoorDirection)[keyof typeof DoorDirection]

/**
 *
 * @export
 * @enum {string}
 */

export const EventType = {
  Unknown: 'UNKNOWN',
  TestString: 'TEST_STRING',
  OrdersUpserted: 'ORDERS_UPSERTED',
  OrdersDeleted: 'ORDERS_DELETED',
  WorkInstructionCreated: 'WORK_INSTRUCTION_CREATED',
  YardBlockSlotReservationCreated: 'YARD_BLOCK_SLOT_RESERVATION_CREATED',
  ContainerMoved: 'CONTAINER_MOVED',
  WorkInstructionFinished: 'WORK_INSTRUCTION_FINISHED',
  VesselReadyForOperation: 'VESSEL_READY_FOR_OPERATION',
  TrainReadyForOperation: 'TRAIN_READY_FOR_OPERATION',
  TruckHasBeenAllowedEntry: 'TRUCK_HAS_BEEN_ALLOWED_ENTRY',
  OrderHasBeenAllowedEntry: 'ORDER_HAS_BEEN_ALLOWED_ENTRY',
  OrderHasBeenAllowedExit: 'ORDER_HAS_BEEN_ALLOWED_EXIT',
  TruckHasBeenAllowedExit: 'TRUCK_HAS_BEEN_ALLOWED_EXIT',
  VesselVisitUpserted: 'VESSEL_VISIT_UPSERTED',
  YardBlockCreated: 'YARD_BLOCK_CREATED',
  YardBlockUpdated: 'YARD_BLOCK_UPDATED',
  YardBlockDeleted: 'YARD_BLOCK_DELETED',
  YardBlockSlotsCreated: 'YARD_BLOCK_SLOTS_CREATED',
  YardBlockSlotsUpdated: 'YARD_BLOCK_SLOTS_UPDATED',
  YardBlockSlotsDeleted: 'YARD_BLOCK_SLOTS_DELETED',
  WorkInstructionStarted: 'WORK_INSTRUCTION_STARTED',
  CancelWorkInstructionStarted: 'CANCEL_WORK_INSTRUCTION_STARTED',
  YardInternalMovesPlanned: 'YARD_INTERNAL_MOVES_PLANNED',
  BerthModified: 'BERTH_MODIFIED',
  ContainerPositionUpdated: 'CONTAINER_POSITION_UPDATED',
  ContainerStackIn: 'CONTAINER_STACK_IN',
  YardBlockSlotReservationFailed: 'YARD_BLOCK_SLOT_RESERVATION_FAILED',
  CarrierVisitsDeleted: 'CARRIER_VISITS_DELETED',
  ReeferTemperatureUpserted: 'REEFER_TEMPERATURE_UPSERTED',
  ContainersUpserted: 'CONTAINERS_UPSERTED',
  CustomerUpserted: 'CUSTOMER_UPSERTED',
  CustomerDeleted: 'CUSTOMER_DELETED',
  TenantUpserted: 'TENANT_UPSERTED',
  RailVisitUpserted: 'RAIL_VISIT_UPSERTED',
  MoveTriggered: 'MOVE_TRIGGERED',
  CommodityUpserted: 'COMMODITY_UPSERTED',
  CommodityDeleted: 'COMMODITY_DELETED',
  PackageUpserted: 'PACKAGE_UPSERTED',
  PackageDeleted: 'PACKAGE_DELETED',
  StuffingOrderUpserted: 'STUFFING_ORDER_UPSERTED',
  StuffingOrderDeleted: 'STUFFING_ORDER_DELETED',
  StrippingOrderUpserted: 'STRIPPING_ORDER_UPSERTED',
  StrippingOrderDeleted: 'STRIPPING_ORDER_DELETED',
  TenantDeleted: 'TENANT_DELETED',
  CoolingOrderUpserted: 'COOLING_ORDER_UPSERTED',
  CoolingOrderDeleted: 'COOLING_ORDER_DELETED',
  ContainersPlannedBlockUpserted: 'CONTAINERS_PLANNED_BLOCK_UPSERTED',
  RailTrackUpserted: 'RAIL_TRACK_UPSERTED',
  RailTrackDeleted: 'RAIL_TRACK_DELETED',
  RailcarTrackPositionsUpserted: 'RAILCAR_TRACK_POSITIONS_UPSERTED',
  RailcarTrackPositionsDeleted: 'RAILCAR_TRACK_POSITIONS_DELETED',
  RailcarTrackPositionReadyForOperation: 'RAILCAR_TRACK_POSITION_READY_FOR_OPERATION',
  TruckVisitUpserted: 'TRUCK_VISIT_UPSERTED',
  ContainerDamageLocationUpserted: 'CONTAINER_DAMAGE_LOCATION_UPSERTED',
  ContainerDamageLocationDeleted: 'CONTAINER_DAMAGE_LOCATION_DELETED',
  ContainerDamageTypeUpserted: 'CONTAINER_DAMAGE_TYPE_UPSERTED',
  ContainerDamageTypeDeleted: 'CONTAINER_DAMAGE_TYPE_DELETED',
  RailcarTrackPositionDepartureCheck: 'RAILCAR_TRACK_POSITION_DEPARTURE_CHECK',
} as const

export type EventType = (typeof EventType)[keyof typeof EventType]

/**
 *
 * @export
 * @interface FirstAvailableOrderInGroupDto
 */
export interface FirstAvailableOrderInGroupDto {
  /**
   *
   * @type {number}
   * @memberof FirstAvailableOrderInGroupDto
   */
  linkedCustomerGroupingVisitId?: number
  /**
   *
   * @type {string}
   * @memberof FirstAvailableOrderInGroupDto
   */
  customerGrouping?: string | null
  /**
   *
   * @type {number}
   * @memberof FirstAvailableOrderInGroupDto
   */
  inboundOrderId?: number
  /**
   *
   * @type {number}
   * @memberof FirstAvailableOrderInGroupDto
   */
  outboundOrderId?: number | null
  /**
   *
   * @type {string}
   * @memberof FirstAvailableOrderInGroupDto
   */
  containerNumber?: string | null
}
/**
 *
 * @export
 * @interface GetFirstAvailableOrderInGroupQuery
 */
export interface GetFirstAvailableOrderInGroupQuery {
  /**
   *
   * @type {Array<GetFirstAvailableOrderInGroupQueryItem>}
   * @memberof GetFirstAvailableOrderInGroupQuery
   */
  queryItems?: Array<GetFirstAvailableOrderInGroupQueryItem> | null
}
/**
 *
 * @export
 * @interface GetFirstAvailableOrderInGroupQueryItem
 */
export interface GetFirstAvailableOrderInGroupQueryItem {
  /**
   *
   * @type {number}
   * @memberof GetFirstAvailableOrderInGroupQueryItem
   */
  linkedCustomerGroupingVisitId?: number
  /**
   *
   * @type {string}
   * @memberof GetFirstAvailableOrderInGroupQueryItem
   */
  customerGrouping?: string | null
}
/**
 *
 * @export
 * @interface GetLinkableOrdersQueryQuery
 */
export interface GetLinkableOrdersQueryQuery {
  /**
   *
   * @type {Array<string>}
   * @memberof GetLinkableOrdersQueryQuery
   */
  numbers: Array<string>
}
/**
 *
 * @export
 * @enum {string}
 */

export const GetTruckAppointmentsQueryType = {
  All: 'All',
  CheckIn: 'CheckIn',
  CheckOut: 'CheckOut',
} as const

export type GetTruckAppointmentsQueryType =
  (typeof GetTruckAppointmentsQueryType)[keyof typeof GetTruckAppointmentsQueryType]

/**
 *
 * @export
 * @interface HoldResponseDto
 */
export interface HoldResponseDto {
  /**
   *
   * @type {number}
   * @memberof HoldResponseDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof HoldResponseDto
   */
  orderId: number
  /**
   *
   * @type {string}
   * @memberof HoldResponseDto
   */
  reason: string
  /**
   *
   * @type {string}
   * @memberof HoldResponseDto
   */
  contact: string
  /**
   *
   * @type {HoldStatus}
   * @memberof HoldResponseDto
   */
  status: HoldStatus
}

/**
 *
 * @export
 * @enum {string}
 */

export const HoldStatus = {
  Active: 'Active',
  Resolved: 'Resolved',
} as const

export type HoldStatus = (typeof HoldStatus)[keyof typeof HoldStatus]

/**
 *
 * @export
 * @interface IssueResponseDto
 */
export interface IssueResponseDto {
  /**
   *
   * @type {number}
   * @memberof IssueResponseDto
   */
  id: number
  /**
   *
   * @type {IssueType}
   * @memberof IssueResponseDto
   */
  issueType: IssueType
  /**
   *
   * @type {IssueStatus}
   * @memberof IssueResponseDto
   */
  issueStatus: IssueStatus
  /**
   *
   * @type {ObjectType}
   * @memberof IssueResponseDto
   */
  objectType: ObjectType
  /**
   *
   * @type {number}
   * @memberof IssueResponseDto
   */
  objectId: number
  /**
   *
   * @type {string}
   * @memberof IssueResponseDto
   */
  notes?: string | null
}

/**
 *
 * @export
 * @enum {string}
 */

export const IssueStatus = {
  Unresolved: 'Unresolved',
  FollowUp: 'FollowUp',
  Resolved: 'Resolved',
} as const

export type IssueStatus = (typeof IssueStatus)[keyof typeof IssueStatus]

/**
 *
 * @export
 * @enum {string}
 */

export const IssueType = {
  Unknown: 'UNKNOWN',
  NoRailTrackPositionAssigned: 'NO_RAIL_TRACK_POSITION_ASSIGNED',
  MissingContainer: 'MISSING_CONTAINER',
  MissingRailcar: 'MISSING_RAILCAR',
} as const

export type IssueType = (typeof IssueType)[keyof typeof IssueType]

/**
 *
 * @export
 * @enum {string}
 */

export const LengthMeasurementUnit = {
  Meter: 'Meter',
  Centimeter: 'Centimeter',
  Millimeters: 'Millimeters',
  Feet: 'Feet',
  Inches: 'Inches',
} as const

export type LengthMeasurementUnit =
  (typeof LengthMeasurementUnit)[keyof typeof LengthMeasurementUnit]

/**
 *
 * @export
 * @interface LinkedOrderResponseDto
 */
export interface LinkedOrderResponseDto {
  /**
   *
   * @type {number}
   * @memberof LinkedOrderResponseDto
   */
  id: number
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof LinkedOrderResponseDto
   */
  direction: CarrierVisitDirection
  /**
   *
   * @type {OrderStatus}
   * @memberof LinkedOrderResponseDto
   */
  status: OrderStatus
  /**
   *
   * @type {string}
   * @memberof LinkedOrderResponseDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {number}
   * @memberof LinkedOrderResponseDto
   */
  carrierVisitId?: number | null
  /**
   *
   * @type {CarrierType}
   * @memberof LinkedOrderResponseDto
   */
  carrierVisitType?: CarrierType
  /**
   *
   * @type {number}
   * @memberof LinkedOrderResponseDto
   */
  customerId?: number | null
  /**
   *
   * @type {string}
   * @memberof LinkedOrderResponseDto
   */
  customerName?: string | null
  /**
   *
   * @type {CarrierVisitDto}
   * @memberof LinkedOrderResponseDto
   */
  carrierVisit?: CarrierVisitDto
}

/**
 *
 * @export
 * @interface NonNumericOrderDto
 */
export interface NonNumericOrderDto {
  /**
   *
   * @type {number}
   * @memberof NonNumericOrderDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof NonNumericOrderDto
   */
  shippingLine: string
  /**
   *
   * @type {string}
   * @memberof NonNumericOrderDto
   */
  referenceNumber: string
  /**
   *
   * @type {string}
   * @memberof NonNumericOrderDto
   */
  containerIsoCode?: string | null
  /**
   *
   * @type {number}
   * @memberof NonNumericOrderDto
   */
  containerLength?: number | null
  /**
   *
   * @type {ContainerHeight}
   * @memberof NonNumericOrderDto
   */
  containerHeight: ContainerHeight
  /**
   *
   * @type {ContainerType}
   * @memberof NonNumericOrderDto
   */
  containerType: ContainerType
  /**
   *
   * @type {number}
   * @memberof NonNumericOrderDto
   */
  containerMaxPayload?: number | null
  /**
   *
   * @type {number}
   * @memberof NonNumericOrderDto
   */
  amount: number
  /**
   *
   * @type {number}
   * @memberof NonNumericOrderDto
   */
  fulfilled: number
  /**
   *
   * @type {number}
   * @memberof NonNumericOrderDto
   */
  orderCount: number
  /**
   *
   * @type {CarrierType}
   * @memberof NonNumericOrderDto
   */
  modeOfTransport: CarrierType
  /**
   *
   * @type {number}
   * @memberof NonNumericOrderDto
   */
  carrierVisitId?: number | null
  /**
   *
   * @type {boolean}
   * @memberof NonNumericOrderDto
   */
  isClosed?: boolean
  /**
   *
   * @type {Array<number>}
   * @memberof NonNumericOrderDto
   */
  orderIds: Array<number>
}

/**
 *
 * @export
 * @interface NonNumericOrderDtoPaginatedList
 */
export interface NonNumericOrderDtoPaginatedList {
  /**
   *
   * @type {Array<NonNumericOrderDto>}
   * @memberof NonNumericOrderDtoPaginatedList
   */
  payload: Array<NonNumericOrderDto>
  /**
   *
   * @type {number}
   * @memberof NonNumericOrderDtoPaginatedList
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof NonNumericOrderDtoPaginatedList
   */
  totalPages: number
  /**
   *
   * @type {number}
   * @memberof NonNumericOrderDtoPaginatedList
   */
  totalCount: number
  /**
   *
   * @type {boolean}
   * @memberof NonNumericOrderDtoPaginatedList
   */
  hasPreviousPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof NonNumericOrderDtoPaginatedList
   */
  hasNextPage: boolean
}
/**
 *
 * @export
 * @enum {string}
 */

export const ObjectType = {
  Unknown: 'Unknown',
  Order: 'Order',
  RailcarTrackPosition: 'RailcarTrackPosition',
} as const

export type ObjectType = (typeof ObjectType)[keyof typeof ObjectType]

/**
 *
 * @export
 * @interface OrderCustomerSequenceDto
 */
export interface OrderCustomerSequenceDto {
  /**
   *
   * @type {number}
   * @memberof OrderCustomerSequenceDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof OrderCustomerSequenceDto
   */
  customerGrouping?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderCustomerSequenceDto
   */
  groupingSequence?: number | null
}
/**
 *
 * @export
 * @interface OrderListOrderDto
 */
export interface OrderListOrderDto {
  /**
   *
   * @type {number}
   * @memberof OrderListOrderDto
   */
  id?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  containerNumber: string
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  isoCode?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  typeCode?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderListOrderDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {boolean}
   * @memberof OrderListOrderDto
   */
  isEmpty?: boolean
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  operator?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  portOfLoading?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  portOfDischarge?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  finalDestination?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  waggon?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderListOrderDto
   */
  sequence?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  railTrackId?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  consignee?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  atConsignee?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  notes?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  operationalInstructions?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  content?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderListOrderDto
   */
  temperature?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderListOrderDto
   */
  linkedOrderId?: number | null
  /**
   *
   * @type {Array<string>}
   * @memberof OrderListOrderDto
   */
  imoClasses: Array<string>
  /**
   *
   * @type {DoorDirection}
   * @memberof OrderListOrderDto
   */
  doorDirection?: DoorDirection
  /**
   *
   * @type {boolean}
   * @memberof OrderListOrderDto
   */
  hasSeals?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof OrderListOrderDto
   */
  seals?: Array<string> | null
}

/**
 *
 * @export
 * @interface OrderResponseDto
 */
export interface OrderResponseDto {
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  id: number
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof OrderResponseDto
   */
  direction: CarrierVisitDirection
  /**
   *
   * @type {OrderStatus}
   * @memberof OrderResponseDto
   */
  status: OrderStatus
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  closedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  nonNumericOrderId?: number | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  linkedOrderId?: number | null
  /**
   *
   * @type {boolean}
   * @memberof OrderResponseDto
   */
  hasServiceOrders: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof OrderResponseDto
   */
  seals?: Array<string> | null
  /**
   *
   * @type {boolean}
   * @memberof OrderResponseDto
   */
  hasSeals?: boolean
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  portOfLoading?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  portOfDischarge?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  temperature?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  consignee?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  atConsignee?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  carrierVisitId?: number | null
  /**
   *
   * @type {CarrierType}
   * @memberof OrderResponseDto
   */
  carrierVisitType?: CarrierType
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  railcarTrackPositionId?: number | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  railcarId?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  waggon?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  railTrackId?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  railTrackName?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  railcarSequenceNumber?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  checkinDate?: string | null
  /**
   *
   * @type {boolean}
   * @memberof OrderResponseDto
   */
  isDeliveryByTruck?: boolean | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  releaseEnd?: string | null
  /**
   *
   * @type {boolean}
   * @memberof OrderResponseDto
   */
  isRestowed: boolean
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  customerId?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  customerName?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  customerReferenceId?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  listName?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  customerGrouping?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  groupingSequence?: number | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  linkedCustomerGroupingVisitId?: number | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  containerId?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  containerNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  containerIsoCode?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  containerLength?: number | null
  /**
   *
   * @type {ContainerHeight}
   * @memberof OrderResponseDto
   */
  containerHeight?: ContainerHeight
  /**
   *
   * @type {ContainerType}
   * @memberof OrderResponseDto
   */
  containerType: ContainerType
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  containerMaxGross?: number | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  containerTare?: number | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof OrderResponseDto
   */
  weightUnit?: WeightMeasurementUnit
  /**
   *
   * @type {boolean}
   * @memberof OrderResponseDto
   */
  isDamaged?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OrderResponseDto
   */
  isDangerous?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OrderResponseDto
   */
  isEmpty: boolean
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  operator?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  yardLocation?: string | null
  /**
   *
   * @type {boolean}
   * @memberof OrderResponseDto
   */
  isOnTerminal?: boolean
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  content?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  notes?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  operationalInstructions?: string | null
  /**
   *
   * @type {Array<DangerousGoodsDefinitionDto>}
   * @memberof OrderResponseDto
   */
  dangerousGoodsDefinitions?: Array<DangerousGoodsDefinitionDto> | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  commodityId?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  commodityName?: string | null
  /**
   *
   * @type {Array<CommodityAttribute>}
   * @memberof OrderResponseDto
   */
  commodityAttributes?: Array<CommodityAttribute> | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  packageId?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  packageName?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  lotNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderResponseDto
   */
  handlingInstructions?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  plannedCargoAmount?: number | null
  /**
   *
   * @type {number}
   * @memberof OrderResponseDto
   */
  actualCargoAmount?: number | null
  /**
   *
   * @type {Array<string>}
   * @memberof OrderResponseDto
   */
  identifiableItems?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof OrderResponseDto
   */
  imoClasses: Array<string>
  /**
   *
   * @type {Array<HoldResponseDto>}
   * @memberof OrderResponseDto
   */
  holds: Array<HoldResponseDto>
  /**
   *
   * @type {boolean}
   * @memberof OrderResponseDto
   */
  isJobFinished?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OrderResponseDto
   */
  hasIssue?: boolean
  /**
   *
   * @type {DoorDirection}
   * @memberof OrderResponseDto
   */
  doorDirection?: DoorDirection
}

/**
 *
 * @export
 * @enum {string}
 */

export const OrderStatus = {
  Open: 'Open',
  Fulfilled: 'Fulfilled',
} as const

export type OrderStatus = (typeof OrderStatus)[keyof typeof OrderStatus]

/**
 *
 * @export
 * @interface OrderSyncDto
 */
export interface OrderSyncDto {
  /**
   *
   * @type {Array<OrderUpsertedDto>}
   * @memberof OrderSyncDto
   */
  upsertedOrders?: Array<OrderUpsertedDto> | null
  /**
   *
   * @type {Array<number>}
   * @memberof OrderSyncDto
   */
  deletedOrderIds?: Array<number> | null
}
/**
 *
 * @export
 * @interface OrderUpsertedDto
 */
export interface OrderUpsertedDto {
  /**
   *
   * @type {number}
   * @memberof OrderUpsertedDto
   */
  orderId: number
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof OrderUpsertedDto
   */
  handlingDirection: CarrierVisitDirection
  /**
   *
   * @type {string}
   * @memberof OrderUpsertedDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderUpsertedDto
   */
  customerId?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderUpsertedDto
   */
  customerGrouping?: string | null
  /**
   *
   * @type {number}
   * @memberof OrderUpsertedDto
   */
  groupingSequence?: number | null
  /**
   *
   * @type {number}
   * @memberof OrderUpsertedDto
   */
  linkedOrderId?: number | null
  /**
   *
   * @type {number}
   * @memberof OrderUpsertedDto
   */
  carrierVisitId?: number | null
  /**
   *
   * @type {CarrierType}
   * @memberof OrderUpsertedDto
   */
  carrierType?: CarrierType
  /**
   *
   * @type {string}
   * @memberof OrderUpsertedDto
   */
  portOfLoading?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderUpsertedDto
   */
  portOfDischarge?: string | null
  /**
   *
   * @type {boolean}
   * @memberof OrderUpsertedDto
   */
  isAccidentalDischarge?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OrderUpsertedDto
   */
  isToBeStoredInYard?: boolean
  /**
   *
   * @type {number}
   * @memberof OrderUpsertedDto
   */
  containerId?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderUpsertedDto
   */
  containerNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderUpsertedDto
   */
  waggon?: string | null
  /**
   *
   * @type {CargoInformationDto}
   * @memberof OrderUpsertedDto
   */
  cargoItem?: CargoInformationDto
  /**
   *
   * @type {CargoInformationDto}
   * @memberof OrderUpsertedDto
   */
  cargoInformation?: CargoInformationDto
  /**
   *
   * @type {number}
   * @memberof OrderUpsertedDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {boolean}
   * @memberof OrderUpsertedDto
   */
  isDangerous?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OrderUpsertedDto
   */
  isEmpty?: boolean
  /**
   *
   * @type {string}
   * @memberof OrderUpsertedDto
   */
  temperature?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof OrderUpsertedDto
   */
  imoClasses?: Array<string> | null
  /**
   *
   * @type {boolean}
   * @memberof OrderUpsertedDto
   */
  hasActiveHolds?: boolean
  /**
   *
   * @type {OrderStatus}
   * @memberof OrderUpsertedDto
   */
  status?: OrderStatus
  /**
   *
   * @type {string}
   * @memberof OrderUpsertedDto
   */
  consignee?: string | null
  /**
   *
   * @type {boolean}
   * @memberof OrderUpsertedDto
   */
  isJobFinished?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OrderUpsertedDto
   */
  hasIssue?: boolean
  /**
   *
   * @type {number}
   * @memberof OrderUpsertedDto
   */
  railcarTrackPositionId?: number | null
  /**
   *
   * @type {Array<string>}
   * @memberof OrderUpsertedDto
   */
  seals: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof OrderUpsertedDto
   */
  hasSeals?: boolean
  /**
   *
   * @type {DoorDirection}
   * @memberof OrderUpsertedDto
   */
  doorDirection?: DoorDirection
  /**
   *
   * @type {string}
   * @memberof OrderUpsertedDto
   */
  operationalInstructions?: string | null
}

/**
 *
 * @export
 * @interface OrderWithDirectionDto
 */
export interface OrderWithDirectionDto {
  /**
   *
   * @type {number}
   * @memberof OrderWithDirectionDto
   */
  id: number
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof OrderWithDirectionDto
   */
  direction: CarrierVisitDirection
}

/**
 *
 * @export
 * @interface PackageResponseDto
 */
export interface PackageResponseDto {
  /**
   *
   * @type {number}
   * @memberof PackageResponseDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof PackageResponseDto
   */
  name: string
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof PackageResponseDto
   */
  weightUnit?: WeightMeasurementUnit
  /**
   *
   * @type {number}
   * @memberof PackageResponseDto
   */
  height?: number | null
  /**
   *
   * @type {number}
   * @memberof PackageResponseDto
   */
  width?: number | null
  /**
   *
   * @type {number}
   * @memberof PackageResponseDto
   */
  length?: number | null
  /**
   *
   * @type {LengthMeasurementUnit}
   * @memberof PackageResponseDto
   */
  lengthUnit?: LengthMeasurementUnit
}

/**
 *
 * @export
 * @interface PierResponseDto
 */
export interface PierResponseDto {
  /**
   *
   * @type {string}
   * @memberof PierResponseDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PierResponseDto
   */
  name: string
}
/**
 *
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
  [key: string]: any

  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  type?: string | null
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  title?: string | null
  /**
   *
   * @type {number}
   * @memberof ProblemDetails
   */
  status?: number | null
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  detail?: string | null
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  instance?: string | null
}
/**
 *
 * @export
 * @interface RailTrackResponseDto
 */
export interface RailTrackResponseDto {
  /**
   *
   * @type {string}
   * @memberof RailTrackResponseDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof RailTrackResponseDto
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof RailTrackResponseDto
   */
  length?: number | null
  /**
   *
   * @type {LengthMeasurementUnit}
   * @memberof RailTrackResponseDto
   */
  lengthUnit: LengthMeasurementUnit
  /**
   *
   * @type {boolean}
   * @memberof RailTrackResponseDto
   */
  isDeleted: boolean
}

/**
 *
 * @export
 * @interface RailVisitResponseDto
 */
export interface RailVisitResponseDto {
  /**
   *
   * @type {number}
   * @memberof RailVisitResponseDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof RailVisitResponseDto
   */
  identifier?: string | null
  /**
   *
   * @type {string}
   * @memberof RailVisitResponseDto
   */
  driverName?: string | null
  /**
   *
   * @type {string}
   * @memberof RailVisitResponseDto
   */
  eta?: string
  /**
   *
   * @type {string}
   * @memberof RailVisitResponseDto
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof RailVisitResponseDto
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof RailVisitResponseDto
   */
  atd?: string | null
  /**
   *
   * @type {CargoType}
   * @memberof RailVisitResponseDto
   */
  cargoType: CargoType
  /**
   *
   * @type {Array<number>}
   * @memberof RailVisitResponseDto
   */
  carrierIds: Array<number>
  /**
   *
   * @type {CarrierVisitStatus}
   * @memberof RailVisitResponseDto
   */
  status: CarrierVisitStatus
  /**
   *
   * @type {Array<BreakTimeResponseDto>}
   * @memberof RailVisitResponseDto
   */
  breakTimes?: Array<BreakTimeResponseDto> | null
  /**
   *
   * @type {string}
   * @memberof RailVisitResponseDto
   */
  name?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof RailVisitResponseDto
   */
  inboundTripIds?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof RailVisitResponseDto
   */
  outboundTripIds?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof RailVisitResponseDto
   */
  dischargeEstimate?: number | null
  /**
   *
   * @type {number}
   * @memberof RailVisitResponseDto
   */
  loadEstimate?: number | null
  /**
   *
   * @type {number}
   * @memberof RailVisitResponseDto
   */
  railcarEstimate?: number | null
  /**
   *
   * @type {number}
   * @memberof RailVisitResponseDto
   */
  dischargeTotal: number
  /**
   *
   * @type {number}
   * @memberof RailVisitResponseDto
   */
  dischargeConfirmed: number
  /**
   *
   * @type {number}
   * @memberof RailVisitResponseDto
   */
  loadTotal: number
  /**
   *
   * @type {number}
   * @memberof RailVisitResponseDto
   */
  loadConfirmed: number
  /**
   *
   * @type {string}
   * @memberof RailVisitResponseDto
   */
  trainOperator?: string | null
  /**
   *
   * @type {Array<number>}
   * @memberof RailVisitResponseDto
   */
  orderIds: Array<number>
  /**
   *
   * @type {Array<string>}
   * @memberof RailVisitResponseDto
   * @deprecated
   */
  railTrackIds: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof RailVisitResponseDto
   */
  assignedRailTrackIds: Array<string>
  /**
   *
   * @type {Array<number>}
   * @memberof RailVisitResponseDto
   */
  railcarTrackPositionIds: Array<number>
  /**
   *
   * @type {boolean}
   * @memberof RailVisitResponseDto
   */
  isConflicted?: boolean
  /**
   *
   * @type {boolean}
   * @memberof RailVisitResponseDto
   */
  isInboundRailcarLengthConflicted?: boolean
  /**
   *
   * @type {boolean}
   * @memberof RailVisitResponseDto
   */
  isOutboundRailcarLengthConflicted?: boolean
  /**
   *
   * @type {boolean}
   * @memberof RailVisitResponseDto
   */
  hasAssignedRailcarsWithoutLength?: boolean
}

/**
 *
 * @export
 * @interface RailVisitResponseDtoPaginatedList
 */
export interface RailVisitResponseDtoPaginatedList {
  /**
   *
   * @type {Array<RailVisitResponseDto>}
   * @memberof RailVisitResponseDtoPaginatedList
   */
  payload: Array<RailVisitResponseDto>
  /**
   *
   * @type {number}
   * @memberof RailVisitResponseDtoPaginatedList
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof RailVisitResponseDtoPaginatedList
   */
  totalPages: number
  /**
   *
   * @type {number}
   * @memberof RailVisitResponseDtoPaginatedList
   */
  totalCount: number
  /**
   *
   * @type {boolean}
   * @memberof RailVisitResponseDtoPaginatedList
   */
  hasPreviousPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof RailVisitResponseDtoPaginatedList
   */
  hasNextPage: boolean
}
/**
 *
 * @export
 * @interface RailcarResponseDto
 */
export interface RailcarResponseDto {
  /**
   *
   * @type {number}
   * @memberof RailcarResponseDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof RailcarResponseDto
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof RailcarResponseDto
   */
  length?: number | null
  /**
   *
   * @type {number}
   * @memberof RailcarResponseDto
   */
  maxPayload?: number | null
}
/**
 *
 * @export
 * @interface RailcarResponseDtoPaginatedList
 */
export interface RailcarResponseDtoPaginatedList {
  /**
   *
   * @type {Array<RailcarResponseDto>}
   * @memberof RailcarResponseDtoPaginatedList
   */
  payload: Array<RailcarResponseDto>
  /**
   *
   * @type {number}
   * @memberof RailcarResponseDtoPaginatedList
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof RailcarResponseDtoPaginatedList
   */
  totalPages: number
  /**
   *
   * @type {number}
   * @memberof RailcarResponseDtoPaginatedList
   */
  totalCount: number
  /**
   *
   * @type {boolean}
   * @memberof RailcarResponseDtoPaginatedList
   */
  hasPreviousPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof RailcarResponseDtoPaginatedList
   */
  hasNextPage: boolean
}
/**
 *
 * @export
 * @interface RailcarTrackPositionResponseDto
 */
export interface RailcarTrackPositionResponseDto {
  /**
   *
   * @type {number}
   * @memberof RailcarTrackPositionResponseDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof RailcarTrackPositionResponseDto
   */
  railVisitId: number
  /**
   *
   * @type {number}
   * @memberof RailcarTrackPositionResponseDto
   */
  railcarId: number
  /**
   *
   * @type {string}
   * @memberof RailcarTrackPositionResponseDto
   */
  railcarName: string
  /**
   *
   * @type {string}
   * @memberof RailcarTrackPositionResponseDto
   */
  railTrackId: string
  /**
   *
   * @type {string}
   * @memberof RailcarTrackPositionResponseDto
   */
  railTrackName: string
  /**
   *
   * @type {number}
   * @memberof RailcarTrackPositionResponseDto
   */
  railcarSequenceNumber: number
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof RailcarTrackPositionResponseDto
   */
  direction: CarrierVisitDirection
  /**
   *
   * @type {string}
   * @memberof RailcarTrackPositionResponseDto
   */
  checkinDate?: string | null
  /**
   *
   * @type {string}
   * @memberof RailcarTrackPositionResponseDto
   */
  loadPreparationCheckedDate?: string | null
  /**
   *
   * @type {string}
   * @memberof RailcarTrackPositionResponseDto
   */
  checkoutDate?: string | null
  /**
   *
   * @type {number}
   * @memberof RailcarTrackPositionResponseDto
   */
  railcarLength?: number | null
}

/**
 *
 * @export
 * @interface ReleaseOrderDto
 */
export interface ReleaseOrderDto {
  /**
   *
   * @type {string}
   * @memberof ReleaseOrderDto
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ReleaseOrderDto
   */
  containerNumber: string
  /**
   *
   * @type {boolean}
   * @memberof ReleaseOrderDto
   */
  isOrder: boolean
  /**
   *
   * @type {string}
   * @memberof ReleaseOrderDto
   */
  status: string
  /**
   *
   * @type {string}
   * @memberof ReleaseOrderDto
   */
  orderReferenceNumber?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ReleaseOrderDto
   */
  isReleased?: boolean | null
  /**
   *
   * @type {string}
   * @memberof ReleaseOrderDto
   */
  releaseStart?: string | null
  /**
   *
   * @type {string}
   * @memberof ReleaseOrderDto
   */
  releaseEnd?: string | null
}
/**
 *
 * @export
 * @interface ReleaseOrderDtoPaginatedList
 */
export interface ReleaseOrderDtoPaginatedList {
  /**
   *
   * @type {Array<ReleaseOrderDto>}
   * @memberof ReleaseOrderDtoPaginatedList
   */
  payload: Array<ReleaseOrderDto>
  /**
   *
   * @type {number}
   * @memberof ReleaseOrderDtoPaginatedList
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof ReleaseOrderDtoPaginatedList
   */
  totalPages: number
  /**
   *
   * @type {number}
   * @memberof ReleaseOrderDtoPaginatedList
   */
  totalCount: number
  /**
   *
   * @type {boolean}
   * @memberof ReleaseOrderDtoPaginatedList
   */
  hasPreviousPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof ReleaseOrderDtoPaginatedList
   */
  hasNextPage: boolean
}
/**
 *
 * @export
 * @enum {string}
 */

export const RepeatByDateKind = {
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
} as const

export type RepeatByDateKind = (typeof RepeatByDateKind)[keyof typeof RepeatByDateKind]

/**
 *
 * @export
 * @interface ResolveContainerDamagesCommand
 */
export interface ResolveContainerDamagesCommand {
  /**
   *
   * @type {number}
   * @memberof ResolveContainerDamagesCommand
   */
  containerId: number
  /**
   *
   * @type {Array<number>}
   * @memberof ResolveContainerDamagesCommand
   */
  damageIds: Array<number>
  /**
   *
   * @type {ContainerDamageResolutionReason}
   * @memberof ResolveContainerDamagesCommand
   */
  resolutionReason?: ContainerDamageResolutionReason
}

/**
 *
 * @export
 * @interface StrippingOrderResponseDto
 */
export interface StrippingOrderResponseDto {
  /**
   *
   * @type {number}
   * @memberof StrippingOrderResponseDto
   */
  id: number
  /**
   *
   * @type {Array<CargoItemResponseDto>}
   * @memberof StrippingOrderResponseDto
   */
  packingList: Array<CargoItemResponseDto>
  /**
   *
   * @type {OrderStatus}
   * @memberof StrippingOrderResponseDto
   */
  status: OrderStatus
}

/**
 *
 * @export
 * @interface StuffingOrderResponseDto
 */
export interface StuffingOrderResponseDto {
  /**
   *
   * @type {number}
   * @memberof StuffingOrderResponseDto
   */
  id: number
  /**
   *
   * @type {Array<CargoItemResponseDto>}
   * @memberof StuffingOrderResponseDto
   */
  packingList: Array<CargoItemResponseDto>
  /**
   *
   * @type {OrderStatus}
   * @memberof StuffingOrderResponseDto
   */
  status: OrderStatus
}

/**
 *
 * @export
 * @interface SyncContainerUpdatedDto
 */
export interface SyncContainerUpdatedDto {
  /**
   *
   * @type {number}
   * @memberof SyncContainerUpdatedDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof SyncContainerUpdatedDto
   */
  number?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncContainerUpdatedDto
   */
  isoCode?: string | null
  /**
   *
   * @type {boolean}
   * @memberof SyncContainerUpdatedDto
   */
  isReefer?: boolean | null
  /**
   *
   * @type {boolean}
   * @memberof SyncContainerUpdatedDto
   */
  isDamaged: boolean
  /**
   *
   * @type {boolean}
   * @memberof SyncContainerUpdatedDto
   */
  isCriticalDamage: boolean
  /**
   *
   * @type {number}
   * @memberof SyncContainerUpdatedDto
   */
  length?: number | null
  /**
   *
   * @type {ContainerHeight}
   * @memberof SyncContainerUpdatedDto
   */
  height?: ContainerHeight
  /**
   *
   * @type {ContainerType}
   * @memberof SyncContainerUpdatedDto
   */
  type: ContainerType
  /**
   *
   * @type {string}
   * @memberof SyncContainerUpdatedDto
   */
  operator?: string | null
  /**
   *
   * @type {boolean}
   * @memberof SyncContainerUpdatedDto
   */
  isEmpty: boolean
  /**
   *
   * @type {boolean}
   * @memberof SyncContainerUpdatedDto
   */
  isOnTerminal: boolean
  /**
   *
   * @type {number}
   * @memberof SyncContainerUpdatedDto
   */
  tenantId: number
  /**
   *
   * @type {string}
   * @memberof SyncContainerUpdatedDto
   */
  updated: string
}

/**
 *
 * @export
 * @interface SyncContainersDto
 */
export interface SyncContainersDto {
  /**
   *
   * @type {Array<SyncContainerUpdatedDto>}
   * @memberof SyncContainersDto
   */
  updated: Array<SyncContainerUpdatedDto>
  /**
   *
   * @type {Array<SyncDeletedDto>}
   * @memberof SyncContainersDto
   */
  deleted: Array<SyncDeletedDto>
}
/**
 *
 * @export
 * @interface SyncCoolingOrderUpdatedDto
 */
export interface SyncCoolingOrderUpdatedDto {
  /**
   *
   * @type {number}
   * @memberof SyncCoolingOrderUpdatedDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof SyncCoolingOrderUpdatedDto
   */
  inboundOrderId: number
  /**
   *
   * @type {number}
   * @memberof SyncCoolingOrderUpdatedDto
   */
  requestedTemperature: number
  /**
   *
   * @type {boolean}
   * @memberof SyncCoolingOrderUpdatedDto
   */
  isMonitoringRequired: boolean
  /**
   *
   * @type {number}
   * @memberof SyncCoolingOrderUpdatedDto
   */
  monitoringFrequency?: number | null
  /**
   *
   * @type {boolean}
   * @memberof SyncCoolingOrderUpdatedDto
   */
  isPlugInRequired: boolean
  /**
   *
   * @type {OrderStatus}
   * @memberof SyncCoolingOrderUpdatedDto
   */
  status: OrderStatus
  /**
   *
   * @type {number}
   * @memberof SyncCoolingOrderUpdatedDto
   */
  tenantId: number
  /**
   *
   * @type {string}
   * @memberof SyncCoolingOrderUpdatedDto
   */
  updated: string
}

/**
 *
 * @export
 * @interface SyncCoolingOrdersDto
 */
export interface SyncCoolingOrdersDto {
  /**
   *
   * @type {Array<SyncCoolingOrderUpdatedDto>}
   * @memberof SyncCoolingOrdersDto
   */
  updated: Array<SyncCoolingOrderUpdatedDto>
  /**
   *
   * @type {Array<SyncDeletedDto>}
   * @memberof SyncCoolingOrdersDto
   */
  deleted: Array<SyncDeletedDto>
}
/**
 *
 * @export
 * @interface SyncDeletedDto
 */
export interface SyncDeletedDto {
  /**
   *
   * @type {number}
   * @memberof SyncDeletedDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof SyncDeletedDto
   */
  tenantId: number
}
/**
 *
 * @export
 * @interface SyncOrderUpdatedDto
 */
export interface SyncOrderUpdatedDto {
  /**
   *
   * @type {number}
   * @memberof SyncOrderUpdatedDto
   */
  orderId: number
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof SyncOrderUpdatedDto
   */
  handlingDirection: CarrierVisitDirection
  /**
   *
   * @type {string}
   * @memberof SyncOrderUpdatedDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {number}
   * @memberof SyncOrderUpdatedDto
   */
  customerId?: number | null
  /**
   *
   * @type {string}
   * @memberof SyncOrderUpdatedDto
   */
  customerGrouping?: string | null
  /**
   *
   * @type {number}
   * @memberof SyncOrderUpdatedDto
   */
  groupingSequence?: number | null
  /**
   *
   * @type {number}
   * @memberof SyncOrderUpdatedDto
   */
  linkedOrderId?: number | null
  /**
   *
   * @type {number}
   * @memberof SyncOrderUpdatedDto
   */
  carrierVisitId?: number | null
  /**
   *
   * @type {CarrierType}
   * @memberof SyncOrderUpdatedDto
   */
  carrierType?: CarrierType
  /**
   *
   * @type {string}
   * @memberof SyncOrderUpdatedDto
   */
  portOfLoading?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncOrderUpdatedDto
   */
  portOfDischarge?: string | null
  /**
   *
   * @type {boolean}
   * @memberof SyncOrderUpdatedDto
   */
  isAccidentalDischarge?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SyncOrderUpdatedDto
   */
  isToBeStoredInYard?: boolean
  /**
   *
   * @type {number}
   * @memberof SyncOrderUpdatedDto
   */
  containerId?: number | null
  /**
   *
   * @type {string}
   * @memberof SyncOrderUpdatedDto
   */
  containerNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncOrderUpdatedDto
   */
  waggon?: string | null
  /**
   *
   * @type {CargoInformationDto}
   * @memberof SyncOrderUpdatedDto
   */
  cargoItem?: CargoInformationDto
  /**
   *
   * @type {CargoInformationDto}
   * @memberof SyncOrderUpdatedDto
   */
  cargoInformation?: CargoInformationDto
  /**
   *
   * @type {number}
   * @memberof SyncOrderUpdatedDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {boolean}
   * @memberof SyncOrderUpdatedDto
   */
  isDangerous?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SyncOrderUpdatedDto
   */
  isEmpty?: boolean
  /**
   *
   * @type {string}
   * @memberof SyncOrderUpdatedDto
   */
  temperature?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof SyncOrderUpdatedDto
   */
  imoClasses?: Array<string> | null
  /**
   *
   * @type {boolean}
   * @memberof SyncOrderUpdatedDto
   */
  hasActiveHolds?: boolean
  /**
   *
   * @type {OrderStatus}
   * @memberof SyncOrderUpdatedDto
   */
  status?: OrderStatus
  /**
   *
   * @type {string}
   * @memberof SyncOrderUpdatedDto
   */
  consignee?: string | null
  /**
   *
   * @type {boolean}
   * @memberof SyncOrderUpdatedDto
   */
  isJobFinished?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SyncOrderUpdatedDto
   */
  hasIssue?: boolean
  /**
   *
   * @type {number}
   * @memberof SyncOrderUpdatedDto
   */
  railcarTrackPositionId?: number | null
  /**
   *
   * @type {Array<string>}
   * @memberof SyncOrderUpdatedDto
   */
  seals: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof SyncOrderUpdatedDto
   */
  hasSeals?: boolean
  /**
   *
   * @type {DoorDirection}
   * @memberof SyncOrderUpdatedDto
   */
  doorDirection?: DoorDirection
  /**
   *
   * @type {string}
   * @memberof SyncOrderUpdatedDto
   */
  operationalInstructions?: string | null
  /**
   *
   * @type {number}
   * @memberof SyncOrderUpdatedDto
   */
  tenantId: number
  /**
   *
   * @type {string}
   * @memberof SyncOrderUpdatedDto
   */
  updated: string
  /**
   *
   * @type {string}
   * @memberof SyncOrderUpdatedDto
   */
  closedAt?: string | null
}

/**
 *
 * @export
 * @interface SyncOrdersDto
 */
export interface SyncOrdersDto {
  /**
   *
   * @type {Array<SyncOrderUpdatedDto>}
   * @memberof SyncOrdersDto
   */
  updated: Array<SyncOrderUpdatedDto>
  /**
   *
   * @type {Array<SyncDeletedDto>}
   * @memberof SyncOrdersDto
   */
  deleted: Array<SyncDeletedDto>
}
/**
 *
 * @export
 * @interface SyncRailVisitUpdatedDto
 */
export interface SyncRailVisitUpdatedDto {
  /**
   *
   * @type {number}
   * @memberof SyncRailVisitUpdatedDto
   */
  tenantId: number
  /**
   *
   * @type {string}
   * @memberof SyncRailVisitUpdatedDto
   */
  updated: string
  /**
   *
   * @type {number}
   * @memberof SyncRailVisitUpdatedDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof SyncRailVisitUpdatedDto
   */
  name?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncRailVisitUpdatedDto
   */
  carrierIdentifier?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncRailVisitUpdatedDto
   */
  trainOperator?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncRailVisitUpdatedDto
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncRailVisitUpdatedDto
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncRailVisitUpdatedDto
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncRailVisitUpdatedDto
   */
  atd?: string | null
  /**
   *
   * @type {CarrierVisitStatus}
   * @memberof SyncRailVisitUpdatedDto
   */
  status?: CarrierVisitStatus
  /**
   *
   * @type {Array<string>}
   * @memberof SyncRailVisitUpdatedDto
   */
  inboundTripIds?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof SyncRailVisitUpdatedDto
   */
  outboundTripIds?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof SyncRailVisitUpdatedDto
   */
  railTrackIds?: Array<string> | null
  /**
   *
   * @type {Array<number>}
   * @memberof SyncRailVisitUpdatedDto
   */
  carrierIds?: Array<number> | null
}

/**
 *
 * @export
 * @interface SyncRailVisitsDto
 */
export interface SyncRailVisitsDto {
  /**
   *
   * @type {Array<SyncRailVisitUpdatedDto>}
   * @memberof SyncRailVisitsDto
   */
  updated: Array<SyncRailVisitUpdatedDto>
  /**
   *
   * @type {Array<SyncDeletedDto>}
   * @memberof SyncRailVisitsDto
   */
  deleted: Array<SyncDeletedDto>
}
/**
 *
 * @export
 * @interface SyncRailcarTrackPositionUpdatedDto
 */
export interface SyncRailcarTrackPositionUpdatedDto {
  /**
   *
   * @type {number}
   * @memberof SyncRailcarTrackPositionUpdatedDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof SyncRailcarTrackPositionUpdatedDto
   */
  railVisitId: number
  /**
   *
   * @type {string}
   * @memberof SyncRailcarTrackPositionUpdatedDto
   */
  railTrackId: string
  /**
   *
   * @type {number}
   * @memberof SyncRailcarTrackPositionUpdatedDto
   */
  railcarId: number
  /**
   *
   * @type {string}
   * @memberof SyncRailcarTrackPositionUpdatedDto
   */
  railcarName: string
  /**
   *
   * @type {number}
   * @memberof SyncRailcarTrackPositionUpdatedDto
   */
  railcarSequenceNumber: number
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof SyncRailcarTrackPositionUpdatedDto
   */
  direction: CarrierVisitDirection
  /**
   *
   * @type {string}
   * @memberof SyncRailcarTrackPositionUpdatedDto
   */
  checkinDate?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncRailcarTrackPositionUpdatedDto
   */
  loadPreparationCheckedDate?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncRailcarTrackPositionUpdatedDto
   */
  checkoutDate?: string | null
  /**
   *
   * @type {number}
   * @memberof SyncRailcarTrackPositionUpdatedDto
   */
  tenantId: number
  /**
   *
   * @type {string}
   * @memberof SyncRailcarTrackPositionUpdatedDto
   */
  updated: string
}

/**
 *
 * @export
 * @interface SyncRailcarTrackPositionsDto
 */
export interface SyncRailcarTrackPositionsDto {
  /**
   *
   * @type {Array<SyncRailcarTrackPositionUpdatedDto>}
   * @memberof SyncRailcarTrackPositionsDto
   */
  updated: Array<SyncRailcarTrackPositionUpdatedDto>
  /**
   *
   * @type {Array<SyncDeletedDto>}
   * @memberof SyncRailcarTrackPositionsDto
   */
  deleted: Array<SyncDeletedDto>
}
/**
 *
 * @export
 * @enum {string}
 */

export const SyncStatus = {
  Pending: 'Pending',
  Fetching: 'Fetching',
  Success: 'Success',
  Failed: 'Failed',
  NotFound: 'NotFound',
  Invalid: 'Invalid',
} as const

export type SyncStatus = (typeof SyncStatus)[keyof typeof SyncStatus]

/**
 *
 * @export
 * @interface SyncStrippingOrderUpdatedDto
 */
export interface SyncStrippingOrderUpdatedDto {
  /**
   *
   * @type {number}
   * @memberof SyncStrippingOrderUpdatedDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof SyncStrippingOrderUpdatedDto
   */
  inboundOrderId: number
  /**
   *
   * @type {Array<CargoItemDto>}
   * @memberof SyncStrippingOrderUpdatedDto
   */
  packingList: Array<CargoItemDto>
  /**
   *
   * @type {OrderStatus}
   * @memberof SyncStrippingOrderUpdatedDto
   */
  status: OrderStatus
  /**
   *
   * @type {number}
   * @memberof SyncStrippingOrderUpdatedDto
   */
  tenantId: number
  /**
   *
   * @type {string}
   * @memberof SyncStrippingOrderUpdatedDto
   */
  updated: string
}

/**
 *
 * @export
 * @interface SyncStrippingOrdersDto
 */
export interface SyncStrippingOrdersDto {
  /**
   *
   * @type {Array<SyncStrippingOrderUpdatedDto>}
   * @memberof SyncStrippingOrdersDto
   */
  updated: Array<SyncStrippingOrderUpdatedDto>
  /**
   *
   * @type {Array<SyncDeletedDto>}
   * @memberof SyncStrippingOrdersDto
   */
  deleted: Array<SyncDeletedDto>
}
/**
 *
 * @export
 * @interface SyncStuffingOrderUpdatedDto
 */
export interface SyncStuffingOrderUpdatedDto {
  /**
   *
   * @type {number}
   * @memberof SyncStuffingOrderUpdatedDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof SyncStuffingOrderUpdatedDto
   */
  inboundOrderId: number
  /**
   *
   * @type {Array<CargoItemDto>}
   * @memberof SyncStuffingOrderUpdatedDto
   */
  packingList: Array<CargoItemDto>
  /**
   *
   * @type {OrderStatus}
   * @memberof SyncStuffingOrderUpdatedDto
   */
  status: OrderStatus
  /**
   *
   * @type {number}
   * @memberof SyncStuffingOrderUpdatedDto
   */
  tenantId: number
  /**
   *
   * @type {string}
   * @memberof SyncStuffingOrderUpdatedDto
   */
  updated: string
}

/**
 *
 * @export
 * @interface SyncStuffingOrdersDto
 */
export interface SyncStuffingOrdersDto {
  /**
   *
   * @type {Array<SyncStuffingOrderUpdatedDto>}
   * @memberof SyncStuffingOrdersDto
   */
  updated: Array<SyncStuffingOrderUpdatedDto>
  /**
   *
   * @type {Array<SyncDeletedDto>}
   * @memberof SyncStuffingOrdersDto
   */
  deleted: Array<SyncDeletedDto>
}
/**
 *
 * @export
 * @interface SyncTruckVisitsDto
 */
export interface SyncTruckVisitsDto {
  /**
   *
   * @type {Array<SyncTruckVisitsUpdatedDto>}
   * @memberof SyncTruckVisitsDto
   */
  updated: Array<SyncTruckVisitsUpdatedDto>
  /**
   *
   * @type {Array<SyncDeletedDto>}
   * @memberof SyncTruckVisitsDto
   */
  deleted: Array<SyncDeletedDto>
}
/**
 *
 * @export
 * @interface SyncTruckVisitsUpdatedDto
 */
export interface SyncTruckVisitsUpdatedDto {
  /**
   *
   * @type {number}
   * @memberof SyncTruckVisitsUpdatedDto
   */
  truckVisitId: number
  /**
   *
   * @type {string}
   * @memberof SyncTruckVisitsUpdatedDto
   */
  licensePlate: string
  /**
   *
   * @type {string}
   * @memberof SyncTruckVisitsUpdatedDto
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncTruckVisitsUpdatedDto
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncTruckVisitsUpdatedDto
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncTruckVisitsUpdatedDto
   */
  atd?: string | null
  /**
   *
   * @type {CarrierVisitStatus}
   * @memberof SyncTruckVisitsUpdatedDto
   */
  status: CarrierVisitStatus
  /**
   *
   * @type {number}
   * @memberof SyncTruckVisitsUpdatedDto
   */
  tenantId: number
  /**
   *
   * @type {string}
   * @memberof SyncTruckVisitsUpdatedDto
   */
  updated: string
}

/**
 *
 * @export
 * @interface SyncVesselVisitUpdatedDto
 */
export interface SyncVesselVisitUpdatedDto {
  /**
   *
   * @type {number}
   * @memberof SyncVesselVisitUpdatedDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   */
  etb?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   */
  atb?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   */
  atd?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   */
  operationStart?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   */
  operationEnd?: string | null
  /**
   *
   * @type {CarrierVisitStatus}
   * @memberof SyncVesselVisitUpdatedDto
   */
  status?: CarrierVisitStatus
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   * @deprecated
   */
  importVoyage?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   * @deprecated
   */
  exportVoyage?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof SyncVesselVisitUpdatedDto
   */
  inboundTripIds?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof SyncVesselVisitUpdatedDto
   */
  outboundTripIds?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof SyncVesselVisitUpdatedDto
   */
  importDraft?: number | null
  /**
   *
   * @type {number}
   * @memberof SyncVesselVisitUpdatedDto
   */
  exportDraft?: number | null
  /**
   *
   * @type {number}
   * @memberof SyncVesselVisitUpdatedDto
   */
  dischargeEstimate?: number | null
  /**
   *
   * @type {number}
   * @memberof SyncVesselVisitUpdatedDto
   */
  loadEstimate?: number | null
  /**
   *
   * @type {Array<number>}
   * @memberof SyncVesselVisitUpdatedDto
   * @deprecated
   */
  carrierIds?: Array<number> | null
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   * @deprecated
   */
  carrierIdentifier?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   * @deprecated
   */
  vesselName?: string | null
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   * @deprecated
   */
  shippingLine?: string | null
  /**
   *
   * @type {Array<VesselEventDto>}
   * @memberof SyncVesselVisitUpdatedDto
   */
  carriers?: Array<VesselEventDto> | null
  /**
   *
   * @type {BerthSide}
   * @memberof SyncVesselVisitUpdatedDto
   */
  berthSide?: BerthSide
  /**
   *
   * @type {Array<string>}
   * @memberof SyncVesselVisitUpdatedDto
   */
  berthIds?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof SyncVesselVisitUpdatedDto
   */
  qmmFrom?: number | null
  /**
   *
   * @type {number}
   * @memberof SyncVesselVisitUpdatedDto
   */
  qmmTo?: number | null
  /**
   *
   * @type {number}
   * @memberof SyncVesselVisitUpdatedDto
   */
  tenantId: number
  /**
   *
   * @type {string}
   * @memberof SyncVesselVisitUpdatedDto
   */
  updated: string
}

/**
 *
 * @export
 * @interface SyncVesselVisitsDto
 */
export interface SyncVesselVisitsDto {
  /**
   *
   * @type {Array<SyncVesselVisitUpdatedDto>}
   * @memberof SyncVesselVisitsDto
   */
  updated: Array<SyncVesselVisitUpdatedDto>
  /**
   *
   * @type {Array<SyncDeletedDto>}
   * @memberof SyncVesselVisitsDto
   */
  deleted: Array<SyncDeletedDto>
}
/**
 *
 * @export
 * @interface TenantConfigs
 */
export interface TenantConfigs {
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigs
   */
  skipVesselTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigs
   */
  skipRailTally: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigs
   */
  skipGateOut: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigs
   */
  skipVesselVisitReadyForOperation: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigs
   */
  skipReleaseOrder: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigs
   */
  hasGeneralCargo: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigs
   */
  skipYardPlanning: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigs
   */
  skipBerthTimestamp: boolean
  /**
   *
   * @type {boolean}
   * @memberof TenantConfigs
   */
  hasDamageCatalogue: boolean
}
/**
 *
 * @export
 * @interface TenantResponseDto
 */
export interface TenantResponseDto {
  /**
   *
   * @type {string}
   * @memberof TenantResponseDto
   */
  name: string
  /**
   *
   * @type {TenantConfigs}
   * @memberof TenantResponseDto
   */
  configs: TenantConfigs
  /**
   *
   * @type {Array<string>}
   * @memberof TenantResponseDto
   */
  unloCodes: Array<string>
}
/**
 *
 * @export
 * @interface TransportData
 */
export interface TransportData {
  /**
   *
   * @type {number}
   * @memberof TransportData
   */
  carrierVisitId?: number | null
  /**
   *
   * @type {CarrierType}
   * @memberof TransportData
   */
  carrierType: CarrierType
  /**
   *
   * @type {string}
   * @memberof TransportData
   */
  releaseExpired?: string | null
  /**
   *
   * @type {string}
   * @memberof TransportData
   */
  waggon?: string | null
  /**
   *
   * @type {number}
   * @memberof TransportData
   * @deprecated
   */
  railcarTrackPositionId?: number | null
  /**
   *
   * @type {string}
   * @memberof TransportData
   */
  railTrackId?: string | null
  /**
   *
   * @type {number}
   * @memberof TransportData
   */
  railcarId?: number | null
  /**
   *
   * @type {DoorDirection}
   * @memberof TransportData
   */
  doorDirection?: DoorDirection
}

/**
 *
 * @export
 * @interface TruckAppointmentDto
 */
export interface TruckAppointmentDto {
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentDto
   */
  truckVisitId: number
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentDto
   */
  truckId?: number | null
  /**
   *
   * @type {string}
   * @memberof TruckAppointmentDto
   */
  licensePlate: string
  /**
   *
   * @type {string}
   * @memberof TruckAppointmentDto
   */
  driverName?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckAppointmentDto
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckAppointmentDto
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckAppointmentDto
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckAppointmentDto
   */
  atd?: string | null
  /**
   *
   * @type {CarrierVisitStatus}
   * @memberof TruckAppointmentDto
   */
  status: CarrierVisitStatus
  /**
   *
   * @type {Array<TruckAppointmentOrderDto>}
   * @memberof TruckAppointmentDto
   */
  orders: Array<TruckAppointmentOrderDto>
}

/**
 *
 * @export
 * @interface TruckAppointmentDtoPaginatedList
 */
export interface TruckAppointmentDtoPaginatedList {
  /**
   *
   * @type {Array<TruckAppointmentDto>}
   * @memberof TruckAppointmentDtoPaginatedList
   */
  payload: Array<TruckAppointmentDto>
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentDtoPaginatedList
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentDtoPaginatedList
   */
  totalPages: number
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentDtoPaginatedList
   */
  totalCount: number
  /**
   *
   * @type {boolean}
   * @memberof TruckAppointmentDtoPaginatedList
   */
  hasPreviousPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof TruckAppointmentDtoPaginatedList
   */
  hasNextPage: boolean
}
/**
 *
 * @export
 * @interface TruckAppointmentNnrOrderDto
 */
export interface TruckAppointmentNnrOrderDto {
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentNnrOrderDto
   */
  nnrOrderId: number
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentNnrOrderDto
   */
  amountToPickUp: number
}
/**
 *
 * @export
 * @interface TruckAppointmentOrderDto
 */
export interface TruckAppointmentOrderDto {
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentOrderDto
   */
  orderId: number
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentOrderDto
   */
  carrierVisitId?: number | null
  /**
   *
   * @type {string}
   * @memberof TruckAppointmentOrderDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {OrderStatus}
   * @memberof TruckAppointmentOrderDto
   */
  orderStatus: OrderStatus
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentOrderDto
   */
  containerId?: number | null
  /**
   *
   * @type {string}
   * @memberof TruckAppointmentOrderDto
   */
  containerNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckAppointmentOrderDto
   */
  containerIsoCode?: string | null
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentOrderDto
   */
  containerLength?: number | null
  /**
   *
   * @type {ContainerHeight}
   * @memberof TruckAppointmentOrderDto
   */
  containerHeight?: ContainerHeight
  /**
   *
   * @type {ContainerType}
   * @memberof TruckAppointmentOrderDto
   */
  containerType: ContainerType
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentOrderDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {boolean}
   * @memberof TruckAppointmentOrderDto
   */
  isEmpty: boolean
  /**
   *
   * @type {boolean}
   * @memberof TruckAppointmentOrderDto
   */
  isDangerous: boolean
  /**
   *
   * @type {boolean}
   * @memberof TruckAppointmentOrderDto
   */
  isDamaged: boolean
  /**
   *
   * @type {boolean}
   * @memberof TruckAppointmentOrderDto
   */
  isOnTerminal?: boolean
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof TruckAppointmentOrderDto
   */
  carrierVisitDirection: CarrierVisitDirection
  /**
   *
   * @type {string}
   * @memberof TruckAppointmentOrderDto
   */
  operator?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckAppointmentOrderDto
   */
  portOfLoading?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckAppointmentOrderDto
   */
  portOfDischarge?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckAppointmentOrderDto
   */
  temperature?: string | null
  /**
   *
   * @type {Array<HoldResponseDto>}
   * @memberof TruckAppointmentOrderDto
   */
  holds: Array<HoldResponseDto>
  /**
   *
   * @type {Array<string>}
   * @memberof TruckAppointmentOrderDto
   */
  imoClasses: Array<string>
}

/**
 *
 * @export
 * @interface TruckAppointmentOrderDtoPaginatedList
 */
export interface TruckAppointmentOrderDtoPaginatedList {
  /**
   *
   * @type {Array<TruckAppointmentOrderDto>}
   * @memberof TruckAppointmentOrderDtoPaginatedList
   */
  payload: Array<TruckAppointmentOrderDto>
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentOrderDtoPaginatedList
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentOrderDtoPaginatedList
   */
  totalPages: number
  /**
   *
   * @type {number}
   * @memberof TruckAppointmentOrderDtoPaginatedList
   */
  totalCount: number
  /**
   *
   * @type {boolean}
   * @memberof TruckAppointmentOrderDtoPaginatedList
   */
  hasPreviousPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof TruckAppointmentOrderDtoPaginatedList
   */
  hasNextPage: boolean
}
/**
 *
 * @export
 * @interface TruckDto
 */
export interface TruckDto {
  /**
   *
   * @type {number}
   * @memberof TruckDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TruckDto
   */
  licensePlate: string
}
/**
 *
 * @export
 * @interface TruckDtoPaginatedList
 */
export interface TruckDtoPaginatedList {
  /**
   *
   * @type {Array<TruckDto>}
   * @memberof TruckDtoPaginatedList
   */
  payload: Array<TruckDto>
  /**
   *
   * @type {number}
   * @memberof TruckDtoPaginatedList
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof TruckDtoPaginatedList
   */
  totalPages: number
  /**
   *
   * @type {number}
   * @memberof TruckDtoPaginatedList
   */
  totalCount: number
  /**
   *
   * @type {boolean}
   * @memberof TruckDtoPaginatedList
   */
  hasPreviousPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof TruckDtoPaginatedList
   */
  hasNextPage: boolean
}
/**
 *
 * @export
 * @interface TruckVisitDto
 */
export interface TruckVisitDto {
  /**
   *
   * @type {number}
   * @memberof TruckVisitDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TruckVisitDto
   */
  identifier?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckVisitDto
   */
  driverName?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckVisitDto
   */
  eta?: string
  /**
   *
   * @type {string}
   * @memberof TruckVisitDto
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckVisitDto
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckVisitDto
   */
  atd?: string | null
  /**
   *
   * @type {CargoType}
   * @memberof TruckVisitDto
   */
  cargoType: CargoType
  /**
   *
   * @type {Array<number>}
   * @memberof TruckVisitDto
   */
  carrierIds: Array<number>
  /**
   *
   * @type {CarrierVisitStatus}
   * @memberof TruckVisitDto
   */
  status: CarrierVisitStatus
  /**
   *
   * @type {Array<BreakTimeResponseDto>}
   * @memberof TruckVisitDto
   */
  breakTimes?: Array<BreakTimeResponseDto> | null
}

/**
 *
 * @export
 * @interface TruckVisitDtoPaginatedList
 */
export interface TruckVisitDtoPaginatedList {
  /**
   *
   * @type {Array<TruckVisitDto>}
   * @memberof TruckVisitDtoPaginatedList
   */
  payload: Array<TruckVisitDto>
  /**
   *
   * @type {number}
   * @memberof TruckVisitDtoPaginatedList
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof TruckVisitDtoPaginatedList
   */
  totalPages: number
  /**
   *
   * @type {number}
   * @memberof TruckVisitDtoPaginatedList
   */
  totalCount: number
  /**
   *
   * @type {boolean}
   * @memberof TruckVisitDtoPaginatedList
   */
  hasPreviousPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof TruckVisitDtoPaginatedList
   */
  hasNextPage: boolean
}
/**
 *
 * @export
 * @interface UnassignContainerCommand
 */
export interface UnassignContainerCommand {
  /**
   *
   * @type {number}
   * @memberof UnassignContainerCommand
   */
  nonNumericOrderId: number
  /**
   *
   * @type {number}
   * @memberof UnassignContainerCommand
   */
  orderId: number
}
/**
 *
 * @export
 * @interface UnresolveContainerDamagesCommand
 */
export interface UnresolveContainerDamagesCommand {
  /**
   *
   * @type {number}
   * @memberof UnresolveContainerDamagesCommand
   */
  containerId: number
  /**
   *
   * @type {Array<number>}
   * @memberof UnresolveContainerDamagesCommand
   */
  damageIds: Array<number>
}
/**
 *
 * @export
 * @interface UpdateBreakTimeCommand
 */
export interface UpdateBreakTimeCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateBreakTimeCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateBreakTimeCommand
   */
  from: string
  /**
   *
   * @type {string}
   * @memberof UpdateBreakTimeCommand
   */
  to: string
  /**
   *
   * @type {string}
   * @memberof UpdateBreakTimeCommand
   */
  description: string
}
/**
 *
 * @export
 * @interface UpdateCargoItemActualAmountCommand
 */
export interface UpdateCargoItemActualAmountCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateCargoItemActualAmountCommand
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof UpdateCargoItemActualAmountCommand
   */
  actualAmount: number
}
/**
 *
 * @export
 * @interface UpdateCargoItemDto
 */
export interface UpdateCargoItemDto {
  /**
   *
   * @type {number}
   * @memberof UpdateCargoItemDto
   */
  commodityId: number
  /**
   *
   * @type {number}
   * @memberof UpdateCargoItemDto
   */
  customerId: number
  /**
   *
   * @type {number}
   * @memberof UpdateCargoItemDto
   */
  plannedAmount: number
  /**
   *
   * @type {string}
   * @memberof UpdateCargoItemDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateCargoItemDto
   */
  lotNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateCargoItemDto
   */
  handlingInstructions?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateCargoItemDto
   */
  identifiableItems?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof UpdateCargoItemDto
   */
  packageId?: number | null
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof UpdateCargoItemDto
   */
  weightUnit?: WeightMeasurementUnit
  /**
   *
   * @type {number}
   * @memberof UpdateCargoItemDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateCargoItemDto
   */
  imoClasses?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof UpdateCargoItemDto
   */
  id?: number | null
}

/**
 *
 * @export
 * @interface UpdateContainerDamageCommand
 */
export interface UpdateContainerDamageCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateContainerDamageCommand
   */
  containerDamageId: number
  /**
   *
   * @type {string}
   * @memberof UpdateContainerDamageCommand
   */
  description: string
}
/**
 *
 * @export
 * @interface UpdateContainerOrderDto
 */
export interface UpdateContainerOrderDto {
  /**
   *
   * @type {string}
   * @memberof UpdateContainerOrderDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof UpdateContainerOrderDto
   */
  direction: CarrierVisitDirection
  /**
   *
   * @type {number}
   * @memberof UpdateContainerOrderDto
   */
  linkedOrderId?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateContainerOrderDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {string}
   * @memberof UpdateContainerOrderDto
   */
  temperature?: string | null
  /**
   *
   * @type {boolean}
   * @memberof UpdateContainerOrderDto
   */
  isDeliveryByTruck?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateContainerOrderDto
   */
  portOfLoading?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateContainerOrderDto
   */
  portOfDischarge?: string | null
  /**
   *
   * @type {number}
   * @memberof UpdateContainerOrderDto
   */
  railTrackPositionId?: number | null
  /**
   *
   * @type {string}
   * @memberof UpdateContainerOrderDto
   */
  waggon?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateContainerOrderDto
   */
  railTrackId?: string | null
  /**
   *
   * @type {boolean}
   * @memberof UpdateContainerOrderDto
   */
  isEmpty?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateContainerOrderDto
   */
  imoClasses: Array<string>
  /**
   *
   * @type {number}
   * @memberof UpdateContainerOrderDto
   */
  carrierVisitId?: number | null
  /**
   *
   * @type {boolean}
   * @memberof UpdateContainerOrderDto
   */
  isAccidentalDischarge: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateContainerOrderDto
   */
  isToBeStoredInYard: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateContainerOrderDto
   */
  containerIsoCode?: string | null
  /**
   *
   * @type {ContainerAttributes}
   * @memberof UpdateContainerOrderDto
   */
  containerAttributes?: ContainerAttributes
  /**
   *
   * @type {string}
   * @memberof UpdateContainerOrderDto
   */
  operator?: string | null
  /**
   *
   * @type {Array<ContainerDamageUpsertDto>}
   * @memberof UpdateContainerOrderDto
   */
  damages?: Array<ContainerDamageUpsertDto> | null
  /**
   *
   * @type {number}
   * @memberof UpdateContainerOrderDto
   */
  containerMaxGross?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateContainerOrderDto
   */
  containerTare?: number | null
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateContainerOrderDto
   */
  seals?: Array<string> | null
  /**
   *
   * @type {boolean}
   * @memberof UpdateContainerOrderDto
   */
  hasSeals?: boolean
  /**
   *
   * @type {number}
   * @memberof UpdateContainerOrderDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof UpdateContainerOrderDto
   */
  containerNumber?: string | null
  /**
   *
   * @type {DoorDirection}
   * @memberof UpdateContainerOrderDto
   */
  doorDirection?: DoorDirection
}

/**
 *
 * @export
 * @interface UpdateContainerVisitCommand
 */
export interface UpdateContainerVisitCommand {
  /**
   *
   * @type {string}
   * @memberof UpdateContainerVisitCommand
   */
  containerNumber: string
  /**
   *
   * @type {string}
   * @memberof UpdateContainerVisitCommand
   */
  containerIsoCode: string
  /**
   *
   * @type {string}
   * @memberof UpdateContainerVisitCommand
   */
  referenceNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateContainerVisitCommand
   */
  consignee?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateContainerVisitCommand
   */
  notes?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateContainerVisitCommand
   */
  operationalInstructions?: string | null
  /**
   *
   * @type {number}
   * @memberof UpdateContainerVisitCommand
   */
  customerId?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateContainerVisitCommand
   */
  containerId: number
  /**
   *
   * @type {CargoData}
   * @memberof UpdateContainerVisitCommand
   */
  inboundCargo?: CargoData
  /**
   *
   * @type {CargoData}
   * @memberof UpdateContainerVisitCommand
   */
  outboundCargo?: CargoData
  /**
   *
   * @type {TransportData}
   * @memberof UpdateContainerVisitCommand
   */
  inboundTransportData?: TransportData
  /**
   *
   * @type {TransportData}
   * @memberof UpdateContainerVisitCommand
   */
  outboundTransportData?: TransportData
  /**
   *
   * @type {boolean}
   * @memberof UpdateContainerVisitCommand
   */
  inboundHasSeals?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateContainerVisitCommand
   */
  outboundHasSeals?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateContainerVisitCommand
   */
  inboundSeals?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateContainerVisitCommand
   */
  outboundSeals?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof UpdateContainerVisitCommand
   */
  inboundOrderId?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateContainerVisitCommand
   */
  outboundOrderId?: number | null
}
/**
 *
 * @export
 * @interface UpdateCoolingOrderCommand
 */
export interface UpdateCoolingOrderCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateCoolingOrderCommand
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof UpdateCoolingOrderCommand
   */
  requestedTemperature: number
  /**
   *
   * @type {boolean}
   * @memberof UpdateCoolingOrderCommand
   */
  isMonitoringRequired: boolean
  /**
   *
   * @type {number}
   * @memberof UpdateCoolingOrderCommand
   */
  monitoringFrequency?: number | null
  /**
   *
   * @type {boolean}
   * @memberof UpdateCoolingOrderCommand
   */
  isPlugInRequired: boolean
}
/**
 *
 * @export
 * @interface UpdateCustomerSequenceCommand
 */
export interface UpdateCustomerSequenceCommand {
  /**
   *
   * @type {Array<OrderCustomerSequenceDto>}
   * @memberof UpdateCustomerSequenceCommand
   */
  orders: Array<OrderCustomerSequenceDto>
}
/**
 *
 * @export
 * @interface UpdateDangerousGoodsDefinitionCommand
 */
export interface UpdateDangerousGoodsDefinitionCommand {
  /**
   *
   * @type {string}
   * @memberof UpdateDangerousGoodsDefinitionCommand
   */
  unNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateDangerousGoodsDefinitionCommand
   */
  material?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateDangerousGoodsDefinitionCommand
   */
  primaryImoClass?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateDangerousGoodsDefinitionCommand
   */
  secondaryImoClasses?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof UpdateDangerousGoodsDefinitionCommand
   */
  handlingNotes?: string | null
  /**
   *
   * @type {number}
   * @memberof UpdateDangerousGoodsDefinitionCommand
   */
  id?: number
}
/**
 *
 * @export
 * @interface UpdateGeneralCargoOrderCommand
 */
export interface UpdateGeneralCargoOrderCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  referenceNumber?: string | null
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  direction: CarrierVisitDirection
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  imoClasses: Array<string>
  /**
   *
   * @type {CarrierType}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  carrierVisitType?: CarrierType
  /**
   *
   * @type {OrderStatus}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  status?: OrderStatus
  /**
   *
   * @type {number}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  carrierVisitId?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  customerId?: number | null
  /**
   *
   * @type {string}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  billOfLading?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  lotNumber?: string | null
  /**
   *
   * @type {number}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  commodityId: number
  /**
   *
   * @type {number}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  plannedAmount?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  actualAmount?: number | null
  /**
   *
   * @type {string}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  handlingInstructions?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  waggon?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  identifiableItems?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  packageId?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  grossWeight?: number | null
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof UpdateGeneralCargoOrderCommand
   */
  weightUnit?: WeightMeasurementUnit
}

/**
 *
 * @export
 * @interface UpdateNonNumericOrderCommand
 */
export interface UpdateNonNumericOrderCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateNonNumericOrderCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateNonNumericOrderCommand
   */
  shippingLine: string
  /**
   *
   * @type {string}
   * @memberof UpdateNonNumericOrderCommand
   */
  referenceNumber: string
  /**
   *
   * @type {string}
   * @memberof UpdateNonNumericOrderCommand
   */
  containerIsoCode?: string | null
  /**
   *
   * @type {ContainerAttributes}
   * @memberof UpdateNonNumericOrderCommand
   */
  containerAttributes?: ContainerAttributes
  /**
   *
   * @type {number}
   * @memberof UpdateNonNumericOrderCommand
   */
  containerMaxPayload?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateNonNumericOrderCommand
   */
  amount: number
  /**
   *
   * @type {CarrierType}
   * @memberof UpdateNonNumericOrderCommand
   */
  modeOfTransport: CarrierType
  /**
   *
   * @type {number}
   * @memberof UpdateNonNumericOrderCommand
   */
  carrierVisitId?: number | null
}

/**
 *
 * @export
 * @interface UpdateOrderCommand
 */
export interface UpdateOrderCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateOrderCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateOrderCommand
   */
  referenceNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateOrderCommand
   */
  containerNumber: string
  /**
   *
   * @type {string}
   * @memberof UpdateOrderCommand
   */
  containerIsoCode?: string | null
  /**
   *
   * @type {ContainerAttributes}
   * @memberof UpdateOrderCommand
   */
  containerAttributes?: ContainerAttributes
  /**
   *
   * @type {number}
   * @memberof UpdateOrderCommand
   */
  containerMaxGross?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateOrderCommand
   */
  containerTare?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateOrderCommand
   */
  grossWeight?: number | null
  /**
   *
   * @type {boolean}
   * @memberof UpdateOrderCommand
   */
  isEmpty?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateOrderCommand
   */
  operator?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateOrderCommand
   */
  portOfLoading?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateOrderCommand
   */
  portOfDischarge?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateOrderCommand
   */
  temperature?: string | null
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof UpdateOrderCommand
   */
  direction: CarrierVisitDirection
  /**
   *
   * @type {CarrierType}
   * @memberof UpdateOrderCommand
   */
  carrierType: CarrierType
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateOrderCommand
   */
  imoClasses: Array<string>
  /**
   *
   * @type {Array<ContainerDamageUpsertDto>}
   * @memberof UpdateOrderCommand
   */
  containerDamages: Array<ContainerDamageUpsertDto>
  /**
   *
   * @type {boolean}
   * @memberof UpdateOrderCommand
   */
  isDamaged?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateOrderCommand
   */
  isAccidentalDischarge: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateOrderCommand
   */
  isToBeStoredInYard: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateOrderCommand
   */
  hasSeals: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateOrderCommand
   */
  seals?: Array<string> | null
}

/**
 *
 * @export
 * @interface UpdateOrderWeightCommand
 */
export interface UpdateOrderWeightCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateOrderWeightCommand
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof UpdateOrderWeightCommand
   */
  grossWeight: number
}
/**
 *
 * @export
 * @interface UpdateRailVisitCommand
 */
export interface UpdateRailVisitCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateRailVisitCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateRailVisitCommand
   */
  name?: string | null
  /**
   *
   * @type {CargoType}
   * @memberof UpdateRailVisitCommand
   */
  cargoType: CargoType
  /**
   *
   * @type {string}
   * @memberof UpdateRailVisitCommand
   */
  trainOperator?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateRailVisitCommand
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateRailVisitCommand
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateRailVisitCommand
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateRailVisitCommand
   */
  atd?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateRailVisitCommand
   */
  inboundTripIds?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateRailVisitCommand
   */
  outboundTripIds?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof UpdateRailVisitCommand
   */
  railcarEstimate?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateRailVisitCommand
   */
  dischargeEstimate?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateRailVisitCommand
   */
  loadEstimate?: number | null
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateRailVisitCommand
   */
  railTrackIds: Array<string>
}

/**
 *
 * @export
 * @interface UpdateRailVisitStatusCommand
 */
export interface UpdateRailVisitStatusCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateRailVisitStatusCommand
   */
  id: number
  /**
   *
   * @type {CarrierVisitStatus}
   * @memberof UpdateRailVisitStatusCommand
   */
  status: CarrierVisitStatus
}

/**
 *
 * @export
 * @interface UpdateRailcarCommand
 */
export interface UpdateRailcarCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateRailcarCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateRailcarCommand
   */
  name?: string | null
  /**
   *
   * @type {number}
   * @memberof UpdateRailcarCommand
   */
  length?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateRailcarCommand
   */
  maxPayload?: number | null
}
/**
 *
 * @export
 * @interface UpdateRailcarSequenceCommand
 */
export interface UpdateRailcarSequenceCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateRailcarSequenceCommand
   */
  railVisitId: number
  /**
   *
   * @type {string}
   * @memberof UpdateRailcarSequenceCommand
   */
  railTrackId: string
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof UpdateRailcarSequenceCommand
   */
  direction: CarrierVisitDirection
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateRailcarSequenceCommand
   */
  railcarTrackPositionIds: Array<number>
  /**
   *
   * @type {number}
   * @memberof UpdateRailcarSequenceCommand
   */
  railcarSequenceNumber: number
}

/**
 *
 * @export
 * @interface UpdateReleaseOrdersCommand
 */
export interface UpdateReleaseOrdersCommand {
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateReleaseOrdersCommand
   */
  ids: Array<number>
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateReleaseOrdersCommand
   */
  containerNos: Array<string>
  /**
   *
   * @type {string}
   * @memberof UpdateReleaseOrdersCommand
   */
  releaseStart?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateReleaseOrdersCommand
   */
  releaseEnd?: string | null
  /**
   *
   * @type {boolean}
   * @memberof UpdateReleaseOrdersCommand
   */
  isReleased: boolean
}
/**
 *
 * @export
 * @interface UpdateStrippingOrderCargoItemActualAmountCommand
 */
export interface UpdateStrippingOrderCargoItemActualAmountCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateStrippingOrderCargoItemActualAmountCommand
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof UpdateStrippingOrderCargoItemActualAmountCommand
   */
  actualAmount: number
}
/**
 *
 * @export
 * @interface UpdateStrippingOrderCommand
 */
export interface UpdateStrippingOrderCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateStrippingOrderCommand
   */
  id: number
  /**
   *
   * @type {Array<UpdateCargoItemDto>}
   * @memberof UpdateStrippingOrderCommand
   */
  packingList: Array<UpdateCargoItemDto>
  /**
   *
   * @type {number}
   * @memberof UpdateStrippingOrderCommand
   */
  containerJourneyId: number
}
/**
 *
 * @export
 * @interface UpdateStuffingOrderCommand
 */
export interface UpdateStuffingOrderCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateStuffingOrderCommand
   */
  id: number
  /**
   *
   * @type {Array<UpdateCargoItemDto>}
   * @memberof UpdateStuffingOrderCommand
   */
  packingList: Array<UpdateCargoItemDto>
  /**
   *
   * @type {number}
   * @memberof UpdateStuffingOrderCommand
   */
  containerJourneyId?: number | null
}
/**
 *
 * @export
 * @interface UpdateTruckAppointmentCommand
 */
export interface UpdateTruckAppointmentCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateTruckAppointmentCommand
   */
  truckVisitId: number
  /**
   *
   * @type {string}
   * @memberof UpdateTruckAppointmentCommand
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateTruckAppointmentCommand
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateTruckAppointmentCommand
   */
  driverName?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateTruckAppointmentCommand
   */
  licensePlate: string
  /**
   *
   * @type {Array<OrderWithDirectionDto>}
   * @memberof UpdateTruckAppointmentCommand
   */
  ordersWithDirection: Array<OrderWithDirectionDto>
  /**
   *
   * @type {Array<CreateContainerOrderDto>}
   * @memberof UpdateTruckAppointmentCommand
   */
  ordersToBeCreated: Array<CreateContainerOrderDto>
  /**
   *
   * @type {Array<TruckAppointmentNnrOrderDto>}
   * @memberof UpdateTruckAppointmentCommand
   */
  nnrOrders: Array<TruckAppointmentNnrOrderDto>
}
/**
 *
 * @export
 * @interface UpdateTruckCommand
 */
export interface UpdateTruckCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateTruckCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateTruckCommand
   */
  licensePlate: string
}
/**
 *
 * @export
 * @interface UpdateVesselCommand
 */
export interface UpdateVesselCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateVesselCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateVesselCommand
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof UpdateVesselCommand
   */
  imoNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateVesselCommand
   */
  mmsi?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateVesselCommand
   */
  callSign?: string | null
  /**
   *
   * @type {number}
   * @memberof UpdateVesselCommand
   */
  length: number
  /**
   *
   * @type {number}
   * @memberof UpdateVesselCommand
   */
  width: number
  /**
   *
   * @type {string}
   * @memberof UpdateVesselCommand
   */
  shippingLine?: string | null
}
/**
 *
 * @export
 * @interface UpdateVesselVisitBerthsCommand
 */
export interface UpdateVesselVisitBerthsCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateVesselVisitBerthsCommand
   */
  vesselVisitId: number
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateVesselVisitBerthsCommand
   */
  berthIds: Array<number>
}
/**
 *
 * @export
 * @interface UpdateVesselVisitBerthsDto
 */
export interface UpdateVesselVisitBerthsDto {
  /**
   *
   * @type {number}
   * @memberof UpdateVesselVisitBerthsDto
   */
  vesselVisitId: number
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateVesselVisitBerthsDto
   */
  berthIds: Array<number>
}
/**
 *
 * @export
 * @interface UpdateVesselVisitCommand
 */
export interface UpdateVesselVisitCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateVesselVisitCommand
   */
  id: number
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateVesselVisitCommand
   */
  vesselIds: Array<number>
  /**
   *
   * @type {CargoType}
   * @memberof UpdateVesselVisitCommand
   */
  cargoType: CargoType
  /**
   *
   * @type {string}
   * @memberof UpdateVesselVisitCommand
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateVesselVisitCommand
   */
  etb?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateVesselVisitCommand
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateVesselVisitCommand
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateVesselVisitCommand
   */
  atb?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateVesselVisitCommand
   */
  atd?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateVesselVisitCommand
   */
  inboundTripIds?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateVesselVisitCommand
   */
  outboundTripIds?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof UpdateVesselVisitCommand
   */
  importDraft?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateVesselVisitCommand
   */
  exportDraft?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateVesselVisitCommand
   */
  dischargeEstimate?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateVesselVisitCommand
   */
  loadEstimate?: number | null
  /**
   *
   * @type {BerthSide}
   * @memberof UpdateVesselVisitCommand
   */
  berthSide?: BerthSide
  /**
   *
   * @type {number}
   * @memberof UpdateVesselVisitCommand
   */
  qmmFrom?: number | null
  /**
   *
   * @type {number}
   * @memberof UpdateVesselVisitCommand
   */
  qmmTo?: number | null
}

/**
 *
 * @export
 * @interface UpdateVesselVisitStatusCommand
 */
export interface UpdateVesselVisitStatusCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateVesselVisitStatusCommand
   */
  id: number
  /**
   *
   * @type {CarrierVisitStatus}
   * @memberof UpdateVesselVisitStatusCommand
   */
  status: CarrierVisitStatus
}

/**
 *
 * @export
 * @interface UploadContainerDamageImagesResponseDto
 */
export interface UploadContainerDamageImagesResponseDto {
  /**
   *
   * @type {string}
   * @memberof UploadContainerDamageImagesResponseDto
   */
  fileName?: string | null
  /**
   *
   * @type {string}
   * @memberof UploadContainerDamageImagesResponseDto
   */
  imagePath?: string | null
}
/**
 *
 * @export
 * @interface UpsertContainerCommand
 */
export interface UpsertContainerCommand {
  /**
   *
   * @type {string}
   * @memberof UpsertContainerCommand
   */
  number: string
  /**
   *
   * @type {string}
   * @memberof UpsertContainerCommand
   */
  isoCode: string
  /**
   *
   * @type {string}
   * @memberof UpsertContainerCommand
   */
  operator?: string | null
}
/**
 *
 * @export
 * @interface UpsertOrderListCommandV2
 */
export interface UpsertOrderListCommandV2 {
  /**
   *
   * @type {number}
   * @memberof UpsertOrderListCommandV2
   */
  carrierVisitId: number
  /**
   *
   * @type {CarrierVisitDirection}
   * @memberof UpsertOrderListCommandV2
   */
  direction: CarrierVisitDirection
  /**
   *
   * @type {number}
   * @memberof UpsertOrderListCommandV2
   */
  customerId: number
  /**
   *
   * @type {string}
   * @memberof UpsertOrderListCommandV2
   */
  listName?: string | null
  /**
   *
   * @type {Array<OrderListOrderDto>}
   * @memberof UpsertOrderListCommandV2
   */
  addOrders?: Array<OrderListOrderDto> | null
  /**
   *
   * @type {Array<OrderListOrderDto>}
   * @memberof UpsertOrderListCommandV2
   */
  updateOrders?: Array<OrderListOrderDto> | null
  /**
   *
   * @type {Array<number>}
   * @memberof UpsertOrderListCommandV2
   */
  deleteOrders?: Array<number> | null
}

/**
 *
 * @export
 * @interface VesselDto
 */
export interface VesselDto {
  /**
   *
   * @type {number}
   * @memberof VesselDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof VesselDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof VesselDto
   */
  imoNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselDto
   */
  mmsi?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselDto
   */
  callSign?: string | null
  /**
   *
   * @type {number}
   * @memberof VesselDto
   */
  length: number
  /**
   *
   * @type {number}
   * @memberof VesselDto
   */
  width: number
  /**
   *
   * @type {string}
   * @memberof VesselDto
   */
  shippingLine?: string | null
}
/**
 *
 * @export
 * @interface VesselDtoPaginatedList
 */
export interface VesselDtoPaginatedList {
  /**
   *
   * @type {Array<VesselDto>}
   * @memberof VesselDtoPaginatedList
   */
  payload: Array<VesselDto>
  /**
   *
   * @type {number}
   * @memberof VesselDtoPaginatedList
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof VesselDtoPaginatedList
   */
  totalPages: number
  /**
   *
   * @type {number}
   * @memberof VesselDtoPaginatedList
   */
  totalCount: number
  /**
   *
   * @type {boolean}
   * @memberof VesselDtoPaginatedList
   */
  hasPreviousPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof VesselDtoPaginatedList
   */
  hasNextPage: boolean
}
/**
 *
 * @export
 * @interface VesselEventDto
 */
export interface VesselEventDto {
  /**
   *
   * @type {number}
   * @memberof VesselEventDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof VesselEventDto
   */
  name?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselEventDto
   */
  shippingLine?: string | null
}
/**
 *
 * @export
 * @interface VesselVisitDto
 */
export interface VesselVisitDto {
  /**
   *
   * @type {number}
   * @memberof VesselVisitDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof VesselVisitDto
   */
  identifier?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitDto
   */
  driverName?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitDto
   */
  eta?: string
  /**
   *
   * @type {string}
   * @memberof VesselVisitDto
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitDto
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitDto
   */
  atd?: string | null
  /**
   *
   * @type {CargoType}
   * @memberof VesselVisitDto
   */
  cargoType: CargoType
  /**
   *
   * @type {Array<number>}
   * @memberof VesselVisitDto
   */
  carrierIds: Array<number>
  /**
   *
   * @type {CarrierVisitStatus}
   * @memberof VesselVisitDto
   */
  status: CarrierVisitStatus
  /**
   *
   * @type {Array<BreakTimeResponseDto>}
   * @memberof VesselVisitDto
   */
  breakTimes?: Array<BreakTimeResponseDto> | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitDto
   * @deprecated
   */
  importVoyage?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitDto
   * @deprecated
   */
  exportVoyage?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof VesselVisitDto
   */
  inboundTripIds?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof VesselVisitDto
   */
  outboundTripIds?: Array<string> | null
  /**
   *
   * @type {number}
   * @memberof VesselVisitDto
   */
  importDraft?: number | null
  /**
   *
   * @type {number}
   * @memberof VesselVisitDto
   */
  exportDraft?: number | null
  /**
   *
   * @type {number}
   * @memberof VesselVisitDto
   */
  dischargeEstimate?: number | null
  /**
   *
   * @type {number}
   * @memberof VesselVisitDto
   */
  loadEstimate?: number | null
  /**
   *
   * @type {number}
   * @memberof VesselVisitDto
   */
  dischargeTotal: number
  /**
   *
   * @type {number}
   * @memberof VesselVisitDto
   */
  dischargeConfirmed: number
  /**
   *
   * @type {number}
   * @memberof VesselVisitDto
   */
  loadTotal: number
  /**
   *
   * @type {number}
   * @memberof VesselVisitDto
   */
  loadConfirmed: number
  /**
   *
   * @type {Array<number>}
   * @memberof VesselVisitDto
   */
  berthIds?: Array<number> | null
  /**
   *
   * @type {BerthSide}
   * @memberof VesselVisitDto
   */
  berthSide?: BerthSide
  /**
   *
   * @type {number}
   * @memberof VesselVisitDto
   */
  qmmFrom?: number | null
  /**
   *
   * @type {number}
   * @memberof VesselVisitDto
   */
  qmmTo?: number | null
  /**
   *
   * @type {Array<number>}
   * @memberof VesselVisitDto
   */
  orderIds: Array<number>
  /**
   *
   * @type {string}
   * @memberof VesselVisitDto
   */
  etb?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitDto
   */
  atb?: string | null
  /**
   *
   * @type {boolean}
   * @memberof VesselVisitDto
   */
  isConflicted?: boolean
}

/**
 *
 * @export
 * @interface VesselVisitDtoPaginatedList
 */
export interface VesselVisitDtoPaginatedList {
  /**
   *
   * @type {Array<VesselVisitDto>}
   * @memberof VesselVisitDtoPaginatedList
   */
  payload: Array<VesselVisitDto>
  /**
   *
   * @type {number}
   * @memberof VesselVisitDtoPaginatedList
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof VesselVisitDtoPaginatedList
   */
  totalPages: number
  /**
   *
   * @type {number}
   * @memberof VesselVisitDtoPaginatedList
   */
  totalCount: number
  /**
   *
   * @type {boolean}
   * @memberof VesselVisitDtoPaginatedList
   */
  hasPreviousPage: boolean
  /**
   *
   * @type {boolean}
   * @memberof VesselVisitDtoPaginatedList
   */
  hasNextPage: boolean
}
/**
 *
 * @export
 * @interface VesselVisitUpdatedDto
 */
export interface VesselVisitUpdatedDto {
  /**
   *
   * @type {number}
   * @memberof VesselVisitUpdatedDto
   */
  id: number
  /**
   *
   * @type {Array<number>}
   * @memberof VesselVisitUpdatedDto
   */
  vesselIds: Array<number>
  /**
   *
   * @type {string}
   * @memberof VesselVisitUpdatedDto
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitUpdatedDto
   */
  etb?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitUpdatedDto
   */
  etd?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitUpdatedDto
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitUpdatedDto
   */
  atb?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitUpdatedDto
   */
  atd?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitUpdatedDto
   */
  importVoyage?: string | null
  /**
   *
   * @type {string}
   * @memberof VesselVisitUpdatedDto
   */
  exportVoyage?: string | null
  /**
   *
   * @type {number}
   * @memberof VesselVisitUpdatedDto
   */
  importDraft?: number | null
  /**
   *
   * @type {number}
   * @memberof VesselVisitUpdatedDto
   */
  exportDraft?: number | null
  /**
   *
   * @type {number}
   * @memberof VesselVisitUpdatedDto
   */
  dischargeEstimate?: number | null
  /**
   *
   * @type {number}
   * @memberof VesselVisitUpdatedDto
   */
  loadEstimate?: number | null
  /**
   *
   * @type {BerthSide}
   * @memberof VesselVisitUpdatedDto
   */
  berthSide?: BerthSide
  /**
   *
   * @type {CargoType}
   * @memberof VesselVisitUpdatedDto
   */
  cargoType?: CargoType
  /**
   *
   * @type {boolean}
   * @memberof VesselVisitUpdatedDto
   */
  isConflicted?: boolean
}

/**
 *
 * @export
 * @enum {string}
 */

export const WeightMeasurementUnit = {
  Kilogram: 'Kilogram',
  Ton: 'Ton',
} as const

export type WeightMeasurementUnit =
  (typeof WeightMeasurementUnit)[keyof typeof WeightMeasurementUnit]

/**
 * BerthsApi - axios parameter creator
 * @export
 */
export const BerthsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Berths`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * BerthsApi - functional programming interface
 * @export
 */
export const BerthsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BerthsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BerthResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * BerthsApi - factory interface
 * @export
 */
export const BerthsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BerthsApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options?: any): AxiosPromise<Array<BerthResponseDto>> {
      return localVarFp.get(options).then(request => request(axios, basePath))
    },
  }
}

/**
 * BerthsApi - object-oriented interface
 * @export
 * @class BerthsApi
 * @extends {BaseAPI}
 */
export class BerthsApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BerthsApi
   */
  public get(options?: AxiosRequestConfig) {
    return BerthsApiFp(this.configuration)
      .get(options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * BreakTimesApi - axios parameter creator
 * @export
 */
export const BreakTimesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id)
      const localVarPath = `/planning/api/BreakTimes/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateBreakTimeCommand} [createBreakTimeCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createBreakTimeCommand?: CreateBreakTimeCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/BreakTimes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createBreakTimeCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateBreakTimeCommand} [updateBreakTimeCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      updateBreakTimeCommand?: UpdateBreakTimeCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/BreakTimes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateBreakTimeCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * BreakTimesApi - functional programming interface
 * @export
 */
export const BreakTimesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BreakTimesApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateBreakTimeCommand} [createBreakTimeCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createBreakTimeCommand?: CreateBreakTimeCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BreakTimeResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(
        createBreakTimeCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateBreakTimeCommand} [updateBreakTimeCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      updateBreakTimeCommand?: UpdateBreakTimeCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BreakTimeResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(
        updateBreakTimeCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * BreakTimesApi - factory interface
 * @export
 */
export const BreakTimesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BreakTimesApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp._delete(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateBreakTimeCommand} [createBreakTimeCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      createBreakTimeCommand?: CreateBreakTimeCommand,
      options?: any,
    ): AxiosPromise<BreakTimeResponseDto> {
      return localVarFp
        .create(createBreakTimeCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateBreakTimeCommand} [updateBreakTimeCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(
      updateBreakTimeCommand?: UpdateBreakTimeCommand,
      options?: any,
    ): AxiosPromise<BreakTimeResponseDto> {
      return localVarFp
        .update(updateBreakTimeCommand, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * BreakTimesApi - object-oriented interface
 * @export
 * @class BreakTimesApi
 * @extends {BaseAPI}
 */
export class BreakTimesApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BreakTimesApi
   */
  public _delete(id: number, options?: AxiosRequestConfig) {
    return BreakTimesApiFp(this.configuration)
      ._delete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateBreakTimeCommand} [createBreakTimeCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BreakTimesApi
   */
  public create(createBreakTimeCommand?: CreateBreakTimeCommand, options?: AxiosRequestConfig) {
    return BreakTimesApiFp(this.configuration)
      .create(createBreakTimeCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateBreakTimeCommand} [updateBreakTimeCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BreakTimesApi
   */
  public update(updateBreakTimeCommand?: UpdateBreakTimeCommand, options?: AxiosRequestConfig) {
    return BreakTimesApiFp(this.configuration)
      .update(updateBreakTimeCommand, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * CarrierVisitsApi - axios parameter creator
 * @export
 */
export const CarrierVisitsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id)
      const localVarPath = `/planning/api/CarrierVisits/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/All`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/Berths`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/Commodities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/ContainerDamageLocations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/ContainerDamageTypes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/ContainerTerminalPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/ContainerYardPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containers: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('containers', 'id', id)
      const localVarPath = `/planning/api/CarrierVisits/{id}/Containers`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/Customers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nonNumericOrders: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('nonNumericOrders', 'id', id)
      const localVarPath = `/planning/api/CarrierVisits/{id}/NonNumericOrders`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orders: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('orders', 'id', id)
      const localVarPath = `/planning/api/CarrierVisits/{id}/Orders`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orders_1: async (
      upsertOrderListCommandV2?: UpsertOrderListCommandV2,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/Orders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        upsertOrderListCommandV2,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/Packages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/RailTracks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    syncData: async (
      from: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      assertParamExists('syncData', 'from', from)
      const localVarPath = `/planning/api/CarrierVisits/SyncData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/Tenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertOrderList: async (
      upsertOrderListCommandV2?: UpsertOrderListCommandV2,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/UpsertOrderListAsync`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        upsertOrderListCommandV2,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/CarrierVisits/WorkInstructions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CarrierVisitsApi - functional programming interface
 * @export
 */
export const CarrierVisitsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CarrierVisitsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async all(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.all(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async berths(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.berths(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commodities(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commodities(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageLocations(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageLocations(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageTypes(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageTypes(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerTerminalPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerTerminalPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerYardPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerYardPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containers(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containers(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customers(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customers(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nonNumericOrders(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NonNumericOrderDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.nonNumericOrders(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orders(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.orders(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orders_1(
      upsertOrderListCommandV2?: UpsertOrderListCommandV2,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.orders_1(
        upsertOrderListCommandV2,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packages(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packages(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async railTracks(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.railTracks(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async syncData(
      from: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarrierVisitDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncData(from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenants(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenants(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertOrderList(
      upsertOrderListCommandV2?: UpsertOrderListCommandV2,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertOrderList(
        upsertOrderListCommandV2,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workInstructions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workInstructions(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CarrierVisitsApi - factory interface
 * @export
 */
export const CarrierVisitsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CarrierVisitsApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<boolean> {
      return localVarFp._delete(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.all(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.berths(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.commodities(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageLocations(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageTypes(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerTerminalPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerYardPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containers(id: number, options?: any): AxiosPromise<Array<ContainerResponseDto>> {
      return localVarFp.containers(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.customers(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nonNumericOrders(id: number, options?: any): AxiosPromise<Array<NonNumericOrderDto>> {
      return localVarFp.nonNumericOrders(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orders(id: number, options?: any): AxiosPromise<Array<OrderResponseDto>> {
      return localVarFp.orders(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orders_1(
      upsertOrderListCommandV2?: UpsertOrderListCommandV2,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .orders_1(upsertOrderListCommandV2, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.packages(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.railTracks(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    syncData(from: string, to?: string, options?: any): AxiosPromise<Array<CarrierVisitDto>> {
      return localVarFp.syncData(from, to, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.tenants(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertOrderList(
      upsertOrderListCommandV2?: UpsertOrderListCommandV2,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .upsertOrderList(upsertOrderListCommandV2, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.workInstructions(from, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * CarrierVisitsApi - object-oriented interface
 * @export
 * @class CarrierVisitsApi
 * @extends {BaseAPI}
 */
export class CarrierVisitsApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public _delete(id: number, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      ._delete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public all(from?: string, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .all(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public berths(from?: string, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .berths(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public commodities(from?: string, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .commodities(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public containerDamageLocations(from?: string, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .containerDamageLocations(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public containerDamageTypes(from?: string, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .containerDamageTypes(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public containerTerminalPositions(from?: string, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .containerTerminalPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public containerYardPositions(from?: string, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .containerYardPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public containers(id: number, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .containers(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public customers(from?: string, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .customers(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public nonNumericOrders(id: number, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .nonNumericOrders(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public orders(id: number, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .orders(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public orders_1(
    upsertOrderListCommandV2?: UpsertOrderListCommandV2,
    options?: AxiosRequestConfig,
  ) {
    return CarrierVisitsApiFp(this.configuration)
      .orders_1(upsertOrderListCommandV2, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public packages(from?: string, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .packages(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public railTracks(from?: string, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .railTracks(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} from
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public syncData(from: string, to?: string, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .syncData(from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public tenants(from?: string, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .tenants(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public upsertOrderList(
    upsertOrderListCommandV2?: UpsertOrderListCommandV2,
    options?: AxiosRequestConfig,
  ) {
    return CarrierVisitsApiFp(this.configuration)
      .upsertOrderList(upsertOrderListCommandV2, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public workInstructions(from?: string, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .workInstructions(from, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * CommoditiesApi - axios parameter creator
 * @export
 */
export const CommoditiesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds: async (
      ids: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ids' is not null or undefined
      assertParamExists('getByIds', 'ids', ids)
      const localVarPath = `/planning/api/Commodities/GetByIds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (ids) {
        localVarQueryParameter['Ids'] = ids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByPartialName: async (
      filter: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'filter' is not null or undefined
      assertParamExists('getByPartialName', 'filter', filter)
      const localVarPath = `/planning/api/Commodities/GetByPartialName`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CommoditiesApi - functional programming interface
 * @export
 */
export const CommoditiesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CommoditiesApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByIds(
      ids: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommodityResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByIds(ids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByPartialName(
      filter: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommodityResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByPartialName(filter, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CommoditiesApi - factory interface
 * @export
 */
export const CommoditiesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CommoditiesApiFp(configuration)
  return {
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds(ids: Array<number>, options?: any): AxiosPromise<Array<CommodityResponseDto>> {
      return localVarFp.getByIds(ids, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByPartialName(filter: string, options?: any): AxiosPromise<Array<CommodityResponseDto>> {
      return localVarFp.getByPartialName(filter, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * CommoditiesApi - object-oriented interface
 * @export
 * @class CommoditiesApi
 * @extends {BaseAPI}
 */
export class CommoditiesApi extends BaseAPI {
  /**
   *
   * @param {Array<number>} ids
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommoditiesApi
   */
  public getByIds(ids: Array<number>, options?: AxiosRequestConfig) {
    return CommoditiesApiFp(this.configuration)
      .getByIds(ids, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommoditiesApi
   */
  public getByPartialName(filter: string, options?: AxiosRequestConfig) {
    return CommoditiesApiFp(this.configuration)
      .getByPartialName(filter, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * ContainerDamageLocationsApi - axios parameter creator
 * @export
 */
export const ContainerDamageLocationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (query?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/ContainerDamageLocations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (query !== undefined) {
        localVarQueryParameter['query'] = query
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ContainerDamageLocationsApi - functional programming interface
 * @export
 */
export const ContainerDamageLocationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContainerDamageLocationsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      query?: object,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ContainerDamageLocationResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(query, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ContainerDamageLocationsApi - factory interface
 * @export
 */
export const ContainerDamageLocationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContainerDamageLocationsApiFp(configuration)
  return {
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(query?: object, options?: any): AxiosPromise<Array<ContainerDamageLocationResponseDto>> {
      return localVarFp.get(query, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * ContainerDamageLocationsApi - object-oriented interface
 * @export
 * @class ContainerDamageLocationsApi
 * @extends {BaseAPI}
 */
export class ContainerDamageLocationsApi extends BaseAPI {
  /**
   *
   * @param {object} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerDamageLocationsApi
   */
  public get(query?: object, options?: AxiosRequestConfig) {
    return ContainerDamageLocationsApiFp(this.configuration)
      .get(query, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * ContainerDamageTypesApi - axios parameter creator
 * @export
 */
export const ContainerDamageTypesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (query?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/ContainerDamageTypes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (query !== undefined) {
        localVarQueryParameter['query'] = query
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ContainerDamageTypesApi - functional programming interface
 * @export
 */
export const ContainerDamageTypesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContainerDamageTypesApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      query?: object,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ContainerDamageTypeResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(query, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ContainerDamageTypesApi - factory interface
 * @export
 */
export const ContainerDamageTypesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContainerDamageTypesApiFp(configuration)
  return {
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(query?: object, options?: any): AxiosPromise<Array<ContainerDamageTypeResponseDto>> {
      return localVarFp.get(query, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * ContainerDamageTypesApi - object-oriented interface
 * @export
 * @class ContainerDamageTypesApi
 * @extends {BaseAPI}
 */
export class ContainerDamageTypesApi extends BaseAPI {
  /**
   *
   * @param {object} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerDamageTypesApi
   */
  public get(query?: object, options?: AxiosRequestConfig) {
    return ContainerDamageTypesApiFp(this.configuration)
      .get(query, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * ContainerJourneysApi - axios parameter creator
 * @export
 */
export const ContainerJourneysApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [containerId]
     * @param {boolean} [includeCompleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      containerId?: number,
      includeCompleted?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/ContainerJourneys`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (containerId !== undefined) {
        localVarQueryParameter['ContainerId'] = containerId
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [length]
     * @param {ContainerHeight} [height]
     * @param {ContainerType} [type]
     * @param {string} [operator]
     * @param {string} [isoCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByNonNumeric: async (
      length?: number,
      height?: ContainerHeight,
      type?: ContainerType,
      operator?: string,
      isoCode?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/ContainerJourneys/getByNonNumeric`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (length !== undefined) {
        localVarQueryParameter['Length'] = length
      }

      if (height !== undefined) {
        localVarQueryParameter['Height'] = height
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type
      }

      if (operator !== undefined) {
        localVarQueryParameter['Operator'] = operator
      }

      if (isoCode !== undefined) {
        localVarQueryParameter['IsoCode'] = isoCode
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<number>} [orderIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByOrderIds: async (
      orderIds?: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/ContainerJourneys/getByOrderIds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (orderIds) {
        localVarQueryParameter['OrderIds'] = orderIds
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ContainerJourneysApi - functional programming interface
 * @export
 */
export const ContainerJourneysApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContainerJourneysApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} [containerId]
     * @param {boolean} [includeCompleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      containerId?: number,
      includeCompleted?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerJourneyResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        containerId,
        includeCompleted,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [length]
     * @param {ContainerHeight} [height]
     * @param {ContainerType} [type]
     * @param {string} [operator]
     * @param {string} [isoCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByNonNumeric(
      length?: number,
      height?: ContainerHeight,
      type?: ContainerType,
      operator?: string,
      isoCode?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerJourneyResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByNonNumeric(
        length,
        height,
        type,
        operator,
        isoCode,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {Array<number>} [orderIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByOrderIds(
      orderIds?: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerJourneyResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByOrderIds(orderIds, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ContainerJourneysApi - factory interface
 * @export
 */
export const ContainerJourneysApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContainerJourneysApiFp(configuration)
  return {
    /**
     *
     * @param {number} [containerId]
     * @param {boolean} [includeCompleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      containerId?: number,
      includeCompleted?: boolean,
      options?: any,
    ): AxiosPromise<Array<ContainerJourneyResponseDto>> {
      return localVarFp
        .get(containerId, includeCompleted, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [length]
     * @param {ContainerHeight} [height]
     * @param {ContainerType} [type]
     * @param {string} [operator]
     * @param {string} [isoCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByNonNumeric(
      length?: number,
      height?: ContainerHeight,
      type?: ContainerType,
      operator?: string,
      isoCode?: string,
      options?: any,
    ): AxiosPromise<Array<ContainerJourneyResponseDto>> {
      return localVarFp
        .getByNonNumeric(length, height, type, operator, isoCode, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {Array<number>} [orderIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByOrderIds(
      orderIds?: Array<number>,
      options?: any,
    ): AxiosPromise<Array<ContainerJourneyResponseDto>> {
      return localVarFp.getByOrderIds(orderIds, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * ContainerJourneysApi - object-oriented interface
 * @export
 * @class ContainerJourneysApi
 * @extends {BaseAPI}
 */
export class ContainerJourneysApi extends BaseAPI {
  /**
   *
   * @param {number} [containerId]
   * @param {boolean} [includeCompleted]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerJourneysApi
   */
  public get(containerId?: number, includeCompleted?: boolean, options?: AxiosRequestConfig) {
    return ContainerJourneysApiFp(this.configuration)
      .get(containerId, includeCompleted, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [length]
   * @param {ContainerHeight} [height]
   * @param {ContainerType} [type]
   * @param {string} [operator]
   * @param {string} [isoCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerJourneysApi
   */
  public getByNonNumeric(
    length?: number,
    height?: ContainerHeight,
    type?: ContainerType,
    operator?: string,
    isoCode?: string,
    options?: AxiosRequestConfig,
  ) {
    return ContainerJourneysApiFp(this.configuration)
      .getByNonNumeric(length, height, type, operator, isoCode, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Array<number>} [orderIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainerJourneysApi
   */
  public getByOrderIds(orderIds?: Array<number>, options?: AxiosRequestConfig) {
    return ContainerJourneysApiFp(this.configuration)
      .getByOrderIds(orderIds, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * ContainersApi - axios parameter creator
 * @export
 */
export const ContainersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {GetLinkableOrdersQueryQuery} [getLinkableOrdersQueryQuery]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    activeOrders: async (
      getLinkableOrdersQueryQuery?: GetLinkableOrdersQueryQuery,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Containers/ActiveOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getLinkableOrdersQueryQuery,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [containerNumber]
     * @param {Array<File>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageImages: async (
      containerNumber?: string,
      files?: Array<File>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Containers/ContainerDamageImages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)()

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (containerNumber !== undefined) {
        localVarFormParams.append('ContainerNumber', containerNumber as any)
      }
      if (files) {
        files.forEach(element => {
          localVarFormParams.append('Files', element as any)
        })
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {CreateContainerVisitCommand} [createContainerVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createContainerVisit: async (
      id: number,
      createContainerVisitCommand?: CreateContainerVisitCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('createContainerVisit', 'id', id)
      const localVarPath = `/planning/api/Containers/{id}/CreateContainerVisit`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createContainerVisitCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {CreateContainerDamageCommand} [createContainerDamageCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    damage: async (
      id: number,
      createContainerDamageCommand?: CreateContainerDamageCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('damage', 'id', id)
      const localVarPath = `/planning/api/Containers/{id}/Damage`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createContainerDamageCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    events: async (number: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'number' is not null or undefined
      assertParamExists('events', 'number', number)
      const localVarPath = `/planning/api/Containers/{number}/Events`.replace(
        `{${'number'}}`,
        encodeURIComponent(String(number)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {SyncStatus} [syncStatus]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      page: number,
      pageSize: number,
      syncStatus?: SyncStatus,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('get', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('get', 'pageSize', pageSize)
      const localVarPath = `/planning/api/Containers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (syncStatus !== undefined) {
        localVarQueryParameter['SyncStatus'] = syncStatus
      }

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getById', 'id', id)
      const localVarPath = `/planning/api/Containers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} filter
     * @param {boolean} [isEmpty]
     * @param {boolean} [hasServiceOrders]
     * @param {boolean} [canAssignServiceOrders]
     * @param {ContainerType} [type]
     * @param {boolean} [canAssignStrippingOrder]
     * @param {boolean} [canAssignStuffingOrder]
     * @param {boolean} [canAssignCoolingOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContainersByPartialNumber: async (
      filter: string,
      isEmpty?: boolean,
      hasServiceOrders?: boolean,
      canAssignServiceOrders?: boolean,
      type?: ContainerType,
      canAssignStrippingOrder?: boolean,
      canAssignStuffingOrder?: boolean,
      canAssignCoolingOrder?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'filter' is not null or undefined
      assertParamExists('getContainersByPartialNumber', 'filter', filter)
      const localVarPath = `/planning/api/Containers/GetContainersByPartialNumber`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (isEmpty !== undefined) {
        localVarQueryParameter['IsEmpty'] = isEmpty
      }

      if (hasServiceOrders !== undefined) {
        localVarQueryParameter['HasServiceOrders'] = hasServiceOrders
      }

      if (canAssignServiceOrders !== undefined) {
        localVarQueryParameter['CanAssignServiceOrders'] = canAssignServiceOrders
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type
      }

      if (canAssignStrippingOrder !== undefined) {
        localVarQueryParameter['CanAssignStrippingOrder'] = canAssignStrippingOrder
      }

      if (canAssignStuffingOrder !== undefined) {
        localVarQueryParameter['CanAssignStuffingOrder'] = canAssignStuffingOrder
      }

      if (canAssignCoolingOrder !== undefined) {
        localVarQueryParameter['CanAssignCoolingOrder'] = canAssignCoolingOrder
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetLinkableOrdersQueryQuery} [getLinkableOrdersQueryQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkableOrders: async (
      getLinkableOrdersQueryQuery?: GetLinkableOrdersQueryQuery,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Containers/LinkableOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getLinkableOrdersQueryQuery,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpsertContainerCommand} [upsertContainerCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (
      upsertContainerCommand?: UpsertContainerCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Containers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        upsertContainerCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {ResolveContainerDamagesCommand} [resolveContainerDamagesCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveDamages: async (
      id: number,
      resolveContainerDamagesCommand?: ResolveContainerDamagesCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('resolveDamages', 'id', id)
      const localVarPath = `/planning/api/Containers/{id}/ResolveDamages`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        resolveContainerDamagesCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    sync: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Containers/sync`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {UnresolveContainerDamagesCommand} [unresolveContainerDamagesCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unresolveDamages: async (
      id: number,
      unresolveContainerDamagesCommand?: UnresolveContainerDamagesCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('unresolveDamages', 'id', id)
      const localVarPath = `/planning/api/Containers/{id}/UnresolveDamages`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        unresolveContainerDamagesCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateContainerVisitCommand} [updateContainerVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContainerVisit: async (
      updateContainerVisitCommand?: UpdateContainerVisitCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Containers/UpdateContainerVisit`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateContainerVisitCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} containerDamageId
     * @param {UpdateContainerDamageCommand} [updateContainerDamageCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDamage: async (
      containerDamageId: number,
      updateContainerDamageCommand?: UpdateContainerDamageCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'containerDamageId' is not null or undefined
      assertParamExists('updateDamage', 'containerDamageId', containerDamageId)
      const localVarPath = `/planning/api/Containers/UpdateDamage/{containerDamageId}`.replace(
        `{${'containerDamageId'}}`,
        encodeURIComponent(String(containerDamageId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateContainerDamageCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ContainersApi - functional programming interface
 * @export
 */
export const ContainersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContainersApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {GetLinkableOrdersQueryQuery} [getLinkableOrdersQueryQuery]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async activeOrders(
      getLinkableOrdersQueryQuery?: GetLinkableOrdersQueryQuery,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.activeOrders(
        getLinkableOrdersQueryQuery,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [containerNumber]
     * @param {Array<File>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageImages(
      containerNumber?: string,
      files?: Array<File>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UploadContainerDamageImagesResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageImages(
        containerNumber,
        files,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {CreateContainerVisitCommand} [createContainerVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createContainerVisit(
      id: number,
      createContainerVisitCommand?: CreateContainerVisitCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createContainerVisit(
        id,
        createContainerVisitCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {CreateContainerDamageCommand} [createContainerDamageCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async damage(
      id: number,
      createContainerDamageCommand?: CreateContainerDamageCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.damage(
        id,
        createContainerDamageCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async events(
      number: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerEventDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.events(number, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {SyncStatus} [syncStatus]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      page: number,
      pageSize: number,
      syncStatus?: SyncStatus,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContainerResponseDtoPaginatedList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        page,
        pageSize,
        syncStatus,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getById(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContainerResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} filter
     * @param {boolean} [isEmpty]
     * @param {boolean} [hasServiceOrders]
     * @param {boolean} [canAssignServiceOrders]
     * @param {ContainerType} [type]
     * @param {boolean} [canAssignStrippingOrder]
     * @param {boolean} [canAssignStuffingOrder]
     * @param {boolean} [canAssignCoolingOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContainersByPartialNumber(
      filter: string,
      isEmpty?: boolean,
      hasServiceOrders?: boolean,
      canAssignServiceOrders?: boolean,
      type?: ContainerType,
      canAssignStrippingOrder?: boolean,
      canAssignStuffingOrder?: boolean,
      canAssignCoolingOrder?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContainersByPartialNumber(
        filter,
        isEmpty,
        hasServiceOrders,
        canAssignServiceOrders,
        type,
        canAssignStrippingOrder,
        canAssignStuffingOrder,
        canAssignCoolingOrder,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetLinkableOrdersQueryQuery} [getLinkableOrdersQueryQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linkableOrders(
      getLinkableOrdersQueryQuery?: GetLinkableOrdersQueryQuery,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.linkableOrders(
        getLinkableOrdersQueryQuery,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpsertContainerCommand} [upsertContainerCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(
      upsertContainerCommand?: UpsertContainerCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(
        upsertContainerCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {ResolveContainerDamagesCommand} [resolveContainerDamagesCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resolveDamages(
      id: number,
      resolveContainerDamagesCommand?: ResolveContainerDamagesCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resolveDamages(
        id,
        resolveContainerDamagesCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async sync(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncContainersDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sync(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {UnresolveContainerDamagesCommand} [unresolveContainerDamagesCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unresolveDamages(
      id: number,
      unresolveContainerDamagesCommand?: UnresolveContainerDamagesCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unresolveDamages(
        id,
        unresolveContainerDamagesCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateContainerVisitCommand} [updateContainerVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateContainerVisit(
      updateContainerVisitCommand?: UpdateContainerVisitCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateContainerVisit(
        updateContainerVisitCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} containerDamageId
     * @param {UpdateContainerDamageCommand} [updateContainerDamageCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDamage(
      containerDamageId: number,
      updateContainerDamageCommand?: UpdateContainerDamageCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDamage(
        containerDamageId,
        updateContainerDamageCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ContainersApi - factory interface
 * @export
 */
export const ContainersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContainersApiFp(configuration)
  return {
    /**
     *
     * @param {GetLinkableOrdersQueryQuery} [getLinkableOrdersQueryQuery]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    activeOrders(
      getLinkableOrdersQueryQuery?: GetLinkableOrdersQueryQuery,
      options?: any,
    ): AxiosPromise<Array<OrderResponseDto>> {
      return localVarFp
        .activeOrders(getLinkableOrdersQueryQuery, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [containerNumber]
     * @param {Array<File>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageImages(
      containerNumber?: string,
      files?: Array<File>,
      options?: any,
    ): AxiosPromise<Array<UploadContainerDamageImagesResponseDto>> {
      return localVarFp
        .containerDamageImages(containerNumber, files, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {CreateContainerVisitCommand} [createContainerVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createContainerVisit(
      id: number,
      createContainerVisitCommand?: CreateContainerVisitCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createContainerVisit(id, createContainerVisitCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {CreateContainerDamageCommand} [createContainerDamageCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    damage(
      id: number,
      createContainerDamageCommand?: CreateContainerDamageCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .damage(id, createContainerDamageCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    events(number: string, options?: any): AxiosPromise<Array<ContainerEventDto>> {
      return localVarFp.events(number, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {SyncStatus} [syncStatus]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      page: number,
      pageSize: number,
      syncStatus?: SyncStatus,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<ContainerResponseDtoPaginatedList> {
      return localVarFp
        .get(
          page,
          pageSize,
          syncStatus,
          filter,
          includeCompleted,
          orderBy,
          isDescending,
          from,
          to,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(id: number, options?: any): AxiosPromise<ContainerResponseDto> {
      return localVarFp.getById(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} filter
     * @param {boolean} [isEmpty]
     * @param {boolean} [hasServiceOrders]
     * @param {boolean} [canAssignServiceOrders]
     * @param {ContainerType} [type]
     * @param {boolean} [canAssignStrippingOrder]
     * @param {boolean} [canAssignStuffingOrder]
     * @param {boolean} [canAssignCoolingOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContainersByPartialNumber(
      filter: string,
      isEmpty?: boolean,
      hasServiceOrders?: boolean,
      canAssignServiceOrders?: boolean,
      type?: ContainerType,
      canAssignStrippingOrder?: boolean,
      canAssignStuffingOrder?: boolean,
      canAssignCoolingOrder?: boolean,
      options?: any,
    ): AxiosPromise<Array<ContainerDto>> {
      return localVarFp
        .getContainersByPartialNumber(
          filter,
          isEmpty,
          hasServiceOrders,
          canAssignServiceOrders,
          type,
          canAssignStrippingOrder,
          canAssignStuffingOrder,
          canAssignCoolingOrder,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {GetLinkableOrdersQueryQuery} [getLinkableOrdersQueryQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linkableOrders(
      getLinkableOrdersQueryQuery?: GetLinkableOrdersQueryQuery,
      options?: any,
    ): AxiosPromise<Array<OrderResponseDto>> {
      return localVarFp
        .linkableOrders(getLinkableOrdersQueryQuery, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpsertContainerCommand} [upsertContainerCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(upsertContainerCommand?: UpsertContainerCommand, options?: any): AxiosPromise<number> {
      return localVarFp
        .post(upsertContainerCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {ResolveContainerDamagesCommand} [resolveContainerDamagesCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveDamages(
      id: number,
      resolveContainerDamagesCommand?: ResolveContainerDamagesCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .resolveDamages(id, resolveContainerDamagesCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    sync(from?: string, options?: any): AxiosPromise<SyncContainersDto> {
      return localVarFp.sync(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {UnresolveContainerDamagesCommand} [unresolveContainerDamagesCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unresolveDamages(
      id: number,
      unresolveContainerDamagesCommand?: UnresolveContainerDamagesCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .unresolveDamages(id, unresolveContainerDamagesCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateContainerVisitCommand} [updateContainerVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContainerVisit(
      updateContainerVisitCommand?: UpdateContainerVisitCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateContainerVisit(updateContainerVisitCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} containerDamageId
     * @param {UpdateContainerDamageCommand} [updateContainerDamageCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDamage(
      containerDamageId: number,
      updateContainerDamageCommand?: UpdateContainerDamageCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateDamage(containerDamageId, updateContainerDamageCommand, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * ContainersApi - object-oriented interface
 * @export
 * @class ContainersApi
 * @extends {BaseAPI}
 */
export class ContainersApi extends BaseAPI {
  /**
   *
   * @param {GetLinkableOrdersQueryQuery} [getLinkableOrdersQueryQuery]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public activeOrders(
    getLinkableOrdersQueryQuery?: GetLinkableOrdersQueryQuery,
    options?: AxiosRequestConfig,
  ) {
    return ContainersApiFp(this.configuration)
      .activeOrders(getLinkableOrdersQueryQuery, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [containerNumber]
   * @param {Array<File>} [files]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public containerDamageImages(
    containerNumber?: string,
    files?: Array<File>,
    options?: AxiosRequestConfig,
  ) {
    return ContainersApiFp(this.configuration)
      .containerDamageImages(containerNumber, files, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {CreateContainerVisitCommand} [createContainerVisitCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public createContainerVisit(
    id: number,
    createContainerVisitCommand?: CreateContainerVisitCommand,
    options?: AxiosRequestConfig,
  ) {
    return ContainersApiFp(this.configuration)
      .createContainerVisit(id, createContainerVisitCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {CreateContainerDamageCommand} [createContainerDamageCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public damage(
    id: number,
    createContainerDamageCommand?: CreateContainerDamageCommand,
    options?: AxiosRequestConfig,
  ) {
    return ContainersApiFp(this.configuration)
      .damage(id, createContainerDamageCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} number
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public events(number: string, options?: AxiosRequestConfig) {
    return ContainersApiFp(this.configuration)
      .events(number, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} page
   * @param {number} pageSize
   * @param {SyncStatus} [syncStatus]
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public get(
    page: number,
    pageSize: number,
    syncStatus?: SyncStatus,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return ContainersApiFp(this.configuration)
      .get(
        page,
        pageSize,
        syncStatus,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public getById(id: number, options?: AxiosRequestConfig) {
    return ContainersApiFp(this.configuration)
      .getById(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} filter
   * @param {boolean} [isEmpty]
   * @param {boolean} [hasServiceOrders]
   * @param {boolean} [canAssignServiceOrders]
   * @param {ContainerType} [type]
   * @param {boolean} [canAssignStrippingOrder]
   * @param {boolean} [canAssignStuffingOrder]
   * @param {boolean} [canAssignCoolingOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public getContainersByPartialNumber(
    filter: string,
    isEmpty?: boolean,
    hasServiceOrders?: boolean,
    canAssignServiceOrders?: boolean,
    type?: ContainerType,
    canAssignStrippingOrder?: boolean,
    canAssignStuffingOrder?: boolean,
    canAssignCoolingOrder?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return ContainersApiFp(this.configuration)
      .getContainersByPartialNumber(
        filter,
        isEmpty,
        hasServiceOrders,
        canAssignServiceOrders,
        type,
        canAssignStrippingOrder,
        canAssignStuffingOrder,
        canAssignCoolingOrder,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetLinkableOrdersQueryQuery} [getLinkableOrdersQueryQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public linkableOrders(
    getLinkableOrdersQueryQuery?: GetLinkableOrdersQueryQuery,
    options?: AxiosRequestConfig,
  ) {
    return ContainersApiFp(this.configuration)
      .linkableOrders(getLinkableOrdersQueryQuery, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpsertContainerCommand} [upsertContainerCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public post(upsertContainerCommand?: UpsertContainerCommand, options?: AxiosRequestConfig) {
    return ContainersApiFp(this.configuration)
      .post(upsertContainerCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {ResolveContainerDamagesCommand} [resolveContainerDamagesCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public resolveDamages(
    id: number,
    resolveContainerDamagesCommand?: ResolveContainerDamagesCommand,
    options?: AxiosRequestConfig,
  ) {
    return ContainersApiFp(this.configuration)
      .resolveDamages(id, resolveContainerDamagesCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public sync(from?: string, options?: AxiosRequestConfig) {
    return ContainersApiFp(this.configuration)
      .sync(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {UnresolveContainerDamagesCommand} [unresolveContainerDamagesCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public unresolveDamages(
    id: number,
    unresolveContainerDamagesCommand?: UnresolveContainerDamagesCommand,
    options?: AxiosRequestConfig,
  ) {
    return ContainersApiFp(this.configuration)
      .unresolveDamages(id, unresolveContainerDamagesCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateContainerVisitCommand} [updateContainerVisitCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public updateContainerVisit(
    updateContainerVisitCommand?: UpdateContainerVisitCommand,
    options?: AxiosRequestConfig,
  ) {
    return ContainersApiFp(this.configuration)
      .updateContainerVisit(updateContainerVisitCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} containerDamageId
   * @param {UpdateContainerDamageCommand} [updateContainerDamageCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContainersApi
   */
  public updateDamage(
    containerDamageId: number,
    updateContainerDamageCommand?: UpdateContainerDamageCommand,
    options?: AxiosRequestConfig,
  ) {
    return ContainersApiFp(this.configuration)
      .updateDamage(containerDamageId, updateContainerDamageCommand, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getById', 'id', id)
      const localVarPath = `/planning/api/Customers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByPartialName: async (
      filter: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'filter' is not null or undefined
      assertParamExists('getByPartialName', 'filter', filter)
      const localVarPath = `/planning/api/Customers/GetByPartialName`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getById(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByPartialName(
      filter: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByPartialName(filter, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CustomersApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(id: number, options?: any): AxiosPromise<CustomerResponseDto> {
      return localVarFp.getById(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByPartialName(filter: string, options?: any): AxiosPromise<Array<CustomerResponseDto>> {
      return localVarFp.getByPartialName(filter, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public getById(id: number, options?: AxiosRequestConfig) {
    return CustomersApiFp(this.configuration)
      .getById(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public getByPartialName(filter: string, options?: AxiosRequestConfig) {
    return CustomersApiFp(this.configuration)
      .getByPartialName(filter, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * DangerousGoodsDefinitionsApi - axios parameter creator
 * @export
 */
export const DangerousGoodsDefinitionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id)
      const localVarPath = `/planning/api/DangerousGoodsDefinitions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateDangerousGoodsDefinitionCommand} [createDangerousGoodsDefinitionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createDangerousGoodsDefinitionCommand?: CreateDangerousGoodsDefinitionCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/DangerousGoodsDefinitions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDangerousGoodsDefinitionCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('get', 'id', id)
      const localVarPath = `/planning/api/DangerousGoodsDefinitions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll: async (
      page: number,
      pageSize: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getAll', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getAll', 'pageSize', pageSize)
      const localVarPath = `/planning/api/DangerousGoodsDefinitions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateDangerousGoodsDefinitionCommand} [updateDangerousGoodsDefinitionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      updateDangerousGoodsDefinitionCommand?: UpdateDangerousGoodsDefinitionCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/DangerousGoodsDefinitions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDangerousGoodsDefinitionCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DangerousGoodsDefinitionsApi - functional programming interface
 * @export
 */
export const DangerousGoodsDefinitionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DangerousGoodsDefinitionsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateDangerousGoodsDefinitionCommand} [createDangerousGoodsDefinitionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createDangerousGoodsDefinitionCommand?: CreateDangerousGoodsDefinitionCommand,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DangerousGoodsDefinitionDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(
        createDangerousGoodsDefinitionCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DangerousGoodsDefinitionDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAll(
      page: number,
      pageSize: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DangerousGoodsDefinitionDtoPaginatedList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(
        page,
        pageSize,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateDangerousGoodsDefinitionCommand} [updateDangerousGoodsDefinitionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      updateDangerousGoodsDefinitionCommand?: UpdateDangerousGoodsDefinitionCommand,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DangerousGoodsDefinitionDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(
        updateDangerousGoodsDefinitionCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * DangerousGoodsDefinitionsApi - factory interface
 * @export
 */
export const DangerousGoodsDefinitionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DangerousGoodsDefinitionsApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp._delete(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateDangerousGoodsDefinitionCommand} [createDangerousGoodsDefinitionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      createDangerousGoodsDefinitionCommand?: CreateDangerousGoodsDefinitionCommand,
      options?: any,
    ): AxiosPromise<DangerousGoodsDefinitionDto> {
      return localVarFp
        .create(createDangerousGoodsDefinitionCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(id: number, options?: any): AxiosPromise<DangerousGoodsDefinitionDto> {
      return localVarFp.get(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll(
      page: number,
      pageSize: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<DangerousGoodsDefinitionDtoPaginatedList> {
      return localVarFp
        .getAll(page, pageSize, filter, includeCompleted, orderBy, isDescending, from, to, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateDangerousGoodsDefinitionCommand} [updateDangerousGoodsDefinitionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(
      updateDangerousGoodsDefinitionCommand?: UpdateDangerousGoodsDefinitionCommand,
      options?: any,
    ): AxiosPromise<DangerousGoodsDefinitionDto> {
      return localVarFp
        .update(updateDangerousGoodsDefinitionCommand, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * DangerousGoodsDefinitionsApi - object-oriented interface
 * @export
 * @class DangerousGoodsDefinitionsApi
 * @extends {BaseAPI}
 */
export class DangerousGoodsDefinitionsApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DangerousGoodsDefinitionsApi
   */
  public _delete(id: number, options?: AxiosRequestConfig) {
    return DangerousGoodsDefinitionsApiFp(this.configuration)
      ._delete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateDangerousGoodsDefinitionCommand} [createDangerousGoodsDefinitionCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DangerousGoodsDefinitionsApi
   */
  public create(
    createDangerousGoodsDefinitionCommand?: CreateDangerousGoodsDefinitionCommand,
    options?: AxiosRequestConfig,
  ) {
    return DangerousGoodsDefinitionsApiFp(this.configuration)
      .create(createDangerousGoodsDefinitionCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DangerousGoodsDefinitionsApi
   */
  public get(id: number, options?: AxiosRequestConfig) {
    return DangerousGoodsDefinitionsApiFp(this.configuration)
      .get(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} page
   * @param {number} pageSize
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DangerousGoodsDefinitionsApi
   */
  public getAll(
    page: number,
    pageSize: number,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return DangerousGoodsDefinitionsApiFp(this.configuration)
      .getAll(page, pageSize, filter, includeCompleted, orderBy, isDescending, from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateDangerousGoodsDefinitionCommand} [updateDangerousGoodsDefinitionCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DangerousGoodsDefinitionsApi
   */
  public update(
    updateDangerousGoodsDefinitionCommand?: UpdateDangerousGoodsDefinitionCommand,
    options?: AxiosRequestConfig,
  ) {
    return DangerousGoodsDefinitionsApiFp(this.configuration)
      .update(updateDangerousGoodsDefinitionCommand, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * GcsApi - axios parameter creator
 * @export
 */
export const GcsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (path?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Gcs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (path !== undefined) {
        localVarQueryParameter['path'] = path
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GcsApi - functional programming interface
 * @export
 */
export const GcsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GcsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      path?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(path, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * GcsApi - factory interface
 * @export
 */
export const GcsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GcsApiFp(configuration)
  return {
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(path?: string, options?: any): AxiosPromise<void> {
      return localVarFp.get(path, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * GcsApi - object-oriented interface
 * @export
 * @class GcsApi
 * @extends {BaseAPI}
 */
export class GcsApi extends BaseAPI {
  /**
   *
   * @param {string} [path]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GcsApi
   */
  public get(path?: string, options?: AxiosRequestConfig) {
    return GcsApiFp(this.configuration)
      .get(path, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * GeneralCargoApi - axios parameter creator
 * @export
 */
export const GeneralCargoApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [filter]
     * @param {CarrierVisitDirection} [direction]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByCustomerOrReferenceNumber: async (
      filter?: string,
      direction?: CarrierVisitDirection,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/GeneralCargo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (direction !== undefined) {
        localVarQueryParameter['Direction'] = direction
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateGeneralCargoOrderCommand} [createGeneralCargoOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (
      createGeneralCargoOrderCommand?: CreateGeneralCargoOrderCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/GeneralCargo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createGeneralCargoOrderCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {UpdateGeneralCargoOrderCommand} [updateGeneralCargoOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put: async (
      id: number,
      updateGeneralCargoOrderCommand?: UpdateGeneralCargoOrderCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('put', 'id', id)
      const localVarPath = `/planning/api/GeneralCargo/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateGeneralCargoOrderCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GeneralCargoApi - functional programming interface
 * @export
 */
export const GeneralCargoApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GeneralCargoApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} [filter]
     * @param {CarrierVisitDirection} [direction]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByCustomerOrReferenceNumber(
      filter?: string,
      direction?: CarrierVisitDirection,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByCustomerOrReferenceNumber(
        filter,
        direction,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateGeneralCargoOrderCommand} [createGeneralCargoOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(
      createGeneralCargoOrderCommand?: CreateGeneralCargoOrderCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(
        createGeneralCargoOrderCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {UpdateGeneralCargoOrderCommand} [updateGeneralCargoOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async put(
      id: number,
      updateGeneralCargoOrderCommand?: UpdateGeneralCargoOrderCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.put(
        id,
        updateGeneralCargoOrderCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * GeneralCargoApi - factory interface
 * @export
 */
export const GeneralCargoApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GeneralCargoApiFp(configuration)
  return {
    /**
     *
     * @param {string} [filter]
     * @param {CarrierVisitDirection} [direction]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByCustomerOrReferenceNumber(
      filter?: string,
      direction?: CarrierVisitDirection,
      options?: any,
    ): AxiosPromise<Array<OrderResponseDto>> {
      return localVarFp
        .getByCustomerOrReferenceNumber(filter, direction, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateGeneralCargoOrderCommand} [createGeneralCargoOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(
      createGeneralCargoOrderCommand?: CreateGeneralCargoOrderCommand,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .post(createGeneralCargoOrderCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {UpdateGeneralCargoOrderCommand} [updateGeneralCargoOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put(
      id: number,
      updateGeneralCargoOrderCommand?: UpdateGeneralCargoOrderCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .put(id, updateGeneralCargoOrderCommand, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * GeneralCargoApi - object-oriented interface
 * @export
 * @class GeneralCargoApi
 * @extends {BaseAPI}
 */
export class GeneralCargoApi extends BaseAPI {
  /**
   *
   * @param {string} [filter]
   * @param {CarrierVisitDirection} [direction]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralCargoApi
   */
  public getByCustomerOrReferenceNumber(
    filter?: string,
    direction?: CarrierVisitDirection,
    options?: AxiosRequestConfig,
  ) {
    return GeneralCargoApiFp(this.configuration)
      .getByCustomerOrReferenceNumber(filter, direction, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateGeneralCargoOrderCommand} [createGeneralCargoOrderCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralCargoApi
   */
  public post(
    createGeneralCargoOrderCommand?: CreateGeneralCargoOrderCommand,
    options?: AxiosRequestConfig,
  ) {
    return GeneralCargoApiFp(this.configuration)
      .post(createGeneralCargoOrderCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {UpdateGeneralCargoOrderCommand} [updateGeneralCargoOrderCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralCargoApi
   */
  public put(
    id: number,
    updateGeneralCargoOrderCommand?: UpdateGeneralCargoOrderCommand,
    options?: AxiosRequestConfig,
  ) {
    return GeneralCargoApiFp(this.configuration)
      .put(id, updateGeneralCargoOrderCommand, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * HoldsApi - axios parameter creator
 * @export
 */
export const HoldsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/All`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ApproveHoldsCommand} [approveHoldsCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveHolds: async (
      approveHoldsCommand?: ApproveHoldsCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/ApproveHolds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        approveHoldsCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/Berths`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/Commodities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/ContainerDamageLocations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/ContainerDamageTypes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/ContainerTerminalPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/ContainerYardPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/Customers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByCarrierVisitId: async (
      carrierVisitId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/GetByCarrierVisitId`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (carrierVisitId !== undefined) {
        localVarQueryParameter['CarrierVisitId'] = carrierVisitId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/Packages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateHoldCommand} [createHoldCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (
      createHoldCommand?: CreateHoldCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createHoldCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/RailTracks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    syncData: async (
      from: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      assertParamExists('syncData', 'from', from)
      const localVarPath = `/planning/api/Holds/SyncData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/Tenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Holds/WorkInstructions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * HoldsApi - functional programming interface
 * @export
 */
export const HoldsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HoldsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async all(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.all(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {ApproveHoldsCommand} [approveHoldsCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveHolds(
      approveHoldsCommand?: ApproveHoldsCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveHolds(
        approveHoldsCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async berths(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.berths(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commodities(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commodities(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageLocations(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageLocations(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageTypes(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageTypes(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerTerminalPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerTerminalPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerYardPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerYardPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customers(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customers(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByCarrierVisitId(
      carrierVisitId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HoldResponseDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByCarrierVisitId(
        carrierVisitId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packages(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packages(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateHoldCommand} [createHoldCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(
      createHoldCommand?: CreateHoldCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(createHoldCommand, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async railTracks(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.railTracks(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async syncData(
      from: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderSyncDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncData(from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenants(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenants(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workInstructions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workInstructions(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * HoldsApi - factory interface
 * @export
 */
export const HoldsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HoldsApiFp(configuration)
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.all(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {ApproveHoldsCommand} [approveHoldsCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveHolds(approveHoldsCommand?: ApproveHoldsCommand, options?: any): AxiosPromise<void> {
      return localVarFp
        .approveHolds(approveHoldsCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.berths(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.commodities(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageLocations(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageTypes(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerTerminalPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerYardPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.customers(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [carrierVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByCarrierVisitId(
      carrierVisitId?: number,
      options?: any,
    ): AxiosPromise<Array<HoldResponseDto>> {
      return localVarFp
        .getByCarrierVisitId(carrierVisitId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.packages(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateHoldCommand} [createHoldCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(createHoldCommand?: CreateHoldCommand, options?: any): AxiosPromise<number> {
      return localVarFp.post(createHoldCommand, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.railTracks(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    syncData(from: string, to?: string, options?: any): AxiosPromise<OrderSyncDto> {
      return localVarFp.syncData(from, to, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.tenants(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.workInstructions(from, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * HoldsApi - object-oriented interface
 * @export
 * @class HoldsApi
 * @extends {BaseAPI}
 */
export class HoldsApi extends BaseAPI {
  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public all(from?: string, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .all(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ApproveHoldsCommand} [approveHoldsCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public approveHolds(approveHoldsCommand?: ApproveHoldsCommand, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .approveHolds(approveHoldsCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public berths(from?: string, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .berths(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public commodities(from?: string, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .commodities(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public containerDamageLocations(from?: string, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .containerDamageLocations(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public containerDamageTypes(from?: string, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .containerDamageTypes(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public containerTerminalPositions(from?: string, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .containerTerminalPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public containerYardPositions(from?: string, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .containerYardPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public customers(from?: string, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .customers(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [carrierVisitId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public getByCarrierVisitId(carrierVisitId?: number, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .getByCarrierVisitId(carrierVisitId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public packages(from?: string, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .packages(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateHoldCommand} [createHoldCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public post(createHoldCommand?: CreateHoldCommand, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .post(createHoldCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public railTracks(from?: string, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .railTracks(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} from
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public syncData(from: string, to?: string, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .syncData(from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public tenants(from?: string, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .tenants(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldsApi
   */
  public workInstructions(from?: string, options?: AxiosRequestConfig) {
    return HoldsApiFp(this.configuration)
      .workInstructions(from, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * IssuesApi - axios parameter creator
 * @export
 */
export const IssuesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Issues`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * IssuesApi - functional programming interface
 * @export
 */
export const IssuesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = IssuesApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAll(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IssueResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * IssuesApi - factory interface
 * @export
 */
export const IssuesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = IssuesApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll(options?: any): AxiosPromise<Array<IssueResponseDto>> {
      return localVarFp.getAll(options).then(request => request(axios, basePath))
    },
  }
}

/**
 * IssuesApi - object-oriented interface
 * @export
 * @class IssuesApi
 * @extends {BaseAPI}
 */
export class IssuesApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IssuesApi
   */
  public getAll(options?: AxiosRequestConfig) {
    return IssuesApiFp(this.configuration)
      .getAll(options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * NonNumericOrdersApi - axios parameter creator
 * @export
 */
export const NonNumericOrdersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {AssignContainerCommand} [assignContainerCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignContainer: async (
      id: number,
      assignContainerCommand?: AssignContainerCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('assignContainer', 'id', id)
      const localVarPath = `/planning/api/NonNumericOrders/{id}/assignContainer`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        assignContainerCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {CloseNonNumericOrderCommand} [closeNonNumericOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeOrder: async (
      id: number,
      closeNonNumericOrderCommand?: CloseNonNumericOrderCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('closeOrder', 'id', id)
      const localVarPath = `/planning/api/NonNumericOrders/{id}/close-order`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        closeNonNumericOrderCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierType} [modeOfTransport]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      page: number,
      pageSize: number,
      modeOfTransport?: CarrierType,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('get', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('get', 'pageSize', pageSize)
      const localVarPath = `/planning/api/NonNumericOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (modeOfTransport !== undefined) {
        localVarQueryParameter['ModeOfTransport'] = modeOfTransport
      }

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getById', 'id', id)
      const localVarPath = `/planning/api/NonNumericOrders/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [referenceNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByReferenceNumber: async (
      referenceNumber?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/NonNumericOrders/referenceNumber`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (referenceNumber !== undefined) {
        localVarQueryParameter['ReferenceNumber'] = referenceNumber
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} visitId
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [carrierVisitId]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getOrdersByCarrierVisitId: async (
      visitId: number,
      page: number,
      pageSize: number,
      carrierVisitId?: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'visitId' is not null or undefined
      assertParamExists('getOrdersByCarrierVisitId', 'visitId', visitId)
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getOrdersByCarrierVisitId', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getOrdersByCarrierVisitId', 'pageSize', pageSize)
      const localVarPath = `/planning/api/NonNumericOrders/carrierVisit/{visitId}`.replace(
        `{${'visitId'}}`,
        encodeURIComponent(String(visitId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (carrierVisitId !== undefined) {
        localVarQueryParameter['CarrierVisitId'] = carrierVisitId
      }

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} nnrOrderId
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [nNROrderId]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuitableContainers: async (
      nnrOrderId: number,
      page: number,
      pageSize: number,
      nNROrderId?: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nnrOrderId' is not null or undefined
      assertParamExists('getSuitableContainers', 'nnrOrderId', nnrOrderId)
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getSuitableContainers', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getSuitableContainers', 'pageSize', pageSize)
      const localVarPath = `/planning/api/NonNumericOrders/{nnrOrderId}/containers`.replace(
        `{${'nnrOrderId'}}`,
        encodeURIComponent(String(nnrOrderId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (nNROrderId !== undefined) {
        localVarQueryParameter['NNROrderId'] = nNROrderId
      }

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orders: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('orders', 'id', id)
      const localVarPath = `/planning/api/NonNumericOrders/{id}/orders`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateNonNumericOrderCommand} [createNonNumericOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (
      createNonNumericOrderCommand?: CreateNonNumericOrderCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/NonNumericOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createNonNumericOrderCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {UpdateNonNumericOrderCommand} [updateNonNumericOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put: async (
      id: number,
      updateNonNumericOrderCommand?: UpdateNonNumericOrderCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('put', 'id', id)
      const localVarPath = `/planning/api/NonNumericOrders/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateNonNumericOrderCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UnassignContainerCommand} [unassignContainerCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unassignContainer: async (
      unassignContainerCommand?: UnassignContainerCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/NonNumericOrders/unassignContainer`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        unassignContainerCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * NonNumericOrdersApi - functional programming interface
 * @export
 */
export const NonNumericOrdersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NonNumericOrdersApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {AssignContainerCommand} [assignContainerCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignContainer(
      id: number,
      assignContainerCommand?: AssignContainerCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assignContainer(
        id,
        assignContainerCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {CloseNonNumericOrderCommand} [closeNonNumericOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async closeOrder(
      id: number,
      closeNonNumericOrderCommand?: CloseNonNumericOrderCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.closeOrder(
        id,
        closeNonNumericOrderCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierType} [modeOfTransport]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      page: number,
      pageSize: number,
      modeOfTransport?: CarrierType,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonNumericOrderDtoPaginatedList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        page,
        pageSize,
        modeOfTransport,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getById(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonNumericOrderDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [referenceNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByReferenceNumber(
      referenceNumber?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NonNumericOrderDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByReferenceNumber(
        referenceNumber,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} visitId
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [carrierVisitId]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getOrdersByCarrierVisitId(
      visitId: number,
      page: number,
      pageSize: number,
      carrierVisitId?: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonNumericOrderDtoPaginatedList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersByCarrierVisitId(
        visitId,
        page,
        pageSize,
        carrierVisitId,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} nnrOrderId
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [nNROrderId]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSuitableContainers(
      nnrOrderId: number,
      page: number,
      pageSize: number,
      nNROrderId?: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContainerDtoPaginatedList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSuitableContainers(
        nnrOrderId,
        page,
        pageSize,
        nNROrderId,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orders(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.orders(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateNonNumericOrderCommand} [createNonNumericOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(
      createNonNumericOrderCommand?: CreateNonNumericOrderCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(
        createNonNumericOrderCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {UpdateNonNumericOrderCommand} [updateNonNumericOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async put(
      id: number,
      updateNonNumericOrderCommand?: UpdateNonNumericOrderCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.put(
        id,
        updateNonNumericOrderCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UnassignContainerCommand} [unassignContainerCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unassignContainer(
      unassignContainerCommand?: UnassignContainerCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unassignContainer(
        unassignContainerCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * NonNumericOrdersApi - factory interface
 * @export
 */
export const NonNumericOrdersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NonNumericOrdersApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {AssignContainerCommand} [assignContainerCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignContainer(
      id: number,
      assignContainerCommand?: AssignContainerCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .assignContainer(id, assignContainerCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {CloseNonNumericOrderCommand} [closeNonNumericOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeOrder(
      id: number,
      closeNonNumericOrderCommand?: CloseNonNumericOrderCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .closeOrder(id, closeNonNumericOrderCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierType} [modeOfTransport]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      page: number,
      pageSize: number,
      modeOfTransport?: CarrierType,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<NonNumericOrderDtoPaginatedList> {
      return localVarFp
        .get(
          page,
          pageSize,
          modeOfTransport,
          filter,
          includeCompleted,
          orderBy,
          isDescending,
          from,
          to,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(id: number, options?: any): AxiosPromise<NonNumericOrderDto> {
      return localVarFp.getById(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [referenceNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByReferenceNumber(
      referenceNumber?: string,
      options?: any,
    ): AxiosPromise<Array<NonNumericOrderDto>> {
      return localVarFp
        .getByReferenceNumber(referenceNumber, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} visitId
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [carrierVisitId]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getOrdersByCarrierVisitId(
      visitId: number,
      page: number,
      pageSize: number,
      carrierVisitId?: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<NonNumericOrderDtoPaginatedList> {
      return localVarFp
        .getOrdersByCarrierVisitId(
          visitId,
          page,
          pageSize,
          carrierVisitId,
          filter,
          includeCompleted,
          orderBy,
          isDescending,
          from,
          to,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} nnrOrderId
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [nNROrderId]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuitableContainers(
      nnrOrderId: number,
      page: number,
      pageSize: number,
      nNROrderId?: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<ContainerDtoPaginatedList> {
      return localVarFp
        .getSuitableContainers(
          nnrOrderId,
          page,
          pageSize,
          nNROrderId,
          filter,
          includeCompleted,
          orderBy,
          isDescending,
          from,
          to,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orders(id: number, options?: any): AxiosPromise<Array<OrderResponseDto>> {
      return localVarFp.orders(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateNonNumericOrderCommand} [createNonNumericOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(
      createNonNumericOrderCommand?: CreateNonNumericOrderCommand,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .post(createNonNumericOrderCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {UpdateNonNumericOrderCommand} [updateNonNumericOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put(
      id: number,
      updateNonNumericOrderCommand?: UpdateNonNumericOrderCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .put(id, updateNonNumericOrderCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UnassignContainerCommand} [unassignContainerCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unassignContainer(
      unassignContainerCommand?: UnassignContainerCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .unassignContainer(unassignContainerCommand, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * NonNumericOrdersApi - object-oriented interface
 * @export
 * @class NonNumericOrdersApi
 * @extends {BaseAPI}
 */
export class NonNumericOrdersApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {AssignContainerCommand} [assignContainerCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonNumericOrdersApi
   */
  public assignContainer(
    id: number,
    assignContainerCommand?: AssignContainerCommand,
    options?: AxiosRequestConfig,
  ) {
    return NonNumericOrdersApiFp(this.configuration)
      .assignContainer(id, assignContainerCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {CloseNonNumericOrderCommand} [closeNonNumericOrderCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonNumericOrdersApi
   */
  public closeOrder(
    id: number,
    closeNonNumericOrderCommand?: CloseNonNumericOrderCommand,
    options?: AxiosRequestConfig,
  ) {
    return NonNumericOrdersApiFp(this.configuration)
      .closeOrder(id, closeNonNumericOrderCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} page
   * @param {number} pageSize
   * @param {CarrierType} [modeOfTransport]
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonNumericOrdersApi
   */
  public get(
    page: number,
    pageSize: number,
    modeOfTransport?: CarrierType,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return NonNumericOrdersApiFp(this.configuration)
      .get(
        page,
        pageSize,
        modeOfTransport,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonNumericOrdersApi
   */
  public getById(id: number, options?: AxiosRequestConfig) {
    return NonNumericOrdersApiFp(this.configuration)
      .getById(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [referenceNumber]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonNumericOrdersApi
   */
  public getByReferenceNumber(referenceNumber?: string, options?: AxiosRequestConfig) {
    return NonNumericOrdersApiFp(this.configuration)
      .getByReferenceNumber(referenceNumber, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} visitId
   * @param {number} page
   * @param {number} pageSize
   * @param {number} [carrierVisitId]
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof NonNumericOrdersApi
   */
  public getOrdersByCarrierVisitId(
    visitId: number,
    page: number,
    pageSize: number,
    carrierVisitId?: number,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return NonNumericOrdersApiFp(this.configuration)
      .getOrdersByCarrierVisitId(
        visitId,
        page,
        pageSize,
        carrierVisitId,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} nnrOrderId
   * @param {number} page
   * @param {number} pageSize
   * @param {number} [nNROrderId]
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonNumericOrdersApi
   */
  public getSuitableContainers(
    nnrOrderId: number,
    page: number,
    pageSize: number,
    nNROrderId?: number,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return NonNumericOrdersApiFp(this.configuration)
      .getSuitableContainers(
        nnrOrderId,
        page,
        pageSize,
        nNROrderId,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonNumericOrdersApi
   */
  public orders(id: number, options?: AxiosRequestConfig) {
    return NonNumericOrdersApiFp(this.configuration)
      .orders(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateNonNumericOrderCommand} [createNonNumericOrderCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonNumericOrdersApi
   */
  public post(
    createNonNumericOrderCommand?: CreateNonNumericOrderCommand,
    options?: AxiosRequestConfig,
  ) {
    return NonNumericOrdersApiFp(this.configuration)
      .post(createNonNumericOrderCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {UpdateNonNumericOrderCommand} [updateNonNumericOrderCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonNumericOrdersApi
   */
  public put(
    id: number,
    updateNonNumericOrderCommand?: UpdateNonNumericOrderCommand,
    options?: AxiosRequestConfig,
  ) {
    return NonNumericOrdersApiFp(this.configuration)
      .put(id, updateNonNumericOrderCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UnassignContainerCommand} [unassignContainerCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NonNumericOrdersApi
   */
  public unassignContainer(
    unassignContainerCommand?: UnassignContainerCommand,
    options?: AxiosRequestConfig,
  ) {
    return NonNumericOrdersApiFp(this.configuration)
      .unassignContainer(unassignContainerCommand, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * OcrApi - axios parameter creator
 * @export
 */
export const OcrApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Ocr`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)()

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (file !== undefined) {
        localVarFormParams.append('file', file as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OcrApi - functional programming interface
 * @export
 */
export const OcrApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OcrApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(
      file?: File,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(file, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * OcrApi - factory interface
 * @export
 */
export const OcrApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OcrApiFp(configuration)
  return {
    /**
     *
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(file?: File, options?: any): AxiosPromise<string> {
      return localVarFp.post(file, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * OcrApi - object-oriented interface
 * @export
 * @class OcrApi
 * @extends {BaseAPI}
 */
export class OcrApi extends BaseAPI {
  /**
   *
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OcrApi
   */
  public post(file?: File, options?: AxiosRequestConfig) {
    return OcrApiFp(this.configuration)
      .post(file, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {boolean} [deleteLinkedOrders]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    _delete: async (
      id: number,
      deleteLinkedOrders?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id)
      const localVarPath = `/planning/api/Orders/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (deleteLinkedOrders !== undefined) {
        localVarQueryParameter['deleteLinkedOrders'] = deleteLinkedOrders
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {ConfirmActualCargoAmountOrderCommand} [confirmActualCargoAmountOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    actualCargoAmount: async (
      id: number,
      confirmActualCargoAmountOrderCommand?: ConfirmActualCargoAmountOrderCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('actualCargoAmount', 'id', id)
      const localVarPath = `/planning/api/Orders/{id}/ActualCargoAmount`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        confirmActualCargoAmountOrderCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {AllowOrderPassageCommand} [allowOrderPassageCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allowPassage: async (
      allowOrderPassageCommand?: AllowOrderPassageCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders/AllowPassage`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        allowOrderPassageCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateReleaseOrdersCommand} [updateReleaseOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignToRailcar: async (
      updateReleaseOrdersCommand?: UpdateReleaseOrdersCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders/AssignToRailcar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateReleaseOrdersCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {AssignOrderToRailcarTrackPositionCommand} [assignOrderToRailcarTrackPositionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignToRailcarTrackPosition: async (
      assignOrderToRailcarTrackPositionCommand?: AssignOrderToRailcarTrackPositionCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders/AssignToRailcarTrackPosition`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        assignOrderToRailcarTrackPositionCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerJourney: async (
      filter?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders/ContainerJourney`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateOrAssignOrderToRailcarTrackPositionCommand} [createOrAssignOrderToRailcarTrackPositionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrAssignToRailcarTrackPosition: async (
      createOrAssignOrderToRailcarTrackPositionCommand?: CreateOrAssignOrderToRailcarTrackPositionCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders/CreateOrAssignToRailcarTrackPosition`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOrAssignOrderToRailcarTrackPositionCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateCustomerSequenceCommand} [updateCustomerSequenceCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerSequence: async (
      updateCustomerSequenceCommand?: UpdateCustomerSequenceCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders/CustomerSequence`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCustomerSequenceCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {DeleteOrdersCommand} [deleteOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteByIds: async (
      deleteOrdersCommand?: DeleteOrdersCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders/DeleteByIds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteOrdersCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {OrderStatus} [orderStatus]
     * @param {boolean} [includeCompleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliveries: async (
      filter?: string,
      orderStatus?: OrderStatus,
      includeCompleted?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders/Deliveries`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (orderStatus !== undefined) {
        localVarQueryParameter['OrderStatus'] = orderStatus
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dropOffOrders: async (
      filter?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders/DropOffOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetFirstAvailableOrderInGroupQuery} [getFirstAvailableOrderInGroupQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    firstAvailableOrderInGroups: async (
      getFirstAvailableOrderInGroupQuery?: GetFirstAvailableOrderInGroupQuery,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders/FirstAvailableOrderInGroups`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getFirstAvailableOrderInGroupQuery,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} filter
     * @param {OrderStatus} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByContainerNumber: async (
      filter: string,
      status?: OrderStatus,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'filter' is not null or undefined
      assertParamExists('getByContainerNumber', 'filter', filter)
      const localVarPath = `/planning/api/Orders/containerNumber`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getById', 'id', id)
      const localVarPath = `/planning/api/Orders/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds: async (
      ids: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ids' is not null or undefined
      assertParamExists('getByIds', 'ids', ids)
      const localVarPath = `/planning/api/Orders/GetByIds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (ids) {
        localVarQueryParameter['Ids'] = ids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} filter
     * @param {CarrierVisitDirection} direction
     * @param {CarrierType} [carrierType]
     * @param {OrderStatus} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByReferenceNumber: async (
      filter: string,
      direction: CarrierVisitDirection,
      carrierType?: CarrierType,
      status?: OrderStatus,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'filter' is not null or undefined
      assertParamExists('getByReferenceNumber', 'filter', filter)
      // verify required parameter 'direction' is not null or undefined
      assertParamExists('getByReferenceNumber', 'direction', direction)
      const localVarPath = `/planning/api/Orders/referenceNumber`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (direction !== undefined) {
        localVarQueryParameter['Direction'] = direction
      }

      if (carrierType !== undefined) {
        localVarQueryParameter['CarrierType'] = carrierType
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [filter]
     * @param {boolean} [isSearchByReferenceNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pickUpOrders: async (
      filter?: string,
      isSearchByReferenceNumber?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders/PickUpOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (isSearchByReferenceNumber !== undefined) {
        localVarQueryParameter['IsSearchByReferenceNumber'] = isSearchByReferenceNumber
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateOrdersCommand} [createOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (
      createOrdersCommand?: CreateOrdersCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOrdersCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateOrderCommand} [updateOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put: async (
      updateOrderCommand?: UpdateOrderCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateOrderCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {OrderStatus} [orderStatus]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    releaseOrders: async (
      page: number,
      pageSize: number,
      orderStatus?: OrderStatus,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('releaseOrders', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('releaseOrders', 'pageSize', pageSize)
      const localVarPath = `/planning/api/Orders/releases`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (orderStatus !== undefined) {
        localVarQueryParameter['OrderStatus'] = orderStatus
      }

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    sync: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders/sync`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierVisitDirection} [direction]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    truckAppointmentOrders: async (
      page: number,
      pageSize: number,
      direction?: CarrierVisitDirection,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('truckAppointmentOrders', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('truckAppointmentOrders', 'pageSize', pageSize)
      const localVarPath = `/planning/api/Orders/truck-appointments`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (direction !== undefined) {
        localVarQueryParameter['Direction'] = direction
      }

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateReleaseOrdersCommand} [updateReleaseOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrdersReleaseTime: async (
      updateReleaseOrdersCommand?: UpdateReleaseOrdersCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Orders/UpdateOrdersReleaseTime`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateReleaseOrdersCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {UpdateOrderWeightCommand} [updateOrderWeightCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    weight: async (
      id: number,
      updateOrderWeightCommand?: UpdateOrderWeightCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('weight', 'id', id)
      const localVarPath = `/planning/api/Orders/{id}/Weight`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateOrderWeightCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {boolean} [deleteLinkedOrders]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      deleteLinkedOrders?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(
        id,
        deleteLinkedOrders,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {ConfirmActualCargoAmountOrderCommand} [confirmActualCargoAmountOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async actualCargoAmount(
      id: number,
      confirmActualCargoAmountOrderCommand?: ConfirmActualCargoAmountOrderCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.actualCargoAmount(
        id,
        confirmActualCargoAmountOrderCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {AllowOrderPassageCommand} [allowOrderPassageCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async allowPassage(
      allowOrderPassageCommand?: AllowOrderPassageCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.allowPassage(
        allowOrderPassageCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateReleaseOrdersCommand} [updateReleaseOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignToRailcar(
      updateReleaseOrdersCommand?: UpdateReleaseOrdersCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assignToRailcar(
        updateReleaseOrdersCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {AssignOrderToRailcarTrackPositionCommand} [assignOrderToRailcarTrackPositionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignToRailcarTrackPosition(
      assignOrderToRailcarTrackPositionCommand?: AssignOrderToRailcarTrackPositionCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assignToRailcarTrackPosition(
        assignOrderToRailcarTrackPositionCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerJourney(
      filter?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerJourneyDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerJourney(filter, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateOrAssignOrderToRailcarTrackPositionCommand} [createOrAssignOrderToRailcarTrackPositionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOrAssignToRailcarTrackPosition(
      createOrAssignOrderToRailcarTrackPositionCommand?: CreateOrAssignOrderToRailcarTrackPositionCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createOrAssignToRailcarTrackPosition(
          createOrAssignOrderToRailcarTrackPositionCommand,
          options,
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateCustomerSequenceCommand} [updateCustomerSequenceCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customerSequence(
      updateCustomerSequenceCommand?: UpdateCustomerSequenceCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customerSequence(
        updateCustomerSequenceCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {DeleteOrdersCommand} [deleteOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteByIds(
      deleteOrdersCommand?: DeleteOrdersCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByIds(
        deleteOrdersCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [filter]
     * @param {OrderStatus} [orderStatus]
     * @param {boolean} [includeCompleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deliveries(
      filter?: string,
      orderStatus?: OrderStatus,
      includeCompleted?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deliveries(
        filter,
        orderStatus,
        includeCompleted,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dropOffOrders(
      filter?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dropOffOrders(filter, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetFirstAvailableOrderInGroupQuery} [getFirstAvailableOrderInGroupQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async firstAvailableOrderInGroups(
      getFirstAvailableOrderInGroupQuery?: GetFirstAvailableOrderInGroupQuery,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<FirstAvailableOrderInGroupDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.firstAvailableOrderInGroups(
        getFirstAvailableOrderInGroupQuery,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} filter
     * @param {OrderStatus} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByContainerNumber(
      filter: string,
      status?: OrderStatus,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByContainerNumber(
        filter,
        status,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getById(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByIds(
      ids: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByIds(ids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} filter
     * @param {CarrierVisitDirection} direction
     * @param {CarrierType} [carrierType]
     * @param {OrderStatus} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByReferenceNumber(
      filter: string,
      direction: CarrierVisitDirection,
      carrierType?: CarrierType,
      status?: OrderStatus,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByReferenceNumber(
        filter,
        direction,
        carrierType,
        status,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [filter]
     * @param {boolean} [isSearchByReferenceNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pickUpOrders(
      filter?: string,
      isSearchByReferenceNumber?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pickUpOrders(
        filter,
        isSearchByReferenceNumber,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateOrdersCommand} [createOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(
      createOrdersCommand?: CreateOrdersCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(createOrdersCommand, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateOrderCommand} [updateOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async put(
      updateOrderCommand?: UpdateOrderCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.put(updateOrderCommand, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {OrderStatus} [orderStatus]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async releaseOrders(
      page: number,
      pageSize: number,
      orderStatus?: OrderStatus,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseOrderDtoPaginatedList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.releaseOrders(
        page,
        pageSize,
        orderStatus,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async sync(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncOrdersDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sync(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierVisitDirection} [direction]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async truckAppointmentOrders(
      page: number,
      pageSize: number,
      direction?: CarrierVisitDirection,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TruckAppointmentOrderDtoPaginatedList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.truckAppointmentOrders(
        page,
        pageSize,
        direction,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateReleaseOrdersCommand} [updateReleaseOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateOrdersReleaseTime(
      updateReleaseOrdersCommand?: UpdateReleaseOrdersCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrdersReleaseTime(
        updateReleaseOrdersCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {UpdateOrderWeightCommand} [updateOrderWeightCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async weight(
      id: number,
      updateOrderWeightCommand?: UpdateOrderWeightCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.weight(
        id,
        updateOrderWeightCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrdersApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {boolean} [deleteLinkedOrders]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    _delete(id: number, deleteLinkedOrders?: boolean, options?: any): AxiosPromise<boolean> {
      return localVarFp
        ._delete(id, deleteLinkedOrders, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {ConfirmActualCargoAmountOrderCommand} [confirmActualCargoAmountOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    actualCargoAmount(
      id: number,
      confirmActualCargoAmountOrderCommand?: ConfirmActualCargoAmountOrderCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .actualCargoAmount(id, confirmActualCargoAmountOrderCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {AllowOrderPassageCommand} [allowOrderPassageCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allowPassage(
      allowOrderPassageCommand?: AllowOrderPassageCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .allowPassage(allowOrderPassageCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateReleaseOrdersCommand} [updateReleaseOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignToRailcar(
      updateReleaseOrdersCommand?: UpdateReleaseOrdersCommand,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .assignToRailcar(updateReleaseOrdersCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {AssignOrderToRailcarTrackPositionCommand} [assignOrderToRailcarTrackPositionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignToRailcarTrackPosition(
      assignOrderToRailcarTrackPositionCommand?: AssignOrderToRailcarTrackPositionCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .assignToRailcarTrackPosition(assignOrderToRailcarTrackPositionCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerJourney(filter?: string, options?: any): AxiosPromise<Array<ContainerJourneyDto>> {
      return localVarFp.containerJourney(filter, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateOrAssignOrderToRailcarTrackPositionCommand} [createOrAssignOrderToRailcarTrackPositionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrAssignToRailcarTrackPosition(
      createOrAssignOrderToRailcarTrackPositionCommand?: CreateOrAssignOrderToRailcarTrackPositionCommand,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .createOrAssignToRailcarTrackPosition(
          createOrAssignOrderToRailcarTrackPositionCommand,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateCustomerSequenceCommand} [updateCustomerSequenceCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerSequence(
      updateCustomerSequenceCommand?: UpdateCustomerSequenceCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .customerSequence(updateCustomerSequenceCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {DeleteOrdersCommand} [deleteOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteByIds(deleteOrdersCommand?: DeleteOrdersCommand, options?: any): AxiosPromise<boolean> {
      return localVarFp
        .deleteByIds(deleteOrdersCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [filter]
     * @param {OrderStatus} [orderStatus]
     * @param {boolean} [includeCompleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliveries(
      filter?: string,
      orderStatus?: OrderStatus,
      includeCompleted?: boolean,
      options?: any,
    ): AxiosPromise<Array<OrderResponseDto>> {
      return localVarFp
        .deliveries(filter, orderStatus, includeCompleted, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dropOffOrders(filter?: string, options?: any): AxiosPromise<Array<OrderResponseDto>> {
      return localVarFp.dropOffOrders(filter, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {GetFirstAvailableOrderInGroupQuery} [getFirstAvailableOrderInGroupQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    firstAvailableOrderInGroups(
      getFirstAvailableOrderInGroupQuery?: GetFirstAvailableOrderInGroupQuery,
      options?: any,
    ): AxiosPromise<Array<FirstAvailableOrderInGroupDto>> {
      return localVarFp
        .firstAvailableOrderInGroups(getFirstAvailableOrderInGroupQuery, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} filter
     * @param {OrderStatus} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByContainerNumber(
      filter: string,
      status?: OrderStatus,
      options?: any,
    ): AxiosPromise<Array<OrderResponseDto>> {
      return localVarFp
        .getByContainerNumber(filter, status, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(id: number, options?: any): AxiosPromise<OrderResponseDto> {
      return localVarFp.getById(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds(ids: Array<number>, options?: any): AxiosPromise<Array<OrderResponseDto>> {
      return localVarFp.getByIds(ids, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} filter
     * @param {CarrierVisitDirection} direction
     * @param {CarrierType} [carrierType]
     * @param {OrderStatus} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByReferenceNumber(
      filter: string,
      direction: CarrierVisitDirection,
      carrierType?: CarrierType,
      status?: OrderStatus,
      options?: any,
    ): AxiosPromise<Array<OrderResponseDto>> {
      return localVarFp
        .getByReferenceNumber(filter, direction, carrierType, status, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [filter]
     * @param {boolean} [isSearchByReferenceNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pickUpOrders(
      filter?: string,
      isSearchByReferenceNumber?: boolean,
      options?: any,
    ): AxiosPromise<Array<OrderResponseDto>> {
      return localVarFp
        .pickUpOrders(filter, isSearchByReferenceNumber, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateOrdersCommand} [createOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(createOrdersCommand?: CreateOrdersCommand, options?: any): AxiosPromise<Array<number>> {
      return localVarFp.post(createOrdersCommand, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateOrderCommand} [updateOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put(updateOrderCommand?: UpdateOrderCommand, options?: any): AxiosPromise<void> {
      return localVarFp.put(updateOrderCommand, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {OrderStatus} [orderStatus]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    releaseOrders(
      page: number,
      pageSize: number,
      orderStatus?: OrderStatus,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<ReleaseOrderDtoPaginatedList> {
      return localVarFp
        .releaseOrders(
          page,
          pageSize,
          orderStatus,
          filter,
          includeCompleted,
          orderBy,
          isDescending,
          from,
          to,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    sync(from?: string, options?: any): AxiosPromise<SyncOrdersDto> {
      return localVarFp.sync(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierVisitDirection} [direction]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    truckAppointmentOrders(
      page: number,
      pageSize: number,
      direction?: CarrierVisitDirection,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<TruckAppointmentOrderDtoPaginatedList> {
      return localVarFp
        .truckAppointmentOrders(
          page,
          pageSize,
          direction,
          filter,
          includeCompleted,
          orderBy,
          isDescending,
          from,
          to,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateReleaseOrdersCommand} [updateReleaseOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrdersReleaseTime(
      updateReleaseOrdersCommand?: UpdateReleaseOrdersCommand,
      options?: any,
    ): AxiosPromise<Array<number>> {
      return localVarFp
        .updateOrdersReleaseTime(updateReleaseOrdersCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {UpdateOrderWeightCommand} [updateOrderWeightCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    weight(
      id: number,
      updateOrderWeightCommand?: UpdateOrderWeightCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .weight(id, updateOrderWeightCommand, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {boolean} [deleteLinkedOrders]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public _delete(id: number, deleteLinkedOrders?: boolean, options?: AxiosRequestConfig) {
    return OrdersApiFp(this.configuration)
      ._delete(id, deleteLinkedOrders, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {ConfirmActualCargoAmountOrderCommand} [confirmActualCargoAmountOrderCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public actualCargoAmount(
    id: number,
    confirmActualCargoAmountOrderCommand?: ConfirmActualCargoAmountOrderCommand,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .actualCargoAmount(id, confirmActualCargoAmountOrderCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {AllowOrderPassageCommand} [allowOrderPassageCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public allowPassage(
    allowOrderPassageCommand?: AllowOrderPassageCommand,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .allowPassage(allowOrderPassageCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateReleaseOrdersCommand} [updateReleaseOrdersCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public assignToRailcar(
    updateReleaseOrdersCommand?: UpdateReleaseOrdersCommand,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .assignToRailcar(updateReleaseOrdersCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {AssignOrderToRailcarTrackPositionCommand} [assignOrderToRailcarTrackPositionCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public assignToRailcarTrackPosition(
    assignOrderToRailcarTrackPositionCommand?: AssignOrderToRailcarTrackPositionCommand,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .assignToRailcarTrackPosition(assignOrderToRailcarTrackPositionCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public containerJourney(filter?: string, options?: AxiosRequestConfig) {
    return OrdersApiFp(this.configuration)
      .containerJourney(filter, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateOrAssignOrderToRailcarTrackPositionCommand} [createOrAssignOrderToRailcarTrackPositionCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public createOrAssignToRailcarTrackPosition(
    createOrAssignOrderToRailcarTrackPositionCommand?: CreateOrAssignOrderToRailcarTrackPositionCommand,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .createOrAssignToRailcarTrackPosition(
        createOrAssignOrderToRailcarTrackPositionCommand,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateCustomerSequenceCommand} [updateCustomerSequenceCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public customerSequence(
    updateCustomerSequenceCommand?: UpdateCustomerSequenceCommand,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .customerSequence(updateCustomerSequenceCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DeleteOrdersCommand} [deleteOrdersCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public deleteByIds(deleteOrdersCommand?: DeleteOrdersCommand, options?: AxiosRequestConfig) {
    return OrdersApiFp(this.configuration)
      .deleteByIds(deleteOrdersCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [filter]
   * @param {OrderStatus} [orderStatus]
   * @param {boolean} [includeCompleted]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public deliveries(
    filter?: string,
    orderStatus?: OrderStatus,
    includeCompleted?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .deliveries(filter, orderStatus, includeCompleted, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public dropOffOrders(filter?: string, options?: AxiosRequestConfig) {
    return OrdersApiFp(this.configuration)
      .dropOffOrders(filter, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetFirstAvailableOrderInGroupQuery} [getFirstAvailableOrderInGroupQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public firstAvailableOrderInGroups(
    getFirstAvailableOrderInGroupQuery?: GetFirstAvailableOrderInGroupQuery,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .firstAvailableOrderInGroups(getFirstAvailableOrderInGroupQuery, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} filter
   * @param {OrderStatus} [status]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public getByContainerNumber(filter: string, status?: OrderStatus, options?: AxiosRequestConfig) {
    return OrdersApiFp(this.configuration)
      .getByContainerNumber(filter, status, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public getById(id: number, options?: AxiosRequestConfig) {
    return OrdersApiFp(this.configuration)
      .getById(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Array<number>} ids
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public getByIds(ids: Array<number>, options?: AxiosRequestConfig) {
    return OrdersApiFp(this.configuration)
      .getByIds(ids, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} filter
   * @param {CarrierVisitDirection} direction
   * @param {CarrierType} [carrierType]
   * @param {OrderStatus} [status]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public getByReferenceNumber(
    filter: string,
    direction: CarrierVisitDirection,
    carrierType?: CarrierType,
    status?: OrderStatus,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .getByReferenceNumber(filter, direction, carrierType, status, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [filter]
   * @param {boolean} [isSearchByReferenceNumber]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public pickUpOrders(
    filter?: string,
    isSearchByReferenceNumber?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .pickUpOrders(filter, isSearchByReferenceNumber, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateOrdersCommand} [createOrdersCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public post(createOrdersCommand?: CreateOrdersCommand, options?: AxiosRequestConfig) {
    return OrdersApiFp(this.configuration)
      .post(createOrdersCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateOrderCommand} [updateOrderCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public put(updateOrderCommand?: UpdateOrderCommand, options?: AxiosRequestConfig) {
    return OrdersApiFp(this.configuration)
      .put(updateOrderCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} page
   * @param {number} pageSize
   * @param {OrderStatus} [orderStatus]
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public releaseOrders(
    page: number,
    pageSize: number,
    orderStatus?: OrderStatus,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .releaseOrders(
        page,
        pageSize,
        orderStatus,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public sync(from?: string, options?: AxiosRequestConfig) {
    return OrdersApiFp(this.configuration)
      .sync(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} page
   * @param {number} pageSize
   * @param {CarrierVisitDirection} [direction]
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public truckAppointmentOrders(
    page: number,
    pageSize: number,
    direction?: CarrierVisitDirection,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .truckAppointmentOrders(
        page,
        pageSize,
        direction,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateReleaseOrdersCommand} [updateReleaseOrdersCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public updateOrdersReleaseTime(
    updateReleaseOrdersCommand?: UpdateReleaseOrdersCommand,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .updateOrdersReleaseTime(updateReleaseOrdersCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {UpdateOrderWeightCommand} [updateOrderWeightCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public weight(
    id: number,
    updateOrderWeightCommand?: UpdateOrderWeightCommand,
    options?: AxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .weight(id, updateOrderWeightCommand, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * PackagesApi - axios parameter creator
 * @export
 */
export const PackagesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (query?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Packages/Get`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (query !== undefined) {
        localVarQueryParameter['query'] = query
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PackagesApi - functional programming interface
 * @export
 */
export const PackagesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PackagesApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      query?: object,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PackageResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(query, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * PackagesApi - factory interface
 * @export
 */
export const PackagesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PackagesApiFp(configuration)
  return {
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(query?: object, options?: any): AxiosPromise<Array<PackageResponseDto>> {
      return localVarFp.get(query, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * PackagesApi - object-oriented interface
 * @export
 * @class PackagesApi
 * @extends {BaseAPI}
 */
export class PackagesApi extends BaseAPI {
  /**
   *
   * @param {object} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PackagesApi
   */
  public get(query?: object, options?: AxiosRequestConfig) {
    return PackagesApiFp(this.configuration)
      .get(query, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * PiersApi - axios parameter creator
 * @export
 */
export const PiersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Piers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PiersApi - functional programming interface
 * @export
 */
export const PiersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PiersApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PierResponseDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * PiersApi - factory interface
 * @export
 */
export const PiersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PiersApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options?: any): AxiosPromise<Array<PierResponseDto>> {
      return localVarFp.get(options).then(request => request(axios, basePath))
    },
  }
}

/**
 * PiersApi - object-oriented interface
 * @export
 * @class PiersApi
 * @extends {BaseAPI}
 */
export class PiersApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PiersApi
   */
  public get(options?: AxiosRequestConfig) {
    return PiersApiFp(this.configuration)
      .get(options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * PublicDataApi - axios parameter creator
 * @export
 */
export const PublicDataApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SyncStatus} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerSyncStatuses: async (
      status: SyncStatus,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'status' is not null or undefined
      assertParamExists('containerSyncStatuses', 'status', status)
      const localVarPath = `/planning/api/PublicData/ContainerSyncStatuses`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PublicDataApi - functional programming interface
 * @export
 */
export const PublicDataApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PublicDataApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {SyncStatus} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerSyncStatuses(
      status: SyncStatus,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ContainerSyncStatusResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerSyncStatuses(
        status,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * PublicDataApi - factory interface
 * @export
 */
export const PublicDataApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PublicDataApiFp(configuration)
  return {
    /**
     *
     * @param {SyncStatus} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerSyncStatuses(
      status: SyncStatus,
      options?: any,
    ): AxiosPromise<Array<ContainerSyncStatusResponseDto>> {
      return localVarFp
        .containerSyncStatuses(status, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * PublicDataApi - object-oriented interface
 * @export
 * @class PublicDataApi
 * @extends {BaseAPI}
 */
export class PublicDataApi extends BaseAPI {
  /**
   *
   * @param {SyncStatus} status
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicDataApi
   */
  public containerSyncStatuses(status: SyncStatus, options?: AxiosRequestConfig) {
    return PublicDataApiFp(this.configuration)
      .containerSyncStatuses(status, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * RailTracksApi - axios parameter creator
 * @export
 */
export const RailTracksApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/RailTracks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railcarTrackPositions: async (
      ids: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ids' is not null or undefined
      assertParamExists('railcarTrackPositions', 'ids', ids)
      const localVarPath = `/planning/api/RailTracks/RailcarTrackPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (ids) {
        localVarQueryParameter['Ids'] = ids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * RailTracksApi - functional programming interface
 * @export
 */
export const RailTracksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RailTracksApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RailTrackResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async railcarTrackPositions(
      ids: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<RailcarTrackPositionResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.railcarTrackPositions(ids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * RailTracksApi - factory interface
 * @export
 */
export const RailTracksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RailTracksApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options?: any): AxiosPromise<Array<RailTrackResponseDto>> {
      return localVarFp.get(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railcarTrackPositions(
      ids: Array<number>,
      options?: any,
    ): AxiosPromise<Array<RailcarTrackPositionResponseDto>> {
      return localVarFp
        .railcarTrackPositions(ids, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * RailTracksApi - object-oriented interface
 * @export
 * @class RailTracksApi
 * @extends {BaseAPI}
 */
export class RailTracksApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailTracksApi
   */
  public get(options?: AxiosRequestConfig) {
    return RailTracksApiFp(this.configuration)
      .get(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Array<number>} ids
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailTracksApi
   */
  public railcarTrackPositions(ids: Array<number>, options?: AxiosRequestConfig) {
    return RailTracksApiFp(this.configuration)
      .railcarTrackPositions(ids, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * RailVisitsApi - axios parameter creator
 * @export
 */
export const RailVisitsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AssignRailcarTrackPositionCommand} [assignRailcarTrackPositionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignRailcarTrackPosition: async (
      assignRailcarTrackPositionCommand?: AssignRailcarTrackPositionCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/RailVisits/AssignRailcarTrackPosition`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        assignRailcarTrackPositionCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CheckLoadPreparationCommand} [checkLoadPreparationCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkLoadPreparation: async (
      checkLoadPreparationCommand?: CheckLoadPreparationCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/RailVisits/CheckLoadPreparation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkLoadPreparationCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CheckRailcarForDischargeCommand} [checkRailcarForDischargeCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkRailcarForDischarge: async (
      checkRailcarForDischargeCommand?: CheckRailcarForDischargeCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/RailVisits/CheckRailcarForDischarge`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkRailcarForDischargeCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateRailVisitCommand} [createRailVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createRailVisitCommand?: CreateRailVisitCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/RailVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRailVisitCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateRailcarAndOrdersCommand} [createRailcarAndOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRailcarAndOrders: async (
      createRailcarAndOrdersCommand?: CreateRailcarAndOrdersCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/RailVisits/CreateRailcarAndOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRailcarAndOrdersCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {DeleteRailcarTrackPositionCommand} [deleteRailcarTrackPositionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRailcarTrackPosition: async (
      deleteRailcarTrackPositionCommand?: DeleteRailcarTrackPositionCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/RailVisits/DeleteRailcarTrackPosition`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteRailcarTrackPositionCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateDepartureCheckRailcarCommand} [createDepartureCheckRailcarCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    departureCheck: async (
      createDepartureCheckRailcarCommand?: CreateDepartureCheckRailcarCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/RailVisits/DepartureCheck`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDepartureCheckRailcarCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierVisitStatus} [status]
     * @param {CargoType} [cargoType]
     * @param {boolean} [includeAtaDateRangeFilter]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      page: number,
      pageSize: number,
      status?: CarrierVisitStatus,
      cargoType?: CargoType,
      includeAtaDateRangeFilter?: boolean,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('get', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('get', 'pageSize', pageSize)
      const localVarPath = `/planning/api/RailVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status
      }

      if (cargoType !== undefined) {
        localVarQueryParameter['CargoType'] = cargoType
      }

      if (includeAtaDateRangeFilter !== undefined) {
        localVarQueryParameter['IncludeAtaDateRangeFilter'] = includeAtaDateRangeFilter
      }

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getById', 'id', id)
      const localVarPath = `/planning/api/RailVisits/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds: async (
      ids: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ids' is not null or undefined
      assertParamExists('getByIds', 'ids', ids)
      const localVarPath = `/planning/api/RailVisits/GetByIds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (ids) {
        localVarQueryParameter['Ids'] = ids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateRailVisitCommand} [updateRailVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put: async (
      updateRailVisitCommand?: UpdateRailVisitCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/RailVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateRailVisitCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {UpdateRailVisitStatusCommand} [updateRailVisitStatusCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRailVisitStatus: async (
      id: number,
      updateRailVisitStatusCommand?: UpdateRailVisitStatusCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateRailVisitStatus', 'id', id)
      const localVarPath = `/planning/api/RailVisits/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateRailVisitStatusCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateRailcarSequenceCommand} [updateRailcarSequenceCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRailcarSequence: async (
      updateRailcarSequenceCommand?: UpdateRailcarSequenceCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/RailVisits/UpdateRailcarSequence`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateRailcarSequenceCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * RailVisitsApi - functional programming interface
 * @export
 */
export const RailVisitsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RailVisitsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {AssignRailcarTrackPositionCommand} [assignRailcarTrackPositionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignRailcarTrackPosition(
      assignRailcarTrackPositionCommand?: AssignRailcarTrackPositionCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assignRailcarTrackPosition(
        assignRailcarTrackPositionCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CheckLoadPreparationCommand} [checkLoadPreparationCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkLoadPreparation(
      checkLoadPreparationCommand?: CheckLoadPreparationCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkLoadPreparation(
        checkLoadPreparationCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CheckRailcarForDischargeCommand} [checkRailcarForDischargeCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkRailcarForDischarge(
      checkRailcarForDischargeCommand?: CheckRailcarForDischargeCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkRailcarForDischarge(
        checkRailcarForDischargeCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateRailVisitCommand} [createRailVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createRailVisitCommand?: CreateRailVisitCommand,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RailVisitResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(
        createRailVisitCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateRailcarAndOrdersCommand} [createRailcarAndOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRailcarAndOrders(
      createRailcarAndOrdersCommand?: CreateRailcarAndOrdersCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createRailcarAndOrders(
        createRailcarAndOrdersCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {DeleteRailcarTrackPositionCommand} [deleteRailcarTrackPositionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteRailcarTrackPosition(
      deleteRailcarTrackPositionCommand?: DeleteRailcarTrackPositionCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRailcarTrackPosition(
        deleteRailcarTrackPositionCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateDepartureCheckRailcarCommand} [createDepartureCheckRailcarCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async departureCheck(
      createDepartureCheckRailcarCommand?: CreateDepartureCheckRailcarCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.departureCheck(
        createDepartureCheckRailcarCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierVisitStatus} [status]
     * @param {CargoType} [cargoType]
     * @param {boolean} [includeAtaDateRangeFilter]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      page: number,
      pageSize: number,
      status?: CarrierVisitStatus,
      cargoType?: CargoType,
      includeAtaDateRangeFilter?: boolean,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RailVisitResponseDtoPaginatedList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        page,
        pageSize,
        status,
        cargoType,
        includeAtaDateRangeFilter,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getById(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RailVisitResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByIds(
      ids: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RailVisitResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByIds(ids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateRailVisitCommand} [updateRailVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async put(
      updateRailVisitCommand?: UpdateRailVisitCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RailVisitResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.put(updateRailVisitCommand, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {UpdateRailVisitStatusCommand} [updateRailVisitStatusCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRailVisitStatus(
      id: number,
      updateRailVisitStatusCommand?: UpdateRailVisitStatusCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateRailVisitStatus(
        id,
        updateRailVisitStatusCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateRailcarSequenceCommand} [updateRailcarSequenceCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRailcarSequence(
      updateRailcarSequenceCommand?: UpdateRailcarSequenceCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateRailcarSequence(
        updateRailcarSequenceCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * RailVisitsApi - factory interface
 * @export
 */
export const RailVisitsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RailVisitsApiFp(configuration)
  return {
    /**
     *
     * @param {AssignRailcarTrackPositionCommand} [assignRailcarTrackPositionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignRailcarTrackPosition(
      assignRailcarTrackPositionCommand?: AssignRailcarTrackPositionCommand,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .assignRailcarTrackPosition(assignRailcarTrackPositionCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CheckLoadPreparationCommand} [checkLoadPreparationCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkLoadPreparation(
      checkLoadPreparationCommand?: CheckLoadPreparationCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .checkLoadPreparation(checkLoadPreparationCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CheckRailcarForDischargeCommand} [checkRailcarForDischargeCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkRailcarForDischarge(
      checkRailcarForDischargeCommand?: CheckRailcarForDischargeCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .checkRailcarForDischarge(checkRailcarForDischargeCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateRailVisitCommand} [createRailVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      createRailVisitCommand?: CreateRailVisitCommand,
      options?: any,
    ): AxiosPromise<Array<RailVisitResponseDto>> {
      return localVarFp
        .create(createRailVisitCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateRailcarAndOrdersCommand} [createRailcarAndOrdersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRailcarAndOrders(
      createRailcarAndOrdersCommand?: CreateRailcarAndOrdersCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createRailcarAndOrders(createRailcarAndOrdersCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {DeleteRailcarTrackPositionCommand} [deleteRailcarTrackPositionCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRailcarTrackPosition(
      deleteRailcarTrackPositionCommand?: DeleteRailcarTrackPositionCommand,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .deleteRailcarTrackPosition(deleteRailcarTrackPositionCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateDepartureCheckRailcarCommand} [createDepartureCheckRailcarCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    departureCheck(
      createDepartureCheckRailcarCommand?: CreateDepartureCheckRailcarCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .departureCheck(createDepartureCheckRailcarCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierVisitStatus} [status]
     * @param {CargoType} [cargoType]
     * @param {boolean} [includeAtaDateRangeFilter]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      page: number,
      pageSize: number,
      status?: CarrierVisitStatus,
      cargoType?: CargoType,
      includeAtaDateRangeFilter?: boolean,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<RailVisitResponseDtoPaginatedList> {
      return localVarFp
        .get(
          page,
          pageSize,
          status,
          cargoType,
          includeAtaDateRangeFilter,
          filter,
          includeCompleted,
          orderBy,
          isDescending,
          from,
          to,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(id: number, options?: any): AxiosPromise<RailVisitResponseDto> {
      return localVarFp.getById(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds(ids: Array<number>, options?: any): AxiosPromise<Array<RailVisitResponseDto>> {
      return localVarFp.getByIds(ids, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateRailVisitCommand} [updateRailVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put(
      updateRailVisitCommand?: UpdateRailVisitCommand,
      options?: any,
    ): AxiosPromise<RailVisitResponseDto> {
      return localVarFp
        .put(updateRailVisitCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {UpdateRailVisitStatusCommand} [updateRailVisitStatusCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRailVisitStatus(
      id: number,
      updateRailVisitStatusCommand?: UpdateRailVisitStatusCommand,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .updateRailVisitStatus(id, updateRailVisitStatusCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateRailcarSequenceCommand} [updateRailcarSequenceCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRailcarSequence(
      updateRailcarSequenceCommand?: UpdateRailcarSequenceCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateRailcarSequence(updateRailcarSequenceCommand, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * RailVisitsApi - object-oriented interface
 * @export
 * @class RailVisitsApi
 * @extends {BaseAPI}
 */
export class RailVisitsApi extends BaseAPI {
  /**
   *
   * @param {AssignRailcarTrackPositionCommand} [assignRailcarTrackPositionCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailVisitsApi
   */
  public assignRailcarTrackPosition(
    assignRailcarTrackPositionCommand?: AssignRailcarTrackPositionCommand,
    options?: AxiosRequestConfig,
  ) {
    return RailVisitsApiFp(this.configuration)
      .assignRailcarTrackPosition(assignRailcarTrackPositionCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CheckLoadPreparationCommand} [checkLoadPreparationCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailVisitsApi
   */
  public checkLoadPreparation(
    checkLoadPreparationCommand?: CheckLoadPreparationCommand,
    options?: AxiosRequestConfig,
  ) {
    return RailVisitsApiFp(this.configuration)
      .checkLoadPreparation(checkLoadPreparationCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CheckRailcarForDischargeCommand} [checkRailcarForDischargeCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailVisitsApi
   */
  public checkRailcarForDischarge(
    checkRailcarForDischargeCommand?: CheckRailcarForDischargeCommand,
    options?: AxiosRequestConfig,
  ) {
    return RailVisitsApiFp(this.configuration)
      .checkRailcarForDischarge(checkRailcarForDischargeCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateRailVisitCommand} [createRailVisitCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailVisitsApi
   */
  public create(createRailVisitCommand?: CreateRailVisitCommand, options?: AxiosRequestConfig) {
    return RailVisitsApiFp(this.configuration)
      .create(createRailVisitCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateRailcarAndOrdersCommand} [createRailcarAndOrdersCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailVisitsApi
   */
  public createRailcarAndOrders(
    createRailcarAndOrdersCommand?: CreateRailcarAndOrdersCommand,
    options?: AxiosRequestConfig,
  ) {
    return RailVisitsApiFp(this.configuration)
      .createRailcarAndOrders(createRailcarAndOrdersCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DeleteRailcarTrackPositionCommand} [deleteRailcarTrackPositionCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailVisitsApi
   */
  public deleteRailcarTrackPosition(
    deleteRailcarTrackPositionCommand?: DeleteRailcarTrackPositionCommand,
    options?: AxiosRequestConfig,
  ) {
    return RailVisitsApiFp(this.configuration)
      .deleteRailcarTrackPosition(deleteRailcarTrackPositionCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateDepartureCheckRailcarCommand} [createDepartureCheckRailcarCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailVisitsApi
   */
  public departureCheck(
    createDepartureCheckRailcarCommand?: CreateDepartureCheckRailcarCommand,
    options?: AxiosRequestConfig,
  ) {
    return RailVisitsApiFp(this.configuration)
      .departureCheck(createDepartureCheckRailcarCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} page
   * @param {number} pageSize
   * @param {CarrierVisitStatus} [status]
   * @param {CargoType} [cargoType]
   * @param {boolean} [includeAtaDateRangeFilter]
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailVisitsApi
   */
  public get(
    page: number,
    pageSize: number,
    status?: CarrierVisitStatus,
    cargoType?: CargoType,
    includeAtaDateRangeFilter?: boolean,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return RailVisitsApiFp(this.configuration)
      .get(
        page,
        pageSize,
        status,
        cargoType,
        includeAtaDateRangeFilter,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailVisitsApi
   */
  public getById(id: number, options?: AxiosRequestConfig) {
    return RailVisitsApiFp(this.configuration)
      .getById(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Array<number>} ids
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailVisitsApi
   */
  public getByIds(ids: Array<number>, options?: AxiosRequestConfig) {
    return RailVisitsApiFp(this.configuration)
      .getByIds(ids, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateRailVisitCommand} [updateRailVisitCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailVisitsApi
   */
  public put(updateRailVisitCommand?: UpdateRailVisitCommand, options?: AxiosRequestConfig) {
    return RailVisitsApiFp(this.configuration)
      .put(updateRailVisitCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {UpdateRailVisitStatusCommand} [updateRailVisitStatusCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailVisitsApi
   */
  public updateRailVisitStatus(
    id: number,
    updateRailVisitStatusCommand?: UpdateRailVisitStatusCommand,
    options?: AxiosRequestConfig,
  ) {
    return RailVisitsApiFp(this.configuration)
      .updateRailVisitStatus(id, updateRailVisitStatusCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateRailcarSequenceCommand} [updateRailcarSequenceCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailVisitsApi
   */
  public updateRailcarSequence(
    updateRailcarSequenceCommand?: UpdateRailcarSequenceCommand,
    options?: AxiosRequestConfig,
  ) {
    return RailVisitsApiFp(this.configuration)
      .updateRailcarSequence(updateRailcarSequenceCommand, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * RailcarsApi - axios parameter creator
 * @export
 */
export const RailcarsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id)
      const localVarPath = `/planning/api/Railcars/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateRailcarCommand} [createRailcarCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createRailcarCommand?: CreateRailcarCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Railcars`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRailcarCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      page: number,
      pageSize: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('get', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('get', 'pageSize', pageSize)
      const localVarPath = `/planning/api/Railcars`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateRailcarCommand} [updateRailcarCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      updateRailcarCommand?: UpdateRailcarCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Railcars`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateRailcarCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * RailcarsApi - functional programming interface
 * @export
 */
export const RailcarsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RailcarsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateRailcarCommand} [createRailcarCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createRailcarCommand?: CreateRailcarCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(
        createRailcarCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      page: number,
      pageSize: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RailcarResponseDtoPaginatedList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        page,
        pageSize,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateRailcarCommand} [updateRailcarCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      updateRailcarCommand?: UpdateRailcarCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(
        updateRailcarCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * RailcarsApi - factory interface
 * @export
 */
export const RailcarsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RailcarsApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp._delete(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateRailcarCommand} [createRailcarCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(createRailcarCommand?: CreateRailcarCommand, options?: any): AxiosPromise<number> {
      return localVarFp
        .create(createRailcarCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      page: number,
      pageSize: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<RailcarResponseDtoPaginatedList> {
      return localVarFp
        .get(page, pageSize, filter, includeCompleted, orderBy, isDescending, from, to, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateRailcarCommand} [updateRailcarCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(updateRailcarCommand?: UpdateRailcarCommand, options?: any): AxiosPromise<void> {
      return localVarFp
        .update(updateRailcarCommand, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * RailcarsApi - object-oriented interface
 * @export
 * @class RailcarsApi
 * @extends {BaseAPI}
 */
export class RailcarsApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailcarsApi
   */
  public _delete(id: number, options?: AxiosRequestConfig) {
    return RailcarsApiFp(this.configuration)
      ._delete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateRailcarCommand} [createRailcarCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailcarsApi
   */
  public create(createRailcarCommand?: CreateRailcarCommand, options?: AxiosRequestConfig) {
    return RailcarsApiFp(this.configuration)
      .create(createRailcarCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} page
   * @param {number} pageSize
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailcarsApi
   */
  public get(
    page: number,
    pageSize: number,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return RailcarsApiFp(this.configuration)
      .get(page, pageSize, filter, includeCompleted, orderBy, isDescending, from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateRailcarCommand} [updateRailcarCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RailcarsApi
   */
  public update(updateRailcarCommand?: UpdateRailcarCommand, options?: AxiosRequestConfig) {
    return RailcarsApiFp(this.configuration)
      .update(updateRailcarCommand, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * ReSyncApi - axios parameter creator
 * @export
 */
export const ReSyncApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/ReSync/Berths`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/ReSync/Commodities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/ReSync/ContainerDamageLocations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/ReSync/ContainerDamageTypes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/ReSync/ContainerTerminalPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/ReSync/ContainerYardPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/ReSync/Customers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/ReSync/Packages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/ReSync`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/ReSync/RailTracks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/ReSync/Tenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/ReSync/WorkInstructions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ReSyncApi - functional programming interface
 * @export
 */
export const ReSyncApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReSyncApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async berths(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.berths(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commodities(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commodities(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageLocations(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageLocations(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageTypes(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageTypes(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerTerminalPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerTerminalPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerYardPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerYardPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customers(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customers(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packages(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packages(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async railTracks(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.railTracks(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenants(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenants(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workInstructions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workInstructions(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ReSyncApi - factory interface
 * @export
 */
export const ReSyncApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ReSyncApiFp(configuration)
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.berths(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.commodities(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageLocations(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageTypes(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerTerminalPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerYardPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.customers(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.packages(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.post(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.railTracks(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.tenants(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.workInstructions(from, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * ReSyncApi - object-oriented interface
 * @export
 * @class ReSyncApi
 * @extends {BaseAPI}
 */
export class ReSyncApi extends BaseAPI {
  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReSyncApi
   */
  public berths(from?: string, options?: AxiosRequestConfig) {
    return ReSyncApiFp(this.configuration)
      .berths(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReSyncApi
   */
  public commodities(from?: string, options?: AxiosRequestConfig) {
    return ReSyncApiFp(this.configuration)
      .commodities(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReSyncApi
   */
  public containerDamageLocations(from?: string, options?: AxiosRequestConfig) {
    return ReSyncApiFp(this.configuration)
      .containerDamageLocations(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReSyncApi
   */
  public containerDamageTypes(from?: string, options?: AxiosRequestConfig) {
    return ReSyncApiFp(this.configuration)
      .containerDamageTypes(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReSyncApi
   */
  public containerTerminalPositions(from?: string, options?: AxiosRequestConfig) {
    return ReSyncApiFp(this.configuration)
      .containerTerminalPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReSyncApi
   */
  public containerYardPositions(from?: string, options?: AxiosRequestConfig) {
    return ReSyncApiFp(this.configuration)
      .containerYardPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReSyncApi
   */
  public customers(from?: string, options?: AxiosRequestConfig) {
    return ReSyncApiFp(this.configuration)
      .customers(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReSyncApi
   */
  public packages(from?: string, options?: AxiosRequestConfig) {
    return ReSyncApiFp(this.configuration)
      .packages(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReSyncApi
   */
  public post(from?: string, options?: AxiosRequestConfig) {
    return ReSyncApiFp(this.configuration)
      .post(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReSyncApi
   */
  public railTracks(from?: string, options?: AxiosRequestConfig) {
    return ReSyncApiFp(this.configuration)
      .railTracks(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReSyncApi
   */
  public tenants(from?: string, options?: AxiosRequestConfig) {
    return ReSyncApiFp(this.configuration)
      .tenants(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReSyncApi
   */
  public workInstructions(from?: string, options?: AxiosRequestConfig) {
    return ReSyncApiFp(this.configuration)
      .workInstructions(from, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * ServiceOrdersApi - axios parameter creator
 * @export
 */
export const ServiceOrdersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateCoolingOrderCommand} [createCoolingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coolingOrder: async (
      createCoolingOrderCommand?: CreateCoolingOrderCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/ServiceOrders/CoolingOrder`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCoolingOrderCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCoolingOrder: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteCoolingOrder', 'id', id)
      const localVarPath = `/planning/api/ServiceOrders/CoolingOrder/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStrippingOrder: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteStrippingOrder', 'id', id)
      const localVarPath = `/planning/api/ServiceOrders/StrippingOrder/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStuffingOrder: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteStuffingOrder', 'id', id)
      const localVarPath = `/planning/api/ServiceOrders/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds: async (
      ids: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ids' is not null or undefined
      assertParamExists('getByIds', 'ids', ids)
      const localVarPath = `/planning/api/ServiceOrders/GetByIds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (ids) {
        localVarQueryParameter['Ids'] = ids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCoolingOrdersByIds: async (
      ids: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ids' is not null or undefined
      assertParamExists('getCoolingOrdersByIds', 'ids', ids)
      const localVarPath = `/planning/api/ServiceOrders/GetCoolingOrdersByIds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (ids) {
        localVarQueryParameter['Ids'] = ids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStrippingOrdersByIds: async (
      ids: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ids' is not null or undefined
      assertParamExists('getStrippingOrdersByIds', 'ids', ids)
      const localVarPath = `/planning/api/ServiceOrders/GetStrippingOrdersByIds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (ids) {
        localVarQueryParameter['Ids'] = ids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateStrippingOrderCommand} [createStrippingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    strippingOrder: async (
      createStrippingOrderCommand?: CreateStrippingOrderCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/ServiceOrders/StrippingOrder`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createStrippingOrderCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateStuffingOrderCommand} [createStuffingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stuffingOrder: async (
      createStuffingOrderCommand?: CreateStuffingOrderCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/ServiceOrders/StuffingOrder`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createStuffingOrderCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateCargoItemActualAmountCommand} [updateCargoItemActualAmountCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCargoItemActualAmount: async (
      updateCargoItemActualAmountCommand?: UpdateCargoItemActualAmountCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/ServiceOrders/UpdateCargoItemActualAmount`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCargoItemActualAmountCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateCoolingOrderCommand} [updateCoolingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCoolingOrder: async (
      updateCoolingOrderCommand?: UpdateCoolingOrderCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/ServiceOrders/UpdateCoolingOrder`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCoolingOrderCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateStrippingOrderCommand} [updateStrippingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStrippingOrder: async (
      updateStrippingOrderCommand?: UpdateStrippingOrderCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/ServiceOrders/UpdateStrippingOrder`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateStrippingOrderCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateStrippingOrderCargoItemActualAmountCommand} [updateStrippingOrderCargoItemActualAmountCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStrippingOrderCargoItemActualAmount: async (
      updateStrippingOrderCargoItemActualAmountCommand?: UpdateStrippingOrderCargoItemActualAmountCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/ServiceOrders/UpdateStrippingOrderCargoItemActualAmount`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateStrippingOrderCargoItemActualAmountCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateStuffingOrderCommand} [updateStuffingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStuffingOrder: async (
      updateStuffingOrderCommand?: UpdateStuffingOrderCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/ServiceOrders/UpdateStuffingOrder`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateStuffingOrderCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ServiceOrdersApi - functional programming interface
 * @export
 */
export const ServiceOrdersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ServiceOrdersApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CreateCoolingOrderCommand} [createCoolingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async coolingOrder(
      createCoolingOrderCommand?: CreateCoolingOrderCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.coolingOrder(
        createCoolingOrderCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCoolingOrder(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCoolingOrder(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteStrippingOrder(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStrippingOrder(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteStuffingOrder(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStuffingOrder(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByIds(
      ids: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StuffingOrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByIds(ids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCoolingOrdersByIds(
      ids: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoolingOrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCoolingOrdersByIds(ids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStrippingOrdersByIds(
      ids: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StrippingOrderResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStrippingOrdersByIds(
        ids,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateStrippingOrderCommand} [createStrippingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async strippingOrder(
      createStrippingOrderCommand?: CreateStrippingOrderCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.strippingOrder(
        createStrippingOrderCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateStuffingOrderCommand} [createStuffingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stuffingOrder(
      createStuffingOrderCommand?: CreateStuffingOrderCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.stuffingOrder(
        createStuffingOrderCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateCargoItemActualAmountCommand} [updateCargoItemActualAmountCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCargoItemActualAmount(
      updateCargoItemActualAmountCommand?: UpdateCargoItemActualAmountCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCargoItemActualAmount(
        updateCargoItemActualAmountCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateCoolingOrderCommand} [updateCoolingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCoolingOrder(
      updateCoolingOrderCommand?: UpdateCoolingOrderCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCoolingOrder(
        updateCoolingOrderCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateStrippingOrderCommand} [updateStrippingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStrippingOrder(
      updateStrippingOrderCommand?: UpdateStrippingOrderCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateStrippingOrder(
        updateStrippingOrderCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateStrippingOrderCargoItemActualAmountCommand} [updateStrippingOrderCargoItemActualAmountCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStrippingOrderCargoItemActualAmount(
      updateStrippingOrderCargoItemActualAmountCommand?: UpdateStrippingOrderCargoItemActualAmountCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateStrippingOrderCargoItemActualAmount(
          updateStrippingOrderCargoItemActualAmountCommand,
          options,
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateStuffingOrderCommand} [updateStuffingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStuffingOrder(
      updateStuffingOrderCommand?: UpdateStuffingOrderCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateStuffingOrder(
        updateStuffingOrderCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ServiceOrdersApi - factory interface
 * @export
 */
export const ServiceOrdersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ServiceOrdersApiFp(configuration)
  return {
    /**
     *
     * @param {CreateCoolingOrderCommand} [createCoolingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coolingOrder(
      createCoolingOrderCommand?: CreateCoolingOrderCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .coolingOrder(createCoolingOrderCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCoolingOrder(id: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.deleteCoolingOrder(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStrippingOrder(id: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.deleteStrippingOrder(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStuffingOrder(id: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.deleteStuffingOrder(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds(ids: Array<number>, options?: any): AxiosPromise<Array<StuffingOrderResponseDto>> {
      return localVarFp.getByIds(ids, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCoolingOrdersByIds(
      ids: Array<number>,
      options?: any,
    ): AxiosPromise<Array<CoolingOrderResponseDto>> {
      return localVarFp
        .getCoolingOrdersByIds(ids, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStrippingOrdersByIds(
      ids: Array<number>,
      options?: any,
    ): AxiosPromise<Array<StrippingOrderResponseDto>> {
      return localVarFp
        .getStrippingOrdersByIds(ids, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateStrippingOrderCommand} [createStrippingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    strippingOrder(
      createStrippingOrderCommand?: CreateStrippingOrderCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .strippingOrder(createStrippingOrderCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateStuffingOrderCommand} [createStuffingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stuffingOrder(
      createStuffingOrderCommand?: CreateStuffingOrderCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .stuffingOrder(createStuffingOrderCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateCargoItemActualAmountCommand} [updateCargoItemActualAmountCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCargoItemActualAmount(
      updateCargoItemActualAmountCommand?: UpdateCargoItemActualAmountCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateCargoItemActualAmount(updateCargoItemActualAmountCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateCoolingOrderCommand} [updateCoolingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCoolingOrder(
      updateCoolingOrderCommand?: UpdateCoolingOrderCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateCoolingOrder(updateCoolingOrderCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateStrippingOrderCommand} [updateStrippingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStrippingOrder(
      updateStrippingOrderCommand?: UpdateStrippingOrderCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateStrippingOrder(updateStrippingOrderCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateStrippingOrderCargoItemActualAmountCommand} [updateStrippingOrderCargoItemActualAmountCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStrippingOrderCargoItemActualAmount(
      updateStrippingOrderCargoItemActualAmountCommand?: UpdateStrippingOrderCargoItemActualAmountCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateStrippingOrderCargoItemActualAmount(
          updateStrippingOrderCargoItemActualAmountCommand,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateStuffingOrderCommand} [updateStuffingOrderCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStuffingOrder(
      updateStuffingOrderCommand?: UpdateStuffingOrderCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateStuffingOrder(updateStuffingOrderCommand, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * ServiceOrdersApi - object-oriented interface
 * @export
 * @class ServiceOrdersApi
 * @extends {BaseAPI}
 */
export class ServiceOrdersApi extends BaseAPI {
  /**
   *
   * @param {CreateCoolingOrderCommand} [createCoolingOrderCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public coolingOrder(
    createCoolingOrderCommand?: CreateCoolingOrderCommand,
    options?: AxiosRequestConfig,
  ) {
    return ServiceOrdersApiFp(this.configuration)
      .coolingOrder(createCoolingOrderCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public deleteCoolingOrder(id: number, options?: AxiosRequestConfig) {
    return ServiceOrdersApiFp(this.configuration)
      .deleteCoolingOrder(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public deleteStrippingOrder(id: number, options?: AxiosRequestConfig) {
    return ServiceOrdersApiFp(this.configuration)
      .deleteStrippingOrder(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public deleteStuffingOrder(id: number, options?: AxiosRequestConfig) {
    return ServiceOrdersApiFp(this.configuration)
      .deleteStuffingOrder(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Array<number>} ids
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public getByIds(ids: Array<number>, options?: AxiosRequestConfig) {
    return ServiceOrdersApiFp(this.configuration)
      .getByIds(ids, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Array<number>} ids
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public getCoolingOrdersByIds(ids: Array<number>, options?: AxiosRequestConfig) {
    return ServiceOrdersApiFp(this.configuration)
      .getCoolingOrdersByIds(ids, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Array<number>} ids
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public getStrippingOrdersByIds(ids: Array<number>, options?: AxiosRequestConfig) {
    return ServiceOrdersApiFp(this.configuration)
      .getStrippingOrdersByIds(ids, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateStrippingOrderCommand} [createStrippingOrderCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public strippingOrder(
    createStrippingOrderCommand?: CreateStrippingOrderCommand,
    options?: AxiosRequestConfig,
  ) {
    return ServiceOrdersApiFp(this.configuration)
      .strippingOrder(createStrippingOrderCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateStuffingOrderCommand} [createStuffingOrderCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public stuffingOrder(
    createStuffingOrderCommand?: CreateStuffingOrderCommand,
    options?: AxiosRequestConfig,
  ) {
    return ServiceOrdersApiFp(this.configuration)
      .stuffingOrder(createStuffingOrderCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateCargoItemActualAmountCommand} [updateCargoItemActualAmountCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public updateCargoItemActualAmount(
    updateCargoItemActualAmountCommand?: UpdateCargoItemActualAmountCommand,
    options?: AxiosRequestConfig,
  ) {
    return ServiceOrdersApiFp(this.configuration)
      .updateCargoItemActualAmount(updateCargoItemActualAmountCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateCoolingOrderCommand} [updateCoolingOrderCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public updateCoolingOrder(
    updateCoolingOrderCommand?: UpdateCoolingOrderCommand,
    options?: AxiosRequestConfig,
  ) {
    return ServiceOrdersApiFp(this.configuration)
      .updateCoolingOrder(updateCoolingOrderCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateStrippingOrderCommand} [updateStrippingOrderCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public updateStrippingOrder(
    updateStrippingOrderCommand?: UpdateStrippingOrderCommand,
    options?: AxiosRequestConfig,
  ) {
    return ServiceOrdersApiFp(this.configuration)
      .updateStrippingOrder(updateStrippingOrderCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateStrippingOrderCargoItemActualAmountCommand} [updateStrippingOrderCargoItemActualAmountCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public updateStrippingOrderCargoItemActualAmount(
    updateStrippingOrderCargoItemActualAmountCommand?: UpdateStrippingOrderCargoItemActualAmountCommand,
    options?: AxiosRequestConfig,
  ) {
    return ServiceOrdersApiFp(this.configuration)
      .updateStrippingOrderCargoItemActualAmount(
        updateStrippingOrderCargoItemActualAmountCommand,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateStuffingOrderCommand} [updateStuffingOrderCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceOrdersApi
   */
  public updateStuffingOrder(
    updateStuffingOrderCommand?: UpdateStuffingOrderCommand,
    options?: AxiosRequestConfig,
  ) {
    return ServiceOrdersApiFp(this.configuration)
      .updateStuffingOrder(updateStuffingOrderCommand, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * SyncDataApi - axios parameter creator
 * @export
 */
export const SyncDataApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containers: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/sync/Containers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coolingOrders: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/sync/CoolingOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orders: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/sync/Orders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railVisits: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/sync/RailVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railcarTrackPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/sync/RailcarTrackPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    strippingOrders: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/sync/StrippingOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stuffingOrders: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/sync/StuffingOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    truckVisits: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/sync/TruckVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vesselVisits: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/internal/sync/VesselVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SyncDataApi - functional programming interface
 * @export
 */
export const SyncDataApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SyncDataApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containers(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncContainersDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containers(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async coolingOrders(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncCoolingOrdersDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.coolingOrders(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orders(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncOrdersDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.orders(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async railVisits(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncRailVisitsDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.railVisits(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async railcarTrackPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncRailcarTrackPositionsDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.railcarTrackPositions(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async strippingOrders(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncStrippingOrdersDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.strippingOrders(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stuffingOrders(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncStuffingOrdersDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.stuffingOrders(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async truckVisits(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncTruckVisitsDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.truckVisits(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async vesselVisits(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncVesselVisitsDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.vesselVisits(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SyncDataApi - factory interface
 * @export
 */
export const SyncDataApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SyncDataApiFp(configuration)
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containers(from?: string, options?: any): AxiosPromise<SyncContainersDto> {
      return localVarFp.containers(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coolingOrders(from?: string, options?: any): AxiosPromise<SyncCoolingOrdersDto> {
      return localVarFp.coolingOrders(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orders(from?: string, options?: any): AxiosPromise<SyncOrdersDto> {
      return localVarFp.orders(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railVisits(from?: string, options?: any): AxiosPromise<SyncRailVisitsDto> {
      return localVarFp.railVisits(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railcarTrackPositions(
      from?: string,
      options?: any,
    ): AxiosPromise<SyncRailcarTrackPositionsDto> {
      return localVarFp
        .railcarTrackPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    strippingOrders(from?: string, options?: any): AxiosPromise<SyncStrippingOrdersDto> {
      return localVarFp.strippingOrders(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stuffingOrders(from?: string, options?: any): AxiosPromise<SyncStuffingOrdersDto> {
      return localVarFp.stuffingOrders(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    truckVisits(from?: string, options?: any): AxiosPromise<SyncTruckVisitsDto> {
      return localVarFp.truckVisits(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vesselVisits(from?: string, options?: any): AxiosPromise<SyncVesselVisitsDto> {
      return localVarFp.vesselVisits(from, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * SyncDataApi - object-oriented interface
 * @export
 * @class SyncDataApi
 * @extends {BaseAPI}
 */
export class SyncDataApi extends BaseAPI {
  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncDataApi
   */
  public containers(from?: string, options?: AxiosRequestConfig) {
    return SyncDataApiFp(this.configuration)
      .containers(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncDataApi
   */
  public coolingOrders(from?: string, options?: AxiosRequestConfig) {
    return SyncDataApiFp(this.configuration)
      .coolingOrders(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncDataApi
   */
  public orders(from?: string, options?: AxiosRequestConfig) {
    return SyncDataApiFp(this.configuration)
      .orders(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncDataApi
   */
  public railVisits(from?: string, options?: AxiosRequestConfig) {
    return SyncDataApiFp(this.configuration)
      .railVisits(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncDataApi
   */
  public railcarTrackPositions(from?: string, options?: AxiosRequestConfig) {
    return SyncDataApiFp(this.configuration)
      .railcarTrackPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncDataApi
   */
  public strippingOrders(from?: string, options?: AxiosRequestConfig) {
    return SyncDataApiFp(this.configuration)
      .strippingOrders(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncDataApi
   */
  public stuffingOrders(from?: string, options?: AxiosRequestConfig) {
    return SyncDataApiFp(this.configuration)
      .stuffingOrders(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncDataApi
   */
  public truckVisits(from?: string, options?: AxiosRequestConfig) {
    return SyncDataApiFp(this.configuration)
      .truckVisits(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncDataApi
   */
  public vesselVisits(from?: string, options?: AxiosRequestConfig) {
    return SyncDataApiFp(this.configuration)
      .vesselVisits(from, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * TenantsApi - axios parameter creator
 * @export
 */
export const TenantsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Tenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TenantsApi - functional programming interface
 * @export
 */
export const TenantsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TenantsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TenantsApi - factory interface
 * @export
 */
export const TenantsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TenantsApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options?: any): AxiosPromise<TenantResponseDto> {
      return localVarFp.get(options).then(request => request(axios, basePath))
    },
  }
}

/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
export class TenantsApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public get(options?: AxiosRequestConfig) {
    return TenantsApiFp(this.configuration)
      .get(options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * TestSyncApi - axios parameter creator
 * @export
 */
export const TestSyncApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TestSync/All`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TestSync/Berths`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TestSync/Commodities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TestSync/ContainerDamageLocations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TestSync/ContainerDamageTypes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TestSync/ContainerTerminalPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TestSync/ContainerYardPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TestSync/Customers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TestSync/Packages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TestSync/RailTracks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TestSync/Tenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TestSync/WorkInstructions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TestSyncApi - functional programming interface
 * @export
 */
export const TestSyncApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TestSyncApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async all(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.all(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async berths(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.berths(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commodities(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commodities(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageLocations(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageLocations(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageTypes(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageTypes(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerTerminalPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerTerminalPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerYardPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerYardPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customers(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customers(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packages(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packages(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async railTracks(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.railTracks(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenants(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenants(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workInstructions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workInstructions(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TestSyncApi - factory interface
 * @export
 */
export const TestSyncApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TestSyncApiFp(configuration)
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.all(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.berths(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.commodities(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageLocations(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageTypes(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerTerminalPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerYardPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.customers(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.packages(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.railTracks(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.tenants(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.workInstructions(from, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * TestSyncApi - object-oriented interface
 * @export
 * @class TestSyncApi
 * @extends {BaseAPI}
 */
export class TestSyncApi extends BaseAPI {
  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestSyncApi
   */
  public all(from?: string, options?: AxiosRequestConfig) {
    return TestSyncApiFp(this.configuration)
      .all(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestSyncApi
   */
  public berths(from?: string, options?: AxiosRequestConfig) {
    return TestSyncApiFp(this.configuration)
      .berths(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestSyncApi
   */
  public commodities(from?: string, options?: AxiosRequestConfig) {
    return TestSyncApiFp(this.configuration)
      .commodities(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestSyncApi
   */
  public containerDamageLocations(from?: string, options?: AxiosRequestConfig) {
    return TestSyncApiFp(this.configuration)
      .containerDamageLocations(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestSyncApi
   */
  public containerDamageTypes(from?: string, options?: AxiosRequestConfig) {
    return TestSyncApiFp(this.configuration)
      .containerDamageTypes(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestSyncApi
   */
  public containerTerminalPositions(from?: string, options?: AxiosRequestConfig) {
    return TestSyncApiFp(this.configuration)
      .containerTerminalPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestSyncApi
   */
  public containerYardPositions(from?: string, options?: AxiosRequestConfig) {
    return TestSyncApiFp(this.configuration)
      .containerYardPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestSyncApi
   */
  public customers(from?: string, options?: AxiosRequestConfig) {
    return TestSyncApiFp(this.configuration)
      .customers(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestSyncApi
   */
  public packages(from?: string, options?: AxiosRequestConfig) {
    return TestSyncApiFp(this.configuration)
      .packages(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestSyncApi
   */
  public railTracks(from?: string, options?: AxiosRequestConfig) {
    return TestSyncApiFp(this.configuration)
      .railTracks(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestSyncApi
   */
  public tenants(from?: string, options?: AxiosRequestConfig) {
    return TestSyncApiFp(this.configuration)
      .tenants(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestSyncApi
   */
  public workInstructions(from?: string, options?: AxiosRequestConfig) {
    return TestSyncApiFp(this.configuration)
      .workInstructions(from, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * TruckAppointmentsApi - axios parameter creator
 * @export
 */
export const TruckAppointmentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {GetTruckAppointmentsQueryType} [type]
     * @param {CarrierVisitStatus} [status]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      page: number,
      pageSize: number,
      type?: GetTruckAppointmentsQueryType,
      status?: CarrierVisitStatus,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('get', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('get', 'pageSize', pageSize)
      const localVarPath = `/planning/api/TruckAppointments`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status
      }

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateTruckAppointmentCommand} [createTruckAppointmentCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (
      createTruckAppointmentCommand?: CreateTruckAppointmentCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckAppointments`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTruckAppointmentCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateTruckAppointmentCommand} [updateTruckAppointmentCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put: async (
      updateTruckAppointmentCommand?: UpdateTruckAppointmentCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckAppointments`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTruckAppointmentCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    remove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('remove', 'id', id)
      const localVarPath = `/planning/api/TruckAppointments/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TruckAppointmentsApi - functional programming interface
 * @export
 */
export const TruckAppointmentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TruckAppointmentsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {GetTruckAppointmentsQueryType} [type]
     * @param {CarrierVisitStatus} [status]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      page: number,
      pageSize: number,
      type?: GetTruckAppointmentsQueryType,
      status?: CarrierVisitStatus,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckAppointmentDtoPaginatedList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        page,
        pageSize,
        type,
        status,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateTruckAppointmentCommand} [createTruckAppointmentCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(
      createTruckAppointmentCommand?: CreateTruckAppointmentCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckAppointmentDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(
        createTruckAppointmentCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateTruckAppointmentCommand} [updateTruckAppointmentCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async put(
      updateTruckAppointmentCommand?: UpdateTruckAppointmentCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckAppointmentDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.put(
        updateTruckAppointmentCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async remove(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TruckAppointmentsApi - factory interface
 * @export
 */
export const TruckAppointmentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TruckAppointmentsApiFp(configuration)
  return {
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {GetTruckAppointmentsQueryType} [type]
     * @param {CarrierVisitStatus} [status]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      page: number,
      pageSize: number,
      type?: GetTruckAppointmentsQueryType,
      status?: CarrierVisitStatus,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<TruckAppointmentDtoPaginatedList> {
      return localVarFp
        .get(
          page,
          pageSize,
          type,
          status,
          filter,
          includeCompleted,
          orderBy,
          isDescending,
          from,
          to,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateTruckAppointmentCommand} [createTruckAppointmentCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(
      createTruckAppointmentCommand?: CreateTruckAppointmentCommand,
      options?: any,
    ): AxiosPromise<TruckAppointmentDto> {
      return localVarFp
        .post(createTruckAppointmentCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateTruckAppointmentCommand} [updateTruckAppointmentCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put(
      updateTruckAppointmentCommand?: UpdateTruckAppointmentCommand,
      options?: any,
    ): AxiosPromise<TruckAppointmentDto> {
      return localVarFp
        .put(updateTruckAppointmentCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    remove(id: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.remove(id, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * TruckAppointmentsApi - object-oriented interface
 * @export
 * @class TruckAppointmentsApi
 * @extends {BaseAPI}
 */
export class TruckAppointmentsApi extends BaseAPI {
  /**
   *
   * @param {number} page
   * @param {number} pageSize
   * @param {GetTruckAppointmentsQueryType} [type]
   * @param {CarrierVisitStatus} [status]
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckAppointmentsApi
   */
  public get(
    page: number,
    pageSize: number,
    type?: GetTruckAppointmentsQueryType,
    status?: CarrierVisitStatus,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return TruckAppointmentsApiFp(this.configuration)
      .get(
        page,
        pageSize,
        type,
        status,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateTruckAppointmentCommand} [createTruckAppointmentCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckAppointmentsApi
   */
  public post(
    createTruckAppointmentCommand?: CreateTruckAppointmentCommand,
    options?: AxiosRequestConfig,
  ) {
    return TruckAppointmentsApiFp(this.configuration)
      .post(createTruckAppointmentCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateTruckAppointmentCommand} [updateTruckAppointmentCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckAppointmentsApi
   */
  public put(
    updateTruckAppointmentCommand?: UpdateTruckAppointmentCommand,
    options?: AxiosRequestConfig,
  ) {
    return TruckAppointmentsApiFp(this.configuration)
      .put(updateTruckAppointmentCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckAppointmentsApi
   */
  public remove(id: number, options?: AxiosRequestConfig) {
    return TruckAppointmentsApiFp(this.configuration)
      .remove(id, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * TruckVisitsApi - axios parameter creator
 * @export
 */
export const TruckVisitsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits/All`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {AllowTruckVisitEntryCommand} [allowTruckVisitEntryCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allowTruckVisitEntry: async (
      id: number,
      allowTruckVisitEntryCommand?: AllowTruckVisitEntryCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('allowTruckVisitEntry', 'id', id)
      const localVarPath = `/planning/api/TruckVisits/{id}/AllowTruckVisitEntry`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        allowTruckVisitEntryCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {AllowTruckVisitExitCommand} [allowTruckVisitExitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allowTruckVisitExit: async (
      id: number,
      allowTruckVisitExitCommand?: AllowTruckVisitExitCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('allowTruckVisitExit', 'id', id)
      const localVarPath = `/planning/api/TruckVisits/{id}/AllowTruckVisitExit`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        allowTruckVisitExitCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits/Berths`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits/Commodities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits/ContainerDamageLocations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits/ContainerDamageTypes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits/ContainerTerminalPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits/ContainerYardPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateTruckVisitCommand} [createTruckVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createTruckVisitCommand?: CreateTruckVisitCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTruckVisitCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits/Customers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierVisitStatus} [status]
     * @param {string} [filterBy]
     * @param {boolean} [isOnTerminal]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      page: number,
      pageSize: number,
      status?: CarrierVisitStatus,
      filterBy?: string,
      isOnTerminal?: boolean,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('get', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('get', 'pageSize', pageSize)
      const localVarPath = `/planning/api/TruckVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status
      }

      if (filterBy !== undefined) {
        localVarQueryParameter['FilterBy'] = filterBy
      }

      if (isOnTerminal !== undefined) {
        localVarQueryParameter['IsOnTerminal'] = isOnTerminal
      }

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<number>} [ids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds: async (
      ids?: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits/GetByIds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (ids) {
        localVarQueryParameter['Ids'] = ids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits/Packages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits/RailTracks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    syncData: async (
      from: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      assertParamExists('syncData', 'from', from)
      const localVarPath = `/planning/api/TruckVisits/SyncData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits/Tenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/TruckVisits/WorkInstructions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TruckVisitsApi - functional programming interface
 * @export
 */
export const TruckVisitsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TruckVisitsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async all(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.all(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {AllowTruckVisitEntryCommand} [allowTruckVisitEntryCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async allowTruckVisitEntry(
      id: number,
      allowTruckVisitEntryCommand?: AllowTruckVisitEntryCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.allowTruckVisitEntry(
        id,
        allowTruckVisitEntryCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {AllowTruckVisitExitCommand} [allowTruckVisitExitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async allowTruckVisitExit(
      id: number,
      allowTruckVisitExitCommand?: AllowTruckVisitExitCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.allowTruckVisitExit(
        id,
        allowTruckVisitExitCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async berths(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.berths(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commodities(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commodities(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageLocations(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageLocations(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageTypes(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageTypes(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerTerminalPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerTerminalPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerYardPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerYardPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateTruckVisitCommand} [createTruckVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createTruckVisitCommand?: CreateTruckVisitCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckVisitDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(
        createTruckVisitCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customers(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customers(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierVisitStatus} [status]
     * @param {string} [filterBy]
     * @param {boolean} [isOnTerminal]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      page: number,
      pageSize: number,
      status?: CarrierVisitStatus,
      filterBy?: string,
      isOnTerminal?: boolean,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckVisitDtoPaginatedList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        page,
        pageSize,
        status,
        filterBy,
        isOnTerminal,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {Array<number>} [ids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByIds(
      ids?: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TruckVisitDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByIds(ids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packages(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packages(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async railTracks(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.railTracks(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async syncData(
      from: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TruckVisitDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncData(from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenants(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenants(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workInstructions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workInstructions(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TruckVisitsApi - factory interface
 * @export
 */
export const TruckVisitsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TruckVisitsApiFp(configuration)
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.all(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {AllowTruckVisitEntryCommand} [allowTruckVisitEntryCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allowTruckVisitEntry(
      id: number,
      allowTruckVisitEntryCommand?: AllowTruckVisitEntryCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .allowTruckVisitEntry(id, allowTruckVisitEntryCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {AllowTruckVisitExitCommand} [allowTruckVisitExitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allowTruckVisitExit(
      id: number,
      allowTruckVisitExitCommand?: AllowTruckVisitExitCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .allowTruckVisitExit(id, allowTruckVisitExitCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.berths(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.commodities(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageLocations(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageTypes(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerTerminalPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerYardPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateTruckVisitCommand} [createTruckVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      createTruckVisitCommand?: CreateTruckVisitCommand,
      options?: any,
    ): AxiosPromise<TruckVisitDto> {
      return localVarFp
        .create(createTruckVisitCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.customers(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierVisitStatus} [status]
     * @param {string} [filterBy]
     * @param {boolean} [isOnTerminal]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      page: number,
      pageSize: number,
      status?: CarrierVisitStatus,
      filterBy?: string,
      isOnTerminal?: boolean,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<TruckVisitDtoPaginatedList> {
      return localVarFp
        .get(
          page,
          pageSize,
          status,
          filterBy,
          isOnTerminal,
          filter,
          includeCompleted,
          orderBy,
          isDescending,
          from,
          to,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {Array<number>} [ids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds(ids?: Array<number>, options?: any): AxiosPromise<Array<TruckVisitDto>> {
      return localVarFp.getByIds(ids, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.packages(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.railTracks(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    syncData(from: string, to?: string, options?: any): AxiosPromise<Array<TruckVisitDto>> {
      return localVarFp.syncData(from, to, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.tenants(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.workInstructions(from, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * TruckVisitsApi - object-oriented interface
 * @export
 * @class TruckVisitsApi
 * @extends {BaseAPI}
 */
export class TruckVisitsApi extends BaseAPI {
  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public all(from?: string, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .all(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {AllowTruckVisitEntryCommand} [allowTruckVisitEntryCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public allowTruckVisitEntry(
    id: number,
    allowTruckVisitEntryCommand?: AllowTruckVisitEntryCommand,
    options?: AxiosRequestConfig,
  ) {
    return TruckVisitsApiFp(this.configuration)
      .allowTruckVisitEntry(id, allowTruckVisitEntryCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {AllowTruckVisitExitCommand} [allowTruckVisitExitCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public allowTruckVisitExit(
    id: number,
    allowTruckVisitExitCommand?: AllowTruckVisitExitCommand,
    options?: AxiosRequestConfig,
  ) {
    return TruckVisitsApiFp(this.configuration)
      .allowTruckVisitExit(id, allowTruckVisitExitCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public berths(from?: string, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .berths(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public commodities(from?: string, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .commodities(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public containerDamageLocations(from?: string, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .containerDamageLocations(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public containerDamageTypes(from?: string, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .containerDamageTypes(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public containerTerminalPositions(from?: string, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .containerTerminalPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public containerYardPositions(from?: string, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .containerYardPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateTruckVisitCommand} [createTruckVisitCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public create(createTruckVisitCommand?: CreateTruckVisitCommand, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .create(createTruckVisitCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public customers(from?: string, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .customers(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} page
   * @param {number} pageSize
   * @param {CarrierVisitStatus} [status]
   * @param {string} [filterBy]
   * @param {boolean} [isOnTerminal]
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public get(
    page: number,
    pageSize: number,
    status?: CarrierVisitStatus,
    filterBy?: string,
    isOnTerminal?: boolean,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return TruckVisitsApiFp(this.configuration)
      .get(
        page,
        pageSize,
        status,
        filterBy,
        isOnTerminal,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Array<number>} [ids]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public getByIds(ids?: Array<number>, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .getByIds(ids, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public packages(from?: string, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .packages(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public railTracks(from?: string, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .railTracks(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} from
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public syncData(from: string, to?: string, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .syncData(from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public tenants(from?: string, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .tenants(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TruckVisitsApi
   */
  public workInstructions(from?: string, options?: AxiosRequestConfig) {
    return TruckVisitsApiFp(this.configuration)
      .workInstructions(from, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * TrucksApi - axios parameter creator
 * @export
 */
export const TrucksApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id)
      const localVarPath = `/planning/api/Trucks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Trucks/All`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Trucks/Berths`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Trucks/Commodities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Trucks/ContainerDamageLocations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Trucks/ContainerDamageTypes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Trucks/ContainerTerminalPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Trucks/ContainerYardPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Trucks/Customers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      page: number,
      pageSize: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('get', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('get', 'pageSize', pageSize)
      const localVarPath = `/planning/api/Trucks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getById', 'id', id)
      const localVarPath = `/planning/api/Trucks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Trucks/Packages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateTruckCommand} [createTruckCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (
      createTruckCommand?: CreateTruckCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Trucks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTruckCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {UpdateTruckCommand} [updateTruckCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put: async (
      id: number,
      updateTruckCommand?: UpdateTruckCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('put', 'id', id)
      const localVarPath = `/planning/api/Trucks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTruckCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Trucks/RailTracks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    syncData: async (
      from: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      assertParamExists('syncData', 'from', from)
      const localVarPath = `/planning/api/Trucks/SyncData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Trucks/Tenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Trucks/WorkInstructions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TrucksApi - functional programming interface
 * @export
 */
export const TrucksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TrucksApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async all(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.all(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async berths(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.berths(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commodities(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commodities(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageLocations(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageLocations(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageTypes(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageTypes(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerTerminalPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerTerminalPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerYardPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerYardPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customers(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customers(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      page: number,
      pageSize: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckDtoPaginatedList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        page,
        pageSize,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getById(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packages(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packages(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateTruckCommand} [createTruckCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(
      createTruckCommand?: CreateTruckCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(createTruckCommand, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {UpdateTruckCommand} [updateTruckCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async put(
      id: number,
      updateTruckCommand?: UpdateTruckCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.put(id, updateTruckCommand, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async railTracks(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.railTracks(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async syncData(
      from: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TruckDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncData(from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenants(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenants(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workInstructions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workInstructions(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TrucksApi - factory interface
 * @export
 */
export const TrucksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TrucksApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<boolean> {
      return localVarFp._delete(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.all(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.berths(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.commodities(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageLocations(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageTypes(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerTerminalPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerYardPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.customers(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      page: number,
      pageSize: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<TruckDtoPaginatedList> {
      return localVarFp
        .get(page, pageSize, filter, includeCompleted, orderBy, isDescending, from, to, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(id: number, options?: any): AxiosPromise<TruckDto> {
      return localVarFp.getById(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.packages(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateTruckCommand} [createTruckCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(createTruckCommand?: CreateTruckCommand, options?: any): AxiosPromise<TruckDto> {
      return localVarFp.post(createTruckCommand, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {UpdateTruckCommand} [updateTruckCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put(
      id: number,
      updateTruckCommand?: UpdateTruckCommand,
      options?: any,
    ): AxiosPromise<TruckDto> {
      return localVarFp
        .put(id, updateTruckCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.railTracks(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    syncData(from: string, to?: string, options?: any): AxiosPromise<Array<TruckDto>> {
      return localVarFp.syncData(from, to, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.tenants(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.workInstructions(from, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * TrucksApi - object-oriented interface
 * @export
 * @class TrucksApi
 * @extends {BaseAPI}
 */
export class TrucksApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public _delete(id: number, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      ._delete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public all(from?: string, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .all(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public berths(from?: string, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .berths(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public commodities(from?: string, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .commodities(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public containerDamageLocations(from?: string, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .containerDamageLocations(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public containerDamageTypes(from?: string, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .containerDamageTypes(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public containerTerminalPositions(from?: string, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .containerTerminalPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public containerYardPositions(from?: string, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .containerYardPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public customers(from?: string, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .customers(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} page
   * @param {number} pageSize
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public get(
    page: number,
    pageSize: number,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return TrucksApiFp(this.configuration)
      .get(page, pageSize, filter, includeCompleted, orderBy, isDescending, from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public getById(id: number, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .getById(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public packages(from?: string, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .packages(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateTruckCommand} [createTruckCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public post(createTruckCommand?: CreateTruckCommand, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .post(createTruckCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {UpdateTruckCommand} [updateTruckCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public put(id: number, updateTruckCommand?: UpdateTruckCommand, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .put(id, updateTruckCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public railTracks(from?: string, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .railTracks(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} from
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public syncData(from: string, to?: string, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .syncData(from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public tenants(from?: string, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .tenants(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrucksApi
   */
  public workInstructions(from?: string, options?: AxiosRequestConfig) {
    return TrucksApiFp(this.configuration)
      .workInstructions(from, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * VesselVisitsApi - axios parameter creator
 * @export
 */
export const VesselVisitsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('berths', 'id', id)
      const localVarPath = `/planning/api/VesselVisits/{id}/Berths`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateVesselVisitCommand} [createVesselVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createVesselVisitCommand?: CreateVesselVisitCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/VesselVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createVesselVisitCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [portUnlocode]
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVesselVisitDynamically: async (
      portUnlocode?: string,
      requestBody?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/VesselVisits/CreateVesselVisitsDynamically`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (portUnlocode !== undefined) {
        localVarQueryParameter['portUnlocode'] = portUnlocode
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierVisitStatus} [status]
     * @param {CargoType} [cargoType]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      page: number,
      pageSize: number,
      status?: CarrierVisitStatus,
      cargoType?: CargoType,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('get', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('get', 'pageSize', pageSize)
      const localVarPath = `/planning/api/VesselVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status
      }

      if (cargoType !== undefined) {
        localVarQueryParameter['CargoType'] = cargoType
      }

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getById', 'id', id)
      const localVarPath = `/planning/api/VesselVisits/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds: async (
      ids: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ids' is not null or undefined
      assertParamExists('getByIds', 'ids', ids)
      const localVarPath = `/planning/api/VesselVisits/GetByIds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (ids) {
        localVarQueryParameter['Ids'] = ids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    operationEnd: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('operationEnd', 'id', id)
      const localVarPath = `/planning/api/VesselVisits/{id}/OperationEnd`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    orders: async (
      upsertOrderListCommandV2?: UpsertOrderListCommandV2,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/VesselVisits/Orders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        upsertOrderListCommandV2,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateVesselVisitCommand} [updateVesselVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put: async (
      updateVesselVisitCommand?: UpdateVesselVisitCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/VesselVisits/Put`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateVesselVisitCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    sync: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/VesselVisits/sync`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {UpdateVesselVisitBerthsCommand} [updateVesselVisitBerthsCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBerths: async (
      id: number,
      updateVesselVisitBerthsCommand?: UpdateVesselVisitBerthsCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateBerths', 'id', id)
      const localVarPath = `/planning/api/VesselVisits/{id}/Berths`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateVesselVisitBerthsCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {UpdateVesselVisitStatusCommand} [updateVesselVisitStatusCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVesselVisitStatus: async (
      id: number,
      updateVesselVisitStatusCommand?: UpdateVesselVisitStatusCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateVesselVisitStatus', 'id', id)
      const localVarPath = `/planning/api/VesselVisits/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateVesselVisitStatusCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    upsertOrderList: async (
      upsertOrderListCommandV2?: UpsertOrderListCommandV2,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/VesselVisits/UpsertOrderListAsync`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        upsertOrderListCommandV2,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * VesselVisitsApi - functional programming interface
 * @export
 */
export const VesselVisitsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VesselVisitsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async berths(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BerthResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.berths(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateVesselVisitCommand} [createVesselVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createVesselVisitCommand?: CreateVesselVisitCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselVisitDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(
        createVesselVisitCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [portUnlocode]
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createVesselVisitDynamically(
      portUnlocode?: string,
      requestBody?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createVesselVisitDynamically(
        portUnlocode,
        requestBody,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierVisitStatus} [status]
     * @param {CargoType} [cargoType]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      page: number,
      pageSize: number,
      status?: CarrierVisitStatus,
      cargoType?: CargoType,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselVisitDtoPaginatedList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        page,
        pageSize,
        status,
        cargoType,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getById(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselVisitDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByIds(
      ids: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselVisitDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByIds(ids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async operationEnd(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.operationEnd(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async orders(
      upsertOrderListCommandV2?: UpsertOrderListCommandV2,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.orders(
        upsertOrderListCommandV2,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateVesselVisitCommand} [updateVesselVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async put(
      updateVesselVisitCommand?: UpdateVesselVisitCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselVisitUpdatedDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.put(
        updateVesselVisitCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async sync(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncVesselVisitsDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sync(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {UpdateVesselVisitBerthsCommand} [updateVesselVisitBerthsCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBerths(
      id: number,
      updateVesselVisitBerthsCommand?: UpdateVesselVisitBerthsCommand,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateVesselVisitBerthsDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBerths(
        id,
        updateVesselVisitBerthsCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {UpdateVesselVisitStatusCommand} [updateVesselVisitStatusCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateVesselVisitStatus(
      id: number,
      updateVesselVisitStatusCommand?: UpdateVesselVisitStatusCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateVesselVisitStatus(
        id,
        updateVesselVisitStatusCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async upsertOrderList(
      upsertOrderListCommandV2?: UpsertOrderListCommandV2,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertOrderList(
        upsertOrderListCommandV2,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * VesselVisitsApi - factory interface
 * @export
 */
export const VesselVisitsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = VesselVisitsApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths(id: number, options?: any): AxiosPromise<Array<BerthResponseDto>> {
      return localVarFp.berths(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateVesselVisitCommand} [createVesselVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      createVesselVisitCommand?: CreateVesselVisitCommand,
      options?: any,
    ): AxiosPromise<Array<VesselVisitDto>> {
      return localVarFp
        .create(createVesselVisitCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [portUnlocode]
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVesselVisitDynamically(
      portUnlocode?: string,
      requestBody?: Array<string>,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createVesselVisitDynamically(portUnlocode, requestBody, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {CarrierVisitStatus} [status]
     * @param {CargoType} [cargoType]
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      page: number,
      pageSize: number,
      status?: CarrierVisitStatus,
      cargoType?: CargoType,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<VesselVisitDtoPaginatedList> {
      return localVarFp
        .get(
          page,
          pageSize,
          status,
          cargoType,
          filter,
          includeCompleted,
          orderBy,
          isDescending,
          from,
          to,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(id: number, options?: any): AxiosPromise<VesselVisitDto> {
      return localVarFp.getById(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds(ids: Array<number>, options?: any): AxiosPromise<Array<VesselVisitDto>> {
      return localVarFp.getByIds(ids, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    operationEnd(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.operationEnd(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    orders(upsertOrderListCommandV2?: UpsertOrderListCommandV2, options?: any): AxiosPromise<void> {
      return localVarFp
        .orders(upsertOrderListCommandV2, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateVesselVisitCommand} [updateVesselVisitCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put(
      updateVesselVisitCommand?: UpdateVesselVisitCommand,
      options?: any,
    ): AxiosPromise<VesselVisitUpdatedDto> {
      return localVarFp
        .put(updateVesselVisitCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    sync(from?: string, options?: any): AxiosPromise<SyncVesselVisitsDto> {
      return localVarFp.sync(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {UpdateVesselVisitBerthsCommand} [updateVesselVisitBerthsCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBerths(
      id: number,
      updateVesselVisitBerthsCommand?: UpdateVesselVisitBerthsCommand,
      options?: any,
    ): AxiosPromise<UpdateVesselVisitBerthsDto> {
      return localVarFp
        .updateBerths(id, updateVesselVisitBerthsCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {UpdateVesselVisitStatusCommand} [updateVesselVisitStatusCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVesselVisitStatus(
      id: number,
      updateVesselVisitStatusCommand?: UpdateVesselVisitStatusCommand,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .updateVesselVisitStatus(id, updateVesselVisitStatusCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    upsertOrderList(
      upsertOrderListCommandV2?: UpsertOrderListCommandV2,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .upsertOrderList(upsertOrderListCommandV2, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * VesselVisitsApi - object-oriented interface
 * @export
 * @class VesselVisitsApi
 * @extends {BaseAPI}
 */
export class VesselVisitsApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselVisitsApi
   */
  public berths(id: number, options?: AxiosRequestConfig) {
    return VesselVisitsApiFp(this.configuration)
      .berths(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateVesselVisitCommand} [createVesselVisitCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselVisitsApi
   */
  public create(createVesselVisitCommand?: CreateVesselVisitCommand, options?: AxiosRequestConfig) {
    return VesselVisitsApiFp(this.configuration)
      .create(createVesselVisitCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [portUnlocode]
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselVisitsApi
   */
  public createVesselVisitDynamically(
    portUnlocode?: string,
    requestBody?: Array<string>,
    options?: AxiosRequestConfig,
  ) {
    return VesselVisitsApiFp(this.configuration)
      .createVesselVisitDynamically(portUnlocode, requestBody, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} page
   * @param {number} pageSize
   * @param {CarrierVisitStatus} [status]
   * @param {CargoType} [cargoType]
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselVisitsApi
   */
  public get(
    page: number,
    pageSize: number,
    status?: CarrierVisitStatus,
    cargoType?: CargoType,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return VesselVisitsApiFp(this.configuration)
      .get(
        page,
        pageSize,
        status,
        cargoType,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselVisitsApi
   */
  public getById(id: number, options?: AxiosRequestConfig) {
    return VesselVisitsApiFp(this.configuration)
      .getById(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Array<number>} ids
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselVisitsApi
   */
  public getByIds(ids: Array<number>, options?: AxiosRequestConfig) {
    return VesselVisitsApiFp(this.configuration)
      .getByIds(ids, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselVisitsApi
   */
  public operationEnd(id: number, options?: AxiosRequestConfig) {
    return VesselVisitsApiFp(this.configuration)
      .operationEnd(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof VesselVisitsApi
   */
  public orders(upsertOrderListCommandV2?: UpsertOrderListCommandV2, options?: AxiosRequestConfig) {
    return VesselVisitsApiFp(this.configuration)
      .orders(upsertOrderListCommandV2, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateVesselVisitCommand} [updateVesselVisitCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselVisitsApi
   */
  public put(updateVesselVisitCommand?: UpdateVesselVisitCommand, options?: AxiosRequestConfig) {
    return VesselVisitsApiFp(this.configuration)
      .put(updateVesselVisitCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof VesselVisitsApi
   */
  public sync(from?: string, options?: AxiosRequestConfig) {
    return VesselVisitsApiFp(this.configuration)
      .sync(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {UpdateVesselVisitBerthsCommand} [updateVesselVisitBerthsCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselVisitsApi
   */
  public updateBerths(
    id: number,
    updateVesselVisitBerthsCommand?: UpdateVesselVisitBerthsCommand,
    options?: AxiosRequestConfig,
  ) {
    return VesselVisitsApiFp(this.configuration)
      .updateBerths(id, updateVesselVisitBerthsCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {UpdateVesselVisitStatusCommand} [updateVesselVisitStatusCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselVisitsApi
   */
  public updateVesselVisitStatus(
    id: number,
    updateVesselVisitStatusCommand?: UpdateVesselVisitStatusCommand,
    options?: AxiosRequestConfig,
  ) {
    return VesselVisitsApiFp(this.configuration)
      .updateVesselVisitStatus(id, updateVesselVisitStatusCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpsertOrderListCommandV2} [upsertOrderListCommandV2]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof VesselVisitsApi
   */
  public upsertOrderList(
    upsertOrderListCommandV2?: UpsertOrderListCommandV2,
    options?: AxiosRequestConfig,
  ) {
    return VesselVisitsApiFp(this.configuration)
      .upsertOrderList(upsertOrderListCommandV2, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * VesselsApi - axios parameter creator
 * @export
 */
export const VesselsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id)
      const localVarPath = `/planning/api/Vessels/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels/All`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels/Berths`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels/Commodities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels/ContainerDamageLocations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels/ContainerDamageTypes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels/ContainerTerminalPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels/ContainerYardPositions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels/Customers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      page: number,
      pageSize: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('get', 'page', page)
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('get', 'pageSize', pageSize)
      const localVarPath = `/planning/api/Vessels`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize
      }

      if (filter !== undefined) {
        localVarQueryParameter['Filter'] = filter
      }

      if (includeCompleted !== undefined) {
        localVarQueryParameter['IncludeCompleted'] = includeCompleted
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['OrderBy'] = orderBy
      }

      if (isDescending !== undefined) {
        localVarQueryParameter['IsDescending'] = isDescending
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<number>} [ids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds: async (
      ids?: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels/GetByIds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (ids) {
        localVarQueryParameter['Ids'] = ids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels/Packages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateVesselCommand} [createVesselCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (
      createVesselCommand?: CreateVesselCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createVesselCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {UpdateVesselCommand} [updateVesselCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put: async (
      id: number,
      updateVesselCommand?: UpdateVesselCommand,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('put', 'id', id)
      const localVarPath = `/planning/api/Vessels/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateVesselCommand,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels/RailTracks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    syncData: async (
      from: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      assertParamExists('syncData', 'from', from)
      const localVarPath = `/planning/api/Vessels/SyncData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels/Tenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions: async (
      from?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/planning/api/Vessels/WorkInstructions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * VesselsApi - functional programming interface
 * @export
 */
export const VesselsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VesselsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async all(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.all(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async berths(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.berths(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commodities(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commodities(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageLocations(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageLocations(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerDamageTypes(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerDamageTypes(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerTerminalPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerTerminalPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async containerYardPositions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.containerYardPositions(
        from,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customers(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customers(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      page: number,
      pageSize: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselDtoPaginatedList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        page,
        pageSize,
        filter,
        includeCompleted,
        orderBy,
        isDescending,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {Array<number>} [ids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByIds(
      ids?: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByIds(ids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packages(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.packages(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateVesselCommand} [createVesselCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(
      createVesselCommand?: CreateVesselCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(createVesselCommand, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {UpdateVesselCommand} [updateVesselCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async put(
      id: number,
      updateVesselCommand?: UpdateVesselCommand,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.put(
        id,
        updateVesselCommand,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async railTracks(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.railTracks(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async syncData(
      from: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VesselDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncData(from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenants(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tenants(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workInstructions(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workInstructions(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * VesselsApi - factory interface
 * @export
 */
export const VesselsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = VesselsApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<boolean> {
      return localVarFp._delete(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    all(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.all(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    berths(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.berths(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commodities(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.commodities(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageLocations(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageLocations(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerDamageTypes(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerDamageTypes(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerTerminalPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerTerminalPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    containerYardPositions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .containerYardPositions(from, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customers(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.customers(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [filter]
     * @param {boolean} [includeCompleted]
     * @param {string} [orderBy]
     * @param {boolean} [isDescending]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(
      page: number,
      pageSize: number,
      filter?: string,
      includeCompleted?: boolean,
      orderBy?: string,
      isDescending?: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<VesselDtoPaginatedList> {
      return localVarFp
        .get(page, pageSize, filter, includeCompleted, orderBy, isDescending, from, to, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {Array<number>} [ids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByIds(ids?: Array<number>, options?: any): AxiosPromise<Array<VesselDto>> {
      return localVarFp.getByIds(ids, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packages(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.packages(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateVesselCommand} [createVesselCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(createVesselCommand?: CreateVesselCommand, options?: any): AxiosPromise<VesselDto> {
      return localVarFp.post(createVesselCommand, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {UpdateVesselCommand} [updateVesselCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put(
      id: number,
      updateVesselCommand?: UpdateVesselCommand,
      options?: any,
    ): AxiosPromise<VesselDto> {
      return localVarFp
        .put(id, updateVesselCommand, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    railTracks(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.railTracks(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} from
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    syncData(from: string, to?: string, options?: any): AxiosPromise<Array<VesselDto>> {
      return localVarFp.syncData(from, to, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenants(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.tenants(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workInstructions(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.workInstructions(from, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * VesselsApi - object-oriented interface
 * @export
 * @class VesselsApi
 * @extends {BaseAPI}
 */
export class VesselsApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public _delete(id: number, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      ._delete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public all(from?: string, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .all(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public berths(from?: string, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .berths(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public commodities(from?: string, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .commodities(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public containerDamageLocations(from?: string, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .containerDamageLocations(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public containerDamageTypes(from?: string, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .containerDamageTypes(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public containerTerminalPositions(from?: string, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .containerTerminalPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public containerYardPositions(from?: string, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .containerYardPositions(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public customers(from?: string, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .customers(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} page
   * @param {number} pageSize
   * @param {string} [filter]
   * @param {boolean} [includeCompleted]
   * @param {string} [orderBy]
   * @param {boolean} [isDescending]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public get(
    page: number,
    pageSize: number,
    filter?: string,
    includeCompleted?: boolean,
    orderBy?: string,
    isDescending?: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return VesselsApiFp(this.configuration)
      .get(page, pageSize, filter, includeCompleted, orderBy, isDescending, from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Array<number>} [ids]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public getByIds(ids?: Array<number>, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .getByIds(ids, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public packages(from?: string, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .packages(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateVesselCommand} [createVesselCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public post(createVesselCommand?: CreateVesselCommand, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .post(createVesselCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {UpdateVesselCommand} [updateVesselCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public put(id: number, updateVesselCommand?: UpdateVesselCommand, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .put(id, updateVesselCommand, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public railTracks(from?: string, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .railTracks(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} from
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public syncData(from: string, to?: string, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .syncData(from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public tenants(from?: string, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .tenants(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VesselsApi
   */
  public workInstructions(from?: string, options?: AxiosRequestConfig) {
    return VesselsApiFp(this.configuration)
      .workInstructions(from, options)
      .then(request => request(this.axios, this.basePath))
  }
}
