import { Box, Container, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { IssueCard } from './Components/IssueCard'
import { IssuesPageHeader } from './Components/IssuesPageHeader'
import { IssuesTypeFilter } from './Components/IssuesTypeFilter'

export const IssuesPage = observer(() => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const { issuesViewStore } = usePlanningStore()

  useEffect(() => {
    issuesViewStore.fetchData()
  }, [issuesViewStore])

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <IssuesPageHeader store={issuesViewStore} />
      <IssuesTypeFilter store={issuesViewStore} />

      <Container
        sx={{
          paddingY: theme.customSpacing.m,
        }}
      >
        {issuesViewStore.data.length === 0 ? (
          <Typography variant='h4'>{t('noIssuesFound', 'No issues found')}</Typography>
        ) : (
          issuesViewStore.data.map(i => (
            <IssueCard
              key={i.id}
              issue={i}
              containerVisit={i.containerJourney}
              onClickResolve={id => alert(id)} //TODO song
            />
          ))
        )}
      </Container>
    </Box>
  )
})
